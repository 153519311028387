import {
  ICreditTotal,
  IGetPaymentListSuccess,
  IPaymentCredit,
  IPaymentMaster,
  IPaymentMasterCreate,
  IPaymentMasterRead,
  IPaymentParam,
  IPaymentSchedule,
  ISetCreditTotal,
  ITierBonus,
  ITutorExtraPayment,
  ITutorExtraPaymentCreate,
  ITutorPayment,
  ITutorPaymentRead,
} from '../models/payment.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import {
  deletePaymentCredit,
  deletePaymentInit,
  deleteStaffPaymentInit,
  deletePaymentSchedule,
  deletePayTutorExtraPayment,
  getPayment,
  getPaymentList,
  getPayTutorExtraPayment,
  getTierBonus,
  getTutorPayment,
  getTutorPaymentCreditList,
  getTutorPaymentList,
  getTutorPaymentScheduleList,
  patchPaymentDeadline,
  patchPaymentEmail,
  patchTutorPaymentTotal,
  patchTutorPaymentTotalAll,
  postPayment,
  postPayTutorExtraPayment, deletePayment, getPreviousMonthSchedule,
} from '../api/payment.api';
import { message } from 'antd';

interface IPaymentState {
  paymentMasterList: IPaymentMaster[];
  paymentMaster?: IPaymentMasterRead;
  tutorPaymentList: ITutorPayment[];
  tutorPayment?: ITutorPaymentRead;
  paymentSchedule: IPaymentSchedule[];
  paymentCredit: IPaymentCredit[];
  tierBonus?: ITierBonus;
  creditTotal: ICreditTotal;
  tutorExtraPaymentList: ITutorExtraPayment[];
  isCheckboxUpdate: boolean;
  total: number;
  previousMonthScheduleCount: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IPaymentState = {
  paymentMasterList: [],
  tutorPaymentList: [],
  paymentSchedule: [],
  paymentCredit: [],
  creditTotal: {
    cancellationCredit: 0, baseCredit: 0, credit: 0, noShowCredit: 0, substituteCredit: 0, tierCredit: 0,
    itIssueCredit: 0,
    studentNoShowCredit: 0,
  },
  tutorExtraPaymentList: [],
  isCheckboxUpdate: false,
  total: 0,
  previousMonthScheduleCount: 0,
  isLoading: false,
};

const paymentSlicer = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    postPaymentStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    postPaymentSuccess: (state: IPaymentState, action: PayloadAction<IPaymentMaster>) => {
      state.paymentMasterList = [...state.paymentMasterList, action.payload];
      state.total = state.total + 1;
      state.isLoading = false;
      state.error = undefined;
    },
    postPaymentFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.paymentMasterList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getPaymentListStart: (state: IPaymentState) => {
      state.paymentMasterList = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getPaymentListSuccess: (state: IPaymentState, action: PayloadAction<IGetPaymentListSuccess>) => {
      state.paymentMasterList = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getPaymentListFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.paymentMasterList = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
    getPaymentStart: (state: IPaymentState) => {
      state.paymentMaster = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getPaymentSuccess: (state: IPaymentState, action: PayloadAction<IPaymentMasterRead>) => {
      console.log('payload: ', action.payload);
      state.paymentMaster = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getPaymentFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.paymentMaster = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getTutorPaymentListStart: (state: IPaymentState) => {
      state.tutorPaymentList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getTutorPaymentListSuccess: (state: IPaymentState, action: PayloadAction<ITutorPayment[]>) => {
      state.tutorPaymentList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getTutorPaymentListFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.tutorPaymentList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getTutorPaymentStart: (state: IPaymentState) => {
      state.tutorPayment = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getTutorPaymentSuccess: (state: IPaymentState, action: PayloadAction<ITutorPaymentRead>) => {
      state.tutorPayment = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getTutorPaymentFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.tutorPayment = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getTutorPaymentScheduleListStart: (state: IPaymentState) => {
      state.paymentSchedule = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getTutorPaymentScheduleListSuccess: (state: IPaymentState, action: PayloadAction<IPaymentSchedule[]>) => {
      state.paymentSchedule = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getTutorPaymentScheduleListFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.paymentSchedule = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getTutorPaymentCreditListStart: (state: IPaymentState) => {
      state.paymentCredit = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getTutorPaymentCreditListSuccess: (state: IPaymentState, action: PayloadAction<IPaymentCredit[]>) => {
      state.paymentCredit = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getTutorPaymentCreditListFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.paymentCredit = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getTierBonusStart: (state: IPaymentState) => {
      state.tierBonus = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getTierBonusSuccess: (state: IPaymentState, action: PayloadAction<ITierBonus>) => {
      state.tierBonus = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getTierBonusFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.tierBonus = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    deletePaymentInitStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    deletePaymentInitSuccess: (state: IPaymentState, action: PayloadAction<ITutorPayment[]>) => {
      state.tutorPaymentList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    deletePaymentInitFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCreditTotal: (state: IPaymentState, action: PayloadAction<ISetCreditTotal>) => {
      state.creditTotal = {
        ...state.creditTotal,
        ...action.payload,
      };
    },
    initCreditTotal: (state: IPaymentState) => {
      state.creditTotal = {
        cancellationCredit: 0,
        baseCredit: 0,
        credit: 0,
        noShowCredit: 0,
        substituteCredit: 0,
        tierCredit: 0, itIssueCredit: 0, studentNoShowCredit: 0,
      };
    },
    patchTutorPaymentTotalStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchTutorPaymentTotalSuccess: (state: IPaymentState, action: PayloadAction<ITutorPayment>) => {
      state.tutorPaymentList = state.tutorPaymentList.map((item) => item.id === action.payload.id ? action.payload : item);
      state.isLoading = false;
      state.error = undefined;
    },
    patchTutorPaymentTotalFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deletePaymentScheduleStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    deletePaymentScheduleSuccess: (state: IPaymentState, action: PayloadAction<number>) => {
      state.paymentSchedule = state.paymentSchedule.filter(item => item.id !== action.payload);
      state.isLoading = false;
      state.error = undefined;
    },
    deletePaymentScheduleFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deletePaymentCreditStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    deletePaymentCreditSuccess: (state: IPaymentState, action: PayloadAction<number>) => {
      state.paymentCredit = state.paymentCredit.filter(item => item.id !== action.payload);
      state.isLoading = false;
      state.error = undefined;
    },
    deletePaymentCreditFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    patchPaymentDeadlineStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchPaymentDeadlineSuccess: (state: IPaymentState) => {
      state.isLoading = false;
      state.error = undefined;
    },
    patchPaymentDeadlineFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    initTierBonus: (state: IPaymentState) => {
      state.tierBonus = undefined;
    },
    getPayTutorExtraPaymentStart: (state: IPaymentState) => {
      state.tutorExtraPaymentList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getPayTutorExtraPaymentSuccess: (state: IPaymentState, action: PayloadAction<ITutorExtraPayment[]>) => {
      state.tutorExtraPaymentList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getPayTutorExtraPaymentFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.tutorExtraPaymentList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    postPayTutorExtraPaymentStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    postPayTutorExtraPaymentSuccess: (state: IPaymentState, action: PayloadAction<ITutorExtraPayment>) => {
      state.tutorExtraPaymentList = [...state.tutorExtraPaymentList, { ...action.payload }];
      state.isLoading = false;
      state.error = undefined;
    },
    postPayTutorExtraPaymentFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deletePayTutorExtraPaymentStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    deletePayTutorExtraPaymentSuccess: (state: IPaymentState, action: PayloadAction<number>) => {
      state.tutorExtraPaymentList = state.tutorExtraPaymentList.filter((item) => item.id !== action.payload);
      state.isLoading = false;
      state.error = undefined;
    },
    deletePayTutorExtraPaymentFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    patchTutorPaymentTotalAllStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchTutorPaymentTotalAllSuccess: (state: IPaymentState) => {
      state.isCheckboxUpdate = true;
      state.isLoading = false;
      state.error = undefined;
    },
    patchTutorPaymentTotalAllFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    initCheckboxUpdate: (state: IPaymentState) => {
      state.isCheckboxUpdate = false;
    },
    patchPaymentEmailStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchPaymentEmailSuccess: (state: IPaymentState, action: PayloadAction<number>) => {
      state.paymentMasterList = state.paymentMasterList.map((item) => {
        if (action.payload === item.id) {
          return {
            ...item,
            isPublished: true,
          };
        }
        return item;
      });
      if (state.paymentMaster) {
        state.paymentMaster = { ...state.paymentMaster, isPublished: true };
      }
      state.isLoading = false;
      state.error = undefined;
    },
    patchPaymentEmailFailure: (state: IPaymentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deletePaymentStart: (state: IPaymentState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    deletePaymentSuccess: (state: IPaymentState, { payload }: PayloadAction<number>) => {
      state.paymentMasterList = state.paymentMasterList.filter((paymentMaster) => paymentMaster.id !== payload);
      state.isLoading = false;
      state.error = undefined;
    },
    deletePaymentFailure: (state: IPaymentState, { payload }: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = payload;
    },
    getPreviousMonthScheduleStart: (state: IPaymentState) => {
      state.previousMonthScheduleCount = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getPreviousMonthScheduleSuccess: (state: IPaymentState, { payload }: PayloadAction<number>) => {
      state.previousMonthScheduleCount = payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getPreviousMonthScheduleFailure: (state: IPaymentState, { payload }: PayloadAction<AxiosError>) => {
      state.previousMonthScheduleCount = 0;
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const {
  postPaymentStart,
  postPaymentSuccess,
  postPaymentFailure,
  getPaymentListStart,
  getPaymentListSuccess,
  getPaymentListFailure,
  getPaymentStart,
  getPaymentSuccess,
  getPaymentFailure,
  getTutorPaymentListStart,
  getTutorPaymentListSuccess,
  getTutorPaymentListFailure,
  getTutorPaymentStart,
  getTutorPaymentSuccess,
  getTutorPaymentFailure,
  getTutorPaymentScheduleListStart,
  getTutorPaymentScheduleListSuccess,
  getTutorPaymentScheduleListFailure,
  getTutorPaymentCreditListStart,
  getTutorPaymentCreditListSuccess,
  getTutorPaymentCreditListFailure,
  getTierBonusStart,
  getTierBonusSuccess,
  getTierBonusFailure,
  deletePaymentInitStart,
  deletePaymentInitSuccess,
  deletePaymentInitFailure,
  setCreditTotal,
  initCreditTotal,
  patchTutorPaymentTotalStart,
  patchTutorPaymentTotalSuccess,
  patchTutorPaymentTotalFailure,
  deletePaymentScheduleStart,
  deletePaymentScheduleSuccess,
  deletePaymentScheduleFailure,
  deletePaymentCreditStart,
  deletePaymentCreditSuccess,
  deletePaymentCreditFailure,
  patchPaymentDeadlineStart,
  patchPaymentDeadlineSuccess,
  patchPaymentDeadlineFailure,
  initTierBonus,
  getPayTutorExtraPaymentStart,
  getPayTutorExtraPaymentSuccess,
  getPayTutorExtraPaymentFailure,
  postPayTutorExtraPaymentStart,
  postPayTutorExtraPaymentSuccess,
  postPayTutorExtraPaymentFailure,
  deletePayTutorExtraPaymentStart,
  deletePayTutorExtraPaymentSuccess,
  deletePayTutorExtraPaymentFailure,
  patchTutorPaymentTotalAllStart,
  patchTutorPaymentTotalAllSuccess,
  patchTutorPaymentTotalAllFailure,
  initCheckboxUpdate,
  patchPaymentEmailStart,
  patchPaymentEmailSuccess,
  patchPaymentEmailFailure,
  deletePaymentStart,
  deletePaymentSuccess,
  deletePaymentFailure,
  getPreviousMonthScheduleStart,
  getPreviousMonthScheduleSuccess,
  getPreviousMonthScheduleFailure,
} = paymentSlicer.actions;
export default paymentSlicer.reducer;

export const doPostPayment = (body: IPaymentMasterCreate): AppThunk => async (dispatch) => {
  try {
    dispatch(postPaymentStart());
    const { data } = await postPayment(body);
    dispatch(postPaymentSuccess(data));
    message.success('성공적으로 생성했습니다.');
  } catch (e) {
    dispatch(postPaymentFailure(e));
  }
};

export const doGetPaymentList = (params?: IPaymentParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getPaymentListStart());
    const response = await getPaymentList(params);
    const success: IGetPaymentListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getPaymentListSuccess(success));
  } catch (e) {
    dispatch(getPaymentListFailure(e));
  }
};

export const doGetPayment = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getPaymentStart());
    const { data } = await getPayment(id);
    dispatch(getPaymentSuccess(data));
  } catch (e) {
    dispatch(getPaymentFailure(e));
  }
};

export const doGetTutorPaymentList = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getTutorPaymentListStart());
    const { data } = await getTutorPaymentList(id);
    dispatch(getTutorPaymentListSuccess(data));
  } catch (e) {
    dispatch(getTutorPaymentListFailure(e));
  }
};

export const doGetTutorPayment = (paymentMasterId: number, staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getTutorPaymentStart());
    const { data } = await getTutorPayment(paymentMasterId, staffId);
    dispatch(getTutorPaymentSuccess((data)));
  } catch (e) {
    dispatch(getTutorPaymentFailure(e));
  }
};

export const doGetTutorPaymentScheduleList = (paymentMasterId: number, staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getTutorPaymentScheduleListStart());
    const { data } = await getTutorPaymentScheduleList(paymentMasterId, staffId);
    dispatch(getTutorPaymentScheduleListSuccess(data));
  } catch (e) {
    dispatch(getTutorPaymentScheduleListFailure(e));
  }
};

export const doGetTutorPaymentCreditList = (paymentMasterId: number, staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getTutorPaymentCreditListStart());
    const { data } = await getTutorPaymentCreditList(paymentMasterId, staffId);
    dispatch(getTutorPaymentCreditListSuccess(data));
  } catch (e) {
    dispatch(getTutorPaymentCreditListFailure(e));
  }
};

export const doGetTierBonus = (tier: number, total: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getTierBonusStart());
    const { data } = await getTierBonus(tier, total);
    dispatch(getTierBonusSuccess(data));
  } catch (e) {
    dispatch(getTierBonusFailure(e));
  }
};

export const doDeletePaymentInit = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(deletePaymentInitStart());
    const { data } = await deletePaymentInit(id);
    dispatch(deletePaymentInitSuccess(data));
    dispatch(doGetPayment(id));
    message.success('초기화에 성공했습니다.');
  } catch (e) {
    dispatch(deletePaymentInitFailure(e));
  }
};

export const doDeleteStaffPaymentInit = (id: number, staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(deletePaymentInitStart());
    const { data } = await deleteStaffPaymentInit(id, staffId);
    dispatch(deletePaymentInitSuccess(data));
    dispatch(doGetPayment(id));
    message.success('초기화에 성공했습니다.');
  } catch (e) {
    dispatch(deletePaymentInitFailure(e));
  }
};

export const doPatchTutorPaymentTotal = (paymentMasterId: number, staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(patchTutorPaymentTotalStart());
    const { data } = await patchTutorPaymentTotal(paymentMasterId, staffId);
    dispatch(patchTutorPaymentTotalSuccess(data));
    dispatch(doGetTutorPayment(paymentMasterId, staffId));
    message.success('급여 확정에 성공했습니다.');
  } catch (e) {
    dispatch(patchTutorPaymentTotalFailure(e));
  }
};

export const doDeletePaymentSchedule = (paymentMasterId: number, staffId: number, paymentScheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(deletePaymentScheduleStart());
    const { data } = await deletePaymentSchedule(paymentMasterId, staffId, paymentScheduleId);
    dispatch(deletePaymentScheduleSuccess(data.id));
    dispatch(doGetTutorPayment(paymentMasterId, staffId));
    message.success('삭제에 성공했습니다.');
  } catch (e) {
    dispatch(deletePaymentScheduleFailure(e));
  }
};

export const doDeletePaymentCredit = (tutorDayClassCountId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(deletePaymentCreditStart());
    const { data } = await deletePaymentCredit(tutorDayClassCountId);
    dispatch(deletePaymentCreditSuccess(data.id));
    message.success('삭제에 성공했습니다.');
  } catch (e) {
    dispatch(deletePaymentCreditFailure(e));
  }
};

export const doPatchPaymentDeadline = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(patchPaymentDeadlineStart());
    await patchPaymentDeadline(id);
    dispatch(patchPaymentDeadlineSuccess());
    message.success('마감에 성공했습니다.');
  } catch (e) {
    dispatch(patchPaymentDeadlineFailure(e));
  }
};

export const doGetPayTutorExtraPayment = (paymentMasterId: number, staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getPayTutorExtraPaymentStart());
    const { data } = await getPayTutorExtraPayment(paymentMasterId, staffId);
    dispatch(getPayTutorExtraPaymentSuccess(data));
  } catch (e) {
    dispatch(getPayTutorExtraPaymentFailure(e));
  }
};

export const doPostPayTutorExtraPayment = (paymentMasterId: number, staffId: number, body: ITutorExtraPaymentCreate): AppThunk => async (dispatch) => {
  try {
    dispatch(postPayTutorExtraPaymentStart());
    const { data } = await postPayTutorExtraPayment(paymentMasterId, staffId, body);
    dispatch(postPayTutorExtraPaymentSuccess(data));
    dispatch(doGetTutorPayment(paymentMasterId, staffId));
    message.success('성공적으로 추가항목을 추가했습니다.');
  } catch (e) {
    dispatch(postPayTutorExtraPaymentFailure(e));
  }
};

export const doDeletePayTutorExtraPayment = (paymentMasterId: number, staffId: number, id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(deletePayTutorExtraPaymentStart());
    const { data } = await deletePayTutorExtraPayment(paymentMasterId, staffId, id);
    dispatch(deletePayTutorExtraPaymentSuccess(data.id));
    dispatch(doGetTutorPayment(paymentMasterId, staffId));
    message.success('성공적으로 추가항목을 삭제했습니다.');
  } catch (e) {
    dispatch(deletePayTutorExtraPaymentFailure(e));
  }
};

export const doPatchTutorPaymentTotalAll = (paymentMasterId: number, ids: number[]): AppThunk => async (dispatch) => {
  try {
    dispatch(patchTutorPaymentTotalAllStart());
    await patchTutorPaymentTotalAll(paymentMasterId, ids);
    dispatch(patchTutorPaymentTotalAllSuccess());
    dispatch(doGetPayment(paymentMasterId));
    dispatch(doGetTutorPaymentList(paymentMasterId));
    message.success('성공적으로 일괄 급여 확정을 적용했습니다.');
  } catch (e) {
    dispatch(patchTutorPaymentTotalAllFailure(e));
  }
};

export const doPatchPaymentEmail = (paymentMasterId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(patchPaymentEmailStart());
    patchPaymentEmail(paymentMasterId);
    dispatch(patchPaymentEmailSuccess(paymentMasterId));
  } catch (e) {
    dispatch(patchPaymentEmailFailure(e));
  }
};

export const doDeletePayment = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(deletePaymentStart());
    await deletePayment(id);
    dispatch(deletePaymentSuccess(id));
    message.success('강사 급여 데이터 삭제에 성공했습니다.');
  } catch (e) {
    dispatch(deletePaymentFailure(e));
  }
};

export const doGetPreviousMonthSchedule = (id: number, staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getPreviousMonthScheduleStart());
    const { data: { count } } = await getPreviousMonthSchedule(id, staffId);
    dispatch(getPreviousMonthScheduleSuccess(count));
  } catch (e) {
    dispatch(getPreviousMonthScheduleFailure(e));
  }
};
