import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommentRequest, CommentUpdate, Comment } from '../models/Comment';
import { AppThunk } from './index';
import { list, create, remove, update } from '../api/comment';
import { AxiosError } from 'axios';
import { messageAdd, showMessage } from './message.slice';
import { Error } from '../models/Error';

interface ICommentState {
  comments: Comment[];
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: ICommentState = {
  comments: [],
  isLoading: false,
  error: null,
};

const commentsSlicer = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    requestStart: (state: ICommentState) => {
      state.error = null;
      state.isLoading = true;
    },
    listSuccess: (state: ICommentState, action: PayloadAction<Comment[]>) => {
      state.comments = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    notResponseSuccess: (state: ICommentState) => {
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: ICommentState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  listSuccess,
  notResponseSuccess,
  requestFailure,
} = commentsSlicer.actions;

export default commentsSlicer.reducer;

export const commentCreate = (data: CommentRequest): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await create(data);
    dispatch(messageAdd(showMessage('success', 'comment write')));
    dispatch(notResponseSuccess());
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const commentList = (boardId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(boardId);
    dispatch(listSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const commentUpdate = (commentId: number, comment: CommentUpdate): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await update(commentId, comment);
    dispatch(messageAdd(showMessage('success', 'update')));
    dispatch(notResponseSuccess());
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};

export const commentRemove = (commentId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await remove(commentId);
    dispatch(messageAdd(showMessage('success', 'comment remove')));
    dispatch(notResponseSuccess());
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};
