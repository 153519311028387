import React, { FC, useState } from 'react';
import { Button, DatePicker, Select } from 'antd';
import { divStyle } from '../../style/antdCssProperty';
import { IIssueCategory, IIssueParam } from '../../models/Issue';
import { IssueStatus } from '../../models/Enum';

interface IProps {
  issueCategoryList: IIssueCategory[];

  onSubmit(param: IIssueParam):void
}

const { Option } = Select;

const IssueFilterBox: FC<IProps> = ({ issueCategoryList,onSubmit }) => {
  const [categoryId, setCategoryId] = useState<number | '구분'>('구분');
  const [isReply, setIsReply] = useState<boolean | '상태'>('상태');
  const [status, setStatus] = useState<IssueStatus | '처리'>('처리');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const onCategoryChange = (value: number | '구분') => {
    setCategoryId(value);
  };

  const onIsReplyChange = (value: boolean | '상태') => {
    setIsReply(value);
  };

  const onStatusChange = (value: IssueStatus | '처리') => {
    setStatus(value);
  };

  const onStartDateChange = (date: any, dateString: string) => {
    setStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: string) => {
    setEndDate(dateString);
  };

  const onClick = () => {
    const data: IIssueParam = {};
    if (categoryId !== '구분') {
      data.categoryId = categoryId;
    }
    if (isReply !== '상태') {
      data.isReply = isReply;
    }
    if (status !== '처리') {
      data.status = status;
    }
    if (startDate) {
      data.startDate = startDate;
    }
    if (endDate) {
      data.endDate = endDate;
    }
    onSubmit(data);
  }

  return (
    <div style={divStyle}>
      <Select style={{ width: '15%' }} defaultValue='구분' onChange={onCategoryChange}>
        {
          issueCategoryList.map((item) => (
            <Option value={item.id} key={`${item.name}-${item.id}`}>{item.name}</Option>
          ))
        }
      </Select>
      <Select style={{width:'15%'}} defaultValue='상태' onChange={onIsReplyChange}>
        <option value='true'>답변완료</option>
        <option value='false'>접수중</option>
      </Select>
      <Select style={{ width: '15%' }} defaultValue='처리' onChange={onStatusChange}>
        <option value={IssueStatus.COMPLETE}>처리완료</option>
        <option value={IssueStatus.IN_PROCESS}>처리중</option>
      </Select>
      <DatePicker onChange={onStartDateChange} />
      <DatePicker onChange={onEndDateChange} />
      <Button type='primary' onClick={onClick}>
        검색
      </Button>
    </div>
  );
};

export default IssueFilterBox;
