import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Button, Descriptions, Image, PageHeader, Upload, Input, InputNumber, Modal, Table } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { episodeRead } from '../store/episode.slice';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { EpisodePageFile, EpisodePageItem, EpisodePageUpdate } from '../models/EpisodePage';
import { episodePageList, episodePageRemove, episodePageUpdate } from '../store/episodePage.slice';
import { ColumnsType } from 'antd/lib/table';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { v4 as uuidv4 } from 'uuid';
import { LinkStyle } from '../style/antdCssProperty';
import arrayMove from 'array-move';

interface IMatch {
  bookId: string;
  id: string;
}

const { TextArea } = Input;

const EpisodePageListPage: FC = () => {
  const match = useRouteMatch<IMatch>();
  const dispatch = useDispatch();
  const { episode, isLoading } = useSelector((state: RootState) => state.episodeState);
  const {
    isLoading: episodePageLoading,
    episodePages,
    error: episodePageError,
  } = useSelector((state: RootState) => state.episodePageState);
  const [localEpisodePages, setLocalEpisodePages] = useState<Array<EpisodePageItem>>([]);
  const [id, setId] = useState(0);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteAllModalVisible, setIsDeleteAllModalVisible] = useState(false);

  const [uuid, setUuid] = useState<string>();
  useEffect(() => {
    const v4 = uuidv4();
    setUuid(v4.replace(/-/gi, ''));
    if (episodePageError !== null) {
      setLocalEpisodePages([]);
    }
  }, [episodePageError]);

  useEffect(() => {
    dispatch(episodePageList(Number(match.params.id)));
    dispatch(episodeRead(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    setLocalEpisodePages(episodePages ? episodePages : []);
  }, [episodePages]);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer' }} />
  ));

  const onDirectionChange = async (text: string, index: number) => {
    const newData = localEpisodePages.map((page, i) => index === i ? ({ ...page, direction: text }) : (page));
    setLocalEpisodePages(newData);
  };

  const showModal = useCallback((id: number) => {
    setId(id);
    setIsDeleteModalVisible(true);
  }, []);

  const onDelete = useCallback(async (id: number) => {
    await dispatch(episodePageRemove(id));
    await dispatch(episodePageList(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  const handleOk = useCallback(async () => {
    await onDelete(id);
    setIsDeleteModalVisible(false);
  }, [id, onDelete]);

  const handleCancel = useCallback(() => {
    setIsDeleteModalVisible(false);
  }, []);

  const onChange = useCallback((info: UploadChangeParam<UploadFile<Array<EpisodePageFile[]>>>) => {
    if (info.file.status === 'done') {
      if (info.fileList) {
        const newLocalEpisodePages = episodePages ? episodePages.slice() : [];
        for (const item of info.fileList) {
          if (!item.response) {
            continue;
          }
          newLocalEpisodePages.push({
            id: item.response.id,
            direction: '',
            pageNo: newLocalEpisodePages.length === 0 ? 1 : newLocalEpisodePages[newLocalEpisodePages.length - 1].pageNo + 1,
            pageType: item.response.pageType,
            url: item.response.url,
          });
        }
        setLocalEpisodePages(newLocalEpisodePages);
      }
    }
  }, [episodePages]);

  const handleRegister = async () => {
    const data: EpisodePageUpdate[] = [];
    // return;
    for (const item of localEpisodePages) {
      data.push({
        id: item.id,
        pageNo: item.pageNo,
        direction: item.direction ? item.direction : undefined,
        episode: {
          id: Number(match.params.id),
        },
      });
    }
    await dispatch(episodePageUpdate(data));
    await dispatch(episodePageList(Number(match.params.id)));
  };

  const handleDeleteAllOk = async () => {
    setIsDeleteAllModalVisible(false);
    for (const item of localEpisodePages) {
      await dispatch(episodePageRemove(item.id));
    }
    await dispatch(episodePageList(Number(match.params.id)));
  };


  const columns: ColumnsType<EpisodePageItem> = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: '페이지',
      key: 'page',
      render: (text: string, record: EpisodePageItem, index: number) => (
        <InputNumber defaultValue={record.pageNo}
                     disabled />
      ),
    },
    {
      title: '이미지',
      key: 'image',
      render: (text: string, record: EpisodePageItem) => (
        <Image width={128} src={record.url} />
      ),
    },
    {
      title: 'direction',
      key: 'direction',
      render: (text: string, record: EpisodePageItem, index: number) => {
        return (
          <DirectionText value={record.direction} onChange={onDirectionChange} index={index} />
        );
      },
    },
    {
      title: '편집',
      key: 'edit',
      render: (text: string, record: EpisodePageItem) => (
        <Button type='primary' onClick={() => showModal(record.id)}>삭제</Button>
      ),
    },
  ];

  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableTable = SortableContainer((props: any) => <tbody{...props} />);

  const onSortEnd = useCallback(({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (oldIndex !== newIndex) {
      // @ts-ignore
      const newData = arrayMove<EpisodePageItem>([].concat(localEpisodePages), oldIndex, newIndex).filter(el => !!el);
      for (let i = 0; i < localEpisodePages.length; i++) {
        newData[i] = {
          ...newData[i],
          pageNo: localEpisodePages[i].pageNo,
        };
      }
      setLocalEpisodePages(newData);
    }
  }, [localEpisodePages]);


  const DraggableContainer = useCallback((props: any) => (
    <SortableTable
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  ), [SortableTable, onSortEnd]);

  const DraggableBodyRow = useCallback(({ className, style, ...restProps }) => {
    const index = localEpisodePages.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }, [SortableItem, localEpisodePages]);

  console.log('render :', localEpisodePages);
  if (isLoading || !episode) {
    return <Loader />;
  } else {
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to='/book'>교재관리</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/book/${match.params.bookId}/episode`}>에피소드</Link></Breadcrumb.Item>
          <Breadcrumb.Item>페이지</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title='페이지'>
        </PageHeader>
        <Descriptions bordered size='small' column={2}>
          <Descriptions.Item label='Full Name'>{episode.book.name}</Descriptions.Item>
          <Descriptions.Item label='Short Name'>{episode.book.shortName}</Descriptions.Item>
          <Descriptions.Item label='Episode Name'>{episode.name}</Descriptions.Item>
          <Descriptions.Item label='Lesson Name'>{episode.lessonName}</Descriptions.Item>
          <Descriptions.Item label='차시'>{episode.pageNo}</Descriptions.Item>
          <Descriptions.Item label='Preview'><a style={LinkStyle} target='_blank'
                                                href={`https://video.eggschool.net/eggschool/preview/${uuid}?episodeId=${match.params.id}`}>preview</a></Descriptions.Item>


          <Descriptions.Item label='교재' span={2}>
            {
              episodePageLoading ? (
                <Loader />
              ) : (
                <>
                  <Upload
                    name='files'
                    multiple={true}
                    action={`${process.env.REACT_APP_AXIOS_HOST}/api/books/episodes/page/files`}
                    onChange={onChange}
                    showUploadList={false}>
                    <Button>파일선택</Button>
                    Shift키를 누르시면 여러개의 파일을 업로드 할 수 있습니다.
                  </Upload>
                  <Table
                    dataSource={localEpisodePages}
                    columns={columns}
                    size='small'
                    rowKey='id'
                    pagination={false}
                    components={{
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }}
                  />
                </>
              )
            }
          </Descriptions.Item>
        </Descriptions>
        <div style={{
          marginTop: '1rem',
          textAlign: 'center',
        }}>
          <Button type='primary' onClick={handleRegister} style={{ marginRight: '1.5rem' }}>등록/일괄변경</Button>
          <Link to={`/book/${match.params.bookId}/episode`}>
            <Button type='primary'>목록</Button>
          </Link>

          <Button type='primary' onClick={() => setIsDeleteAllModalVisible(true)}
                  style={{ marginRight: '1.5rem', marginLeft: '1.5rem' }}>일괄 삭제</Button>
        </div>
        <Modal
          title='에피소드페이지 삭제'
          visible={isDeleteModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}>
          <p>정말로 삭제하시겠습니까?</p>
        </Modal>

        <Modal
          title='페이지 일괄 삭제'
          visible={isDeleteAllModalVisible}
          onOk={handleDeleteAllOk}
          onCancel={() => setIsDeleteAllModalVisible(false)}>
          <p> 정말로 모든 페이지를 삭제하시겠습니까?</p>
        </Modal>
      </>
    );
  }
};

export default EpisodePageListPage;


interface IP {
  value: string
  index: number

  onChange(direction: string, index: number): void;
}

const DirectionText = ({ value, onChange, index }: IP) => {
  const [textValue, setTextValue] = useState('');
  useEffect(() => {
    setTextValue(value);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);

  };
  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    onChange(textValue, index);
  };

  return (
    <TextArea value={textValue} onChange={handleChange} onBlur={handleBlur}
    />
  );
};
