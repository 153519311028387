import { FC, useEffect, useState } from 'react';
import { Button, Descriptions, message, Modal, Radio, Select } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle, LinkStyle } from '../../style/antdCssProperty';
import { IDashboardRead } from '../../models/Schedule';
import { format } from 'date-fns';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { failReasonSelect } from '../../libs/data';
import { FailReason, StudyUserType } from '../../models/Enum';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { doDeleteLevelTestRecovery, initIsRedirect } from '../../store/levelTest.slice';

interface IProps {
  dashboard: IDashboardRead;
  isTrialClass?: boolean;

  showModal(): void;

  showFailModal(failReason: FailReason): void;
}

const { Option } = Select;

const DashboardInfo: FC<IProps> = ({ dashboard, isTrialClass = false, showModal, showFailModal }) => {
    const dispatch = useDispatch();
    const router = useRouteMatch<{ id: string; }>();
    const history = useHistory();
    const scheduleId = +router.params.id;

    const [status, setStatus] = useState<'complete' | 'fail'>('complete');
    const [failReason, setFailReason] = useState<FailReason | 'default'>('default');
    const [visible, setVisible] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const { studyroomLogList } = useSelector((root: RootState) => root.studyroomState);
    const { isRedirect } = useSelector((root: RootState) => root.levelTestState);

    useEffect(() => {
      if (isRedirect) {
        dispatch(initIsRedirect());
        history.push('/level-test');
      }
    }, [isRedirect]);

    useEffect(() => {
      if (dashboard.status === 'fail') {
        setStatus('fail');
        if (dashboard.failReason) {
          setFailReason(dashboard.failReason);
        }
      }
    }, [dashboard]);

    const onChange = (e: RadioChangeEvent) => {
      setStatus(e.target.value);
    };

    const onFailReasonChange = (value: FailReason | 'default') => {
      setFailReason(value);
    };

    const onSaveClick = () => {
      if (dashboard.failReasonDetail) {
        message.warn('이미 작성하셨습니다.');
        return;
      }
      if (dashboard.status === 'fail') {
        message.warn('이미 실패처리 되었습니다.');
        return;
      }
      if (failReason === 'default') {
        message.warn('fail을 선택해주세요.');
        return;
      }
      showFailModal(failReason);
    };

    const onCloseModal = () => {
      setVisible(false);
    };

    const showLocalModal = () => {
      setVisible(true);
    };

    const isWrite = (): boolean => {
      return dashboard.status === 'fail';
    };

    const getEnterDateTime = (userType: StudyUserType) => {
      const filterStuddyroomLogList = studyroomLogList.filter((item) => item.userType === userType);
      const elements = [];
      for (const item of filterStuddyroomLogList) {
        elements.push(<>{format(new Date(item.enterDatetime), 'yyyy-MM-dd HH:mm:ss')}<br /></>);
      }
      return elements;
    };

    const showIsDelete = () => {
      setIsDelete(true);
    };

    const handleIsDeleteOk = () => {
      dispatch(doDeleteLevelTestRecovery(scheduleId, dashboard.status));
      setIsDelete(false);
    };

    const handleIsDeleteCancel = () => {
      setIsDelete(false);
    };

    const getDisabled = () => {
      return dashboard && dashboard.levelTest && dashboard.levelTest !== null;
    };

    return (
      <>
        <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
          <Descriptions.Item label='학생'>
            {dashboard.student.name}({dashboard.student.id}) {dashboard.student.user.email}
          </Descriptions.Item>
          <Descriptions.Item label='튜터'>
            <p style={LinkStyle}
               onClick={showModal}>{dashboard.staff.firstName} {dashboard.staff.lastName}({dashboard.staff.id}) {dashboard.staff.email}</p>
          </Descriptions.Item>
          <Descriptions.Item label='수업 시작 시간' span={1}>
            {dashboard ? format(new Date(dashboard.startDateTime), 'yyyy-MM-dd HH:mm:ss') : ''}
          </Descriptions.Item>
          <Descriptions.Item label='수업 종료 시간' span={1}>
            {dashboard ? format(new Date(dashboard.endDateTime), 'yyyy-MM-dd HH:mm:ss') : ''}
          </Descriptions.Item>
          <Descriptions.Item label='학생 입장 시간' span={1}>
            {getEnterDateTime(StudyUserType.STUDENT)}
          </Descriptions.Item>
          <Descriptions.Item label='튜터 입장 시간' span={1}>
            {getEnterDateTime(StudyUserType.TUTOR)}
          </Descriptions.Item>
          <Descriptions.Item label='전화번호' span={isTrialClass ? 1 : 2}>
            {(dashboard && dashboard.student.user.phone)}
          </Descriptions.Item>
          {isTrialClass && <Descriptions.Item label={`체험수업 ${dashboard.status === 'reserved' ? '삭제' : '복원'}`} span={1}>
            <Button onClick={showIsDelete}
                    disabled={getDisabled()}>{getDisabled() ? '복원완료' : dashboard.status === 'reserved' ? '삭제' : '복원'}</Button>
          </Descriptions.Item>}
          {
            !isTrialClass && <Descriptions.Item label='수업 상태' span={2}>
              <Radio.Group onChange={onChange} value={status}>
                <Radio value='complete' disabled={isWrite()}>Complete</Radio>
                <Radio value='fail'>Fail</Radio>
              </Radio.Group>
              {status === 'fail' && <><Select onChange={onFailReasonChange} style={{ width: '20%', marginRight: '1rem' }}
                                              value={failReason} disabled={isWrite()}>
                <Option value='default' disabled>구분 선택</Option>
                {failReasonSelect.map((item) => (
                  <Option key={`fail-reason-${item}`} value={item.value}>{item.text}</Option>
                ))}
              </Select>
                {
                  isWrite() && <p style={{
                    ...LinkStyle,
                    display: 'inline-block',
                    marginRight: '1rem',
                    cursor: 'pointer',
                  }} onClick={showLocalModal}>사유보기</p>
                }
                <Button type='primary' onClick={onSaveClick}>저장</Button></>}
            </Descriptions.Item>
          }
        </Descriptions>
        <Modal visible={visible} title='사유' onCancel={onCloseModal} onOk={onCloseModal}>
          <p>{dashboard.failReasonDetail ? dashboard.failReasonDetail : ''}</p>
        </Modal>
        <Modal visible={isDelete} title={`체험수업 ${dashboard.status === 'reserved' ? '삭제' : '복원'}`}
               onCancel={handleIsDeleteCancel} onOk={handleIsDeleteOk}>
          <p>해당 체험수업 {dashboard.status === 'reserved' ? '삭제' : '복원'} 하시겠습니까?</p>
        </Modal>
      </>
    );
  }
;

export default DashboardInfo;
