import { ISchedule2Meta, ISchedule2MetaParam } from '../models/schedule2.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getSchedule2MetaList } from '../api/schedule2.api';

interface ISchedule2State {
  schedule2MetaList: ISchedule2Meta[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: ISchedule2State = {
  schedule2MetaList: [],
  isLoading: false,
};

const schedule2Slicer = createSlice({
  name: 'schedule2',
  initialState,
  reducers: {
    getSchedule2MetaListStart: (state: ISchedule2State) => {
      state.schedule2MetaList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getSchedule2MetaListSuccess: (state: ISchedule2State, action: PayloadAction<ISchedule2Meta[]>) => {
      state.schedule2MetaList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getSchedule2MetaListFailure: (state: ISchedule2State, action: PayloadAction<AxiosError>) => {
      state.schedule2MetaList = [];
      state.isLoading = false;
      state.error = undefined;
    },
  },
});

export const {
  getSchedule2MetaListStart,
  getSchedule2MetaListSuccess,
  getSchedule2MetaListFailure,
} = schedule2Slicer.actions;
export default schedule2Slicer.reducer;

export const doGetSchedule2MetaList = (param?: ISchedule2MetaParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getSchedule2MetaListStart());
    const response = await getSchedule2MetaList(param);
    dispatch(getSchedule2MetaListSuccess(response.data));
  } catch (e) {
    dispatch(getSchedule2MetaListFailure(e));
  }
};
