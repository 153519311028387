import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { IStudyroomLog, IStutyroomVideo } from '../models/studyroom.model';

export const getStudyroom = (scheduleId: number): AxiosPromise<IStutyroomVideo[]> => {
  return axios.get(`/api/studyroom/dashboard-view?scheduleId=${scheduleId}`);
}

export const getStudyroomLogList = (scheduleId: number):AxiosPromise<IStudyroomLog[]> => {
  return axios.get(`/api/studyroom/log?scheduleId=${scheduleId}`);
}
