import { AxiosPromise } from 'axios';
import { EpisodeItem, EpisodeReadItem, EpisodeCount } from '../models/Episode';
import axios from './axiosConfig';

export const list = (bookId: number): AxiosPromise<EpisodeItem[]> => {
  return axios.get(`/api/books/episodes?bookId=${bookId}`);
};

export const create = (data: FormData): AxiosPromise<{ id: number }> => {

  data.forEach( item => console.log(item))

  return axios.post('/api/books/episodes', data);
};

export const update = (data: FormData): AxiosPromise => {
  return axios.patch('/api/books/episodes', data);
};

export const remove = (id: number): AxiosPromise => {
  return axios.delete(`/api/books/episodes/${id}`);
};

export const read = (id: number): AxiosPromise<EpisodeReadItem> => {
  return axios.get(`/api/books/episodes/${id}`);
};

export const updateAll = (data: EpisodeCount[]) => {
  return axios.patch('/api/books/episodes/all', data);
};

export const updateFile = (id: number, type: 'lessonPlan' | 'classTool') => {
  return axios.patch(`/api/books/episodes/${id}`, { type });
};
