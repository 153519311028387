import { AxiosPromise } from 'axios';
import { EpisodePageUpdate, EpisodePageItem } from '../models/EpisodePage';
import axios from './axiosConfig';

export const update = (data: EpisodePageUpdate[]): AxiosPromise => {
  return axios.patch('/api/books/episodes/page', data);
};

export const remove = (id: number): AxiosPromise => {
  return axios.delete(`/api/books/episodes/page/${id}`);
};

export const list = (episodeId: number): AxiosPromise<EpisodePageItem[]> => {
  return axios.get(`/api/books/episodes/page?episodeId=${episodeId}`);
};