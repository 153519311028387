import axios from './axiosConfig';
import { ISchedule2MetaParam } from '../models/schedule2.model';
import qs from 'qs';

export const getSchedule2MetaList = (param?: ISchedule2MetaParam) => {
  let url = '/api/schedules2/meta';
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
}
