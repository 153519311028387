import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { IStudentCourse, IStudentCourseUpdate } from '../models/studyCourse.model';

export const getStudentCourseList = (studentId: number): AxiosPromise<IStudentCourse[]> => {
  return axios.get(`/api/student-course?studentId=${studentId}`);
};

export const patchStudentCourse = (id: number, data: IStudentCourseUpdate): AxiosPromise<IStudentCourse[]> => {
  return axios.patch(`/api/student-course/${id}`, data);
};
