import { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Layout,
  Typography,
  Row,
  Col,
  Card,
} from 'antd';
import { authLogin } from 'store/auth.slice';
import { AuthLogin } from '../models/Auth';

const { Title } = Typography;
const { Header, Content, Footer } = Layout;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Login: FC = () => {
  const dispatch = useDispatch();

  const onFinish = (values: AuthLogin) => {
    const { email, password } = values;
    const data = {
      email: email.trim(),
      password: password.trim(),
    };
    dispatch(authLogin(data));
  };

  return (
    <>
      <Layout>
        <Header className='header' style={{ minHeight: '10vh' }}>
          <Row justify='center' align='middle' style={{ minHeight: '10vh' }}>
            <Col>
              <Title
                level={3}
                type='secondary'
                style={{ color: 'white', textAlign: 'center' }}>
                Egg School Learning Management System
              </Title>
            </Col>
          </Row>
        </Header>
        <Content>
          <Row justify='center' align='middle' style={{ minHeight: '80vh' }}>
            <Col md={{ span: 14, offset: 0 }}>
              <Card style={{ textAlign: 'center' }}>
                <Form
                  {...layout}
                  name='basic'
                  initialValues={{ remember: true }}
                  onFinish={onFinish}>
                  <Form.Item
                    label='E-mail'
                    name='email'
                    rules={[{ required: true, message: 'Please input your e-mail!' }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Password'
                    name='password'
                    rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button type='primary' htmlType='submit' style={{ background: '#001529' }}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
        <Footer style={{ textAlign: 'center', minHeight: '10vh' }}>
          COPYRIGHT ⓒ EGG School LLC. All rights reserved.
        </Footer>
      </Layout>
    </>
  );
};

export default Login;
