import { ProductItem } from '../models/Product';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { list } from '../api/product';

interface IProductState {
  products: ProductItem[];
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IProductState = {
  products: [],
  isLoading: false,
  error: null,
};

const productSlicer = createSlice({
  name: 'product',
  initialState,
  reducers: {
    requestStart: (state: IProductState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestListSuccess: (state: IProductState, action: PayloadAction<ProductItem[]>) => {
      state.products = action.payload;
      state.isLoading = false;
    },
    requestFailure: (state: IProductState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestFailure,
} = productSlicer.actions;

export default productSlicer.reducer;

export const productList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list();
    dispatch(requestListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};
