import React, { FC, useEffect, useState } from 'react';
import { divStyle } from '../../style/antdCssProperty';
import { Button, Input, Select } from 'antd';
import { ProductItem } from '../../models/Product';
import { ICourse } from '../../models/Course';
import { IUserTransactionParam } from '../../models/UserTransaction';
import { FileExcelOutlined } from '@ant-design/icons';
import { getUserTransactionList } from '../../api/userTransaction';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { excelInit, getPayType, getTransactionDate } from '../../libs/helper';
import { getSession } from '../../libs/session';
import CustomDatePicker from '../common/CustomDatePicker';
import { ProductCourseType, productCourseTypes } from '../../models/Enum';

interface IExcelData {
  id: number;
  appNo: string;
  amount: number;
  couponAmount: number;
  createdAt: string;
  isTerminate: boolean;
  productId: number;
  productName: string;
  userId: number;
  phone: string;
  userName: string;
  userEmail: string;
  studentId: number;
  studentName: string;
  courseId: number;
  courseName: string;
  division: string;
  retakeClass: string;
}

interface IProps {
  products: ProductItem[];
  courses: ICourse[];
  total: number;

  onSearch(params?: IUserTransactionParam): void;
}

const { Option } = Select;
const { Search } = Input;

const TransactionFilterBox: FC<IProps> = ({ products, courses, total, onSearch }) => {
  const [courseType, setCourseType] = useState<'구분' | ProductCourseType>('구분');
  const [courseId, setCourseId] = useState<'과정' | number>('과정');
  const [isCancel, setIsCancel] = useState('상태');
  const [type, setType] = useState('학생이름');
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    const sessionParams = getSession<IUserTransactionParam>('transaction');
    if (sessionParams) {
      if (sessionParams.courseType) {
        setCourseType(sessionParams.courseType);
      }
      if (sessionParams.courseId) {
        setCourseId(sessionParams.courseId);
      }
      if (sessionParams.isCancel) {
        setIsCancel(String(sessionParams.isCancel));
      }
      if (sessionParams.type) {
        setType(sessionParams.type);
      }
      if (sessionParams.search) {
        setSearch(sessionParams.search);
      }
      if (sessionParams.startDate) {
        setStartDate(sessionParams.startDate);
      }
      if (sessionParams.endDate) {
        setEndDate(sessionParams.endDate);
      }
    }
  }, []);

  const onCourseTypeChange = (value: '구분' | ProductCourseType) => {
    setCourseType(value);
  };

  const onCourseIdChange = (value: '과정' | number) => {
    setCourseId(value);
  };

  const onIsCancelChange = (value: string) => {
    setIsCancel(value);
  };

  const onTypeChange = (value: string) => {
    setType(value);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onStartDateChange = (date: any, dateString: string) => {
    setStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: string) => {
    setEndDate(dateString);
  };

  const initData = () => {
    const params: IUserTransactionParam = {};
    if (courseType !== '구분') {
      params.courseType = courseType;
    }
    if (courseId !== '과정') {
      params.courseId = courseId;
    }
    if (isCancel !== '상태') {
      params.isCancel = Boolean(isCancel);
    }
    if (type && search) {
      params.type = type;
      params.search = search.trim();
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    return params;
  };

  const onSearchClick = () => {
    const params = initData();
    onSearch(params);
  };

  const onExcelClick = async () => {
    const params = initData();
    // if (month) {
    //   params.month = month;
    // }
    params.all = true;
    const [newStartDate, newEndDate] = getTransactionDate(params);
    const response = await getUserTransactionList({ ...params, endDate: newEndDate, startDate: newStartDate });
    if (response.data.length >= 1) {
      const excelData: IExcelData[] = [];
      for (const item of response.data) {
        excelData.push({
          id: item.id,
          appNo: item.appNo,
          amount: item.amount,
          couponAmount: item.couponAmount,
          createdAt: format(utcToZonedTime(new Date(item.createdAt), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
          isTerminate: !!item.deletedAt,
          productId: item.product.id,
          productName: item.product.name,
          userId: item.user.id,
          phone: item.user.phone,
          userName: item.user.name,
          userEmail: item.user.email,
          studentId: item.student.id,
          studentName: item.student.name,
          courseId: item.course.id,
          courseName: item.course.name,
          division: getPayType(item),
          retakeClass: item.isRepayment ? 'Y' : 'N',
        });
      }
      await excelInit('transaction', 'transaction', excelData);
    }
  };

  return (
    <div style={divStyle}>
      <Select style={{ width: '10%' }} value={courseType} onChange={onCourseTypeChange}>
        <Option value='구분'>구분</Option>
        {productCourseTypes.map(item => (
          <Option value={item} key={`product-${item}`}>{item}</Option>
        ))}
      </Select>
      <Select style={{ width: '10%' }} value={courseId} onChange={onCourseIdChange}>
        <Option value='과정'>과정</Option>
        {courses.map(item => (
          <Option value={item.id} key={`course-${item.id}`}>{item.name}</Option>
        ))}
      </Select>
      <Select style={{ width: '10%' }} value={isCancel} onChange={onIsCancelChange}>
        <Option value='상태'>상태</Option>
        <Option value='true'>결제완료</Option>
        <Option value='false'>결제취소</Option>
      </Select>
      <Select style={{ width: '10%' }} value={type} onChange={onTypeChange}>
        <Option value='studentName'>학생이름</Option>
        <Option value='email'>이메일</Option>
        <Option value='phone'>전화번호</Option>
      </Select>
      <Search style={{ width: '20%' }} enterButton='검색' value={search} onChange={onSearchChange}
              onSearch={onSearchClick} />
      결제일
      <CustomDatePicker value={startDate ? new Date(startDate) : new Date()} onChange={onStartDateChange} />
      ~
      <CustomDatePicker value={endDate ? new Date(endDate) : new Date()} onChange={onEndDateChange} />
      <Button type='primary' style={{ marginRight: '0.5rem' }} onClick={onSearchClick}>검색</Button>
      total: {total}
      <Button icon={<FileExcelOutlined />} onClick={onExcelClick} style={{ marginLeft: '0.5rem', float: 'right' }} />
    </div>
  );
};

export default TransactionFilterBox;
