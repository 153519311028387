import React, { FC, useEffect } from 'react';
import { Breadcrumb, Col, PageHeader, Row } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import { bookRead } from '../store/book.slice';
import EpisodeList from '../components/EpisodeList';
import {
  episodeList as episodeListSlice,
  episodeRemove,
  episodeUpdateAll,
} from '../store/episode.slice';
import { EpisodeCount } from '../models/Episode';

interface IMatch {
  id: string;
}

const EpisodeListPage: FC = () => {
  const dispatch = useDispatch();
  const { isLoading, book } = useSelector((state: RootState) => state.bookState);
  const { isLoading: episodesLoading, episodes, error } = useSelector((state: RootState) => state.episodeState);
  const match = useRouteMatch<IMatch>();

  useEffect(() => {
    dispatch(bookRead(Number(match.params.id)));
    dispatch(episodeListSlice(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  const onUpdate = async (episodeList: EpisodeCount[]) => {
    await dispatch(episodeUpdateAll(episodeList));
    await dispatch(episodeListSlice(Number(match.params.id)));
  };

  const onDelete = async (id: number) => {
    await dispatch(episodeRemove(id));
    await dispatch(episodeListSlice(Number(match.params.id)));
  };

  if (isLoading || !book || episodesLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to='/book'>교재관리</Link></Breadcrumb.Item>
          <Breadcrumb.Item>에피소드</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title='에피소드' />
        <Row gutter={10}>
          <Col span={24}>
            <EpisodeList book={book} episodes={error || episodes === null ? [] : episodes} onUpdate={onUpdate} onDelete={onDelete} />
          </Col>
        </Row>
      </>
    );
  }
};

export default EpisodeListPage;
