import React, { FC, useEffect, useState } from 'react';
import { Breadcrumb, Col, PageHeader, Row, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import { ITutor2Param } from '../models/tutor2.model';
import TutorFilterBox from '../components/Tutor/TutorFilterBox';
import TutorTable from '../components/Tutor/TutorTable';
import { doGetTutor2List } from '../store/tutor2.slice';
import { getSession, setSession } from '../libs/session';

const TutorListPage: FC = () => {
  const dispatch = useDispatch();
  const { tutors, total, isLoading } = useSelector((root: RootState) => root.tutor2State);
  const [page, setPage] = useState(1);
  const [param, setParam] = useState<ITutor2Param>();

  useEffect(() => {
    const sessionParams = getSession<ITutor2Param>('tutor');
    if (sessionParams) {
      if (sessionParams.page) {
        setPage(sessionParams.page);
      }
      setParam(sessionParams);
      dispatch(doGetTutor2List(sessionParams));
    } else {
      const data: ITutor2Param = { ...param, page };
      setParam(data);
      dispatch(doGetTutor2List(data));
    }
  }, [dispatch, page]);

  useEffect(() => {
    if (param) {
      setSession('tutor', param);
    }
  }, [param]);

  const onFilter = (data: ITutor2Param) => {
    data.page = page;
    setParam(data);
    dispatch(doGetTutor2List(data));
  };

  const onPageChange = (page: number) => {
    const newParams: ITutor2Param = {
      ...param,
      page,
    };
    setSession('tutor', newParams);
    setPage(page);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>강사관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='강사관리' />
      <TutorFilterBox onFilter={onFilter} total={total} />
      <Row gutter={10}>
        <Col span={24}>
          {
            isLoading ? <Loader /> : <TutorTable tutors={tutors} />
          }
        </Col>
      </Row>
      <Pagination
        current={page}
        total={total}
        defaultPageSize={20}
        onChange={onPageChange}
        style={{ marginTop: '1.5rem' }}
        showSizeChanger={false}
      />
    </>
  );
};


export default TutorListPage;
