import { FC } from 'react';
import { Form, FormProps, FormItemProps, Input, Button } from 'antd';

export interface IFormItem {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

interface IProps {
  onFinish(values: any): void;
}

const StaffForm: FC<IProps> = ({ onFinish }) => {
  const formProps: FormProps = {
    wrapperCol: { span: 16 },
    onFinish
  };

  const tailLayout: FormItemProps = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const emailFormItemProps: FormItemProps = {
    label: 'email',
    name: 'email',
    rules: [{ required: true, message: '이메일을 입력해주세요.' }],
  };

  const firstNameFormItemProps: FormItemProps = {
    label: 'firstName',
    name: 'firstName',
    rules: [{ required: true, message: '이름을 입력해주세요.' }],
  };

  const lastNameFormItemProps: FormItemProps = {
    label: 'lastName',
    name: 'lastName',
    rules: [{ required: true, message: '성을 입력해주세요.' }],
  };

  const passwordFormItemProps: FormItemProps = {
    label: 'password',
    name: 'password',
    rules: [{ required: true, message: '비밀번호를 입력해주세요.' }],
  };

  return (
    <Form {...formProps}>
      <Form.Item {...emailFormItemProps}>
        <Input />
      </Form.Item>
      <Form.Item {...firstNameFormItemProps}>
        <Input />
      </Form.Item>
      <Form.Item {...lastNameFormItemProps}>
        <Input />
      </Form.Item>
      <Form.Item {...passwordFormItemProps}>
        <Input type='password' />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>제출</Button>
      </Form.Item>
    </Form>
  );
};

export default StaffForm;
