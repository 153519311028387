import { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, PageHeader, Pagination, Modal } from 'antd';
import { doGetStaffList } from '../store/staff.slice';
import { RootState } from '../store/rootReducer';
import StaffTable from '../components/Staff/StaffTable';
import { getSession, setSession } from '../libs/session';
import { IStaffListQuery } from '../models/Staff';
import StaffFilterBox from '../components/Staff/StaffFilterBox';
import { doDeleteStaff, logoutSuccess } from '../store/auth.slice';
import { logout } from '../api/auth';

const StaffListPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { staffList, total } = useSelector((root: RootState) => root.staffState);
  const { user } = useSelector((root: RootState) => root.authState);

  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [staffId, setStaffId] = useState(0);

  useEffect(() => {
    const sessionParams = getSession<IStaffListQuery>('staff');
    if (sessionParams) {
      dispatch(doGetStaffList(sessionParams));
      if (sessionParams.page) {
        setPage(sessionParams.page);
      }
    } else {
      dispatch(doGetStaffList());
    }
  }, []);

  const onPageChange = (page: number) => {
    setPage(page);
    const param: IStaffListQuery = { page };
    if (page >= 2) {
      setSession('staff', param);
    }
    dispatch(doGetStaffList(param));
  };

  const showModal = (id: number) => {
    setStaffId(id);
    setVisible(true);
  };

  const handleOk = () => {
    if (staffId !== 0) {
      dispatch(doDeleteStaff(staffId));
      if (user && !(user.isAdmin)) {
        logout();
        dispatch(logoutSuccess());
        history.push('/login');
      }
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>스태프 관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='스태프 관리' />
      <StaffFilterBox />
      <StaffTable staffList={staffList} showModal={showModal} />
      <Pagination
        current={page}
        total={total}
        defaultPageSize={20}
        onChange={onPageChange}
        style={{ marginTop: '1.5rem' }}
        showSizeChanger={false}
      />
      <Modal visible={visible} title='회원 탈퇴' onOk={handleOk} onCancel={handleCancel}>
        <p>정말로 탈퇴{user && user.isAdmin ? '시키' : '하시'}겠습니까?</p>
      </Modal>
    </>
  );
};

export default StaffListPage;
