import { Auth, AuthLogin } from '../models/Auth';
import { AxiosPromise } from 'axios';
import axios from './axiosConfig';

export const login = (data: AuthLogin): AxiosPromise<Auth> => {
  return axios.post('/api/auth/staff/login', data);
};

export const check = (): AxiosPromise<Auth> => {
  return axios.get('/api/auth/staff/check');
};

export const logout = (): AxiosPromise => {
  return axios.get('/api/auth/staff/logout', {
    withCredentials: true,
  });
};

export const getUserVirtualLogin = (email: string): AxiosPromise => {
  return axios.get(`/api/auth/user/virtual-login?email=${email}`);
};

export const getStaffVirtualLogin = (email: string):AxiosPromise => {
  return axios.get(`/api/auth/staff/virtual-login?email=${email}`);
}
