import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import {
  IMonitoringCallCheck,
  IMonitoringCheck,
  IMonitoringCheckBody,
  IMonitoringCount,
} from '../models/monitoring.model';

export const getMonitoringCount = (): AxiosPromise<IMonitoringCount> => {
  const url = `/api/schedule/monitoring-count`;
  return axios.get(url);
};

export const postMonitoringCheck = (body: IMonitoringCheckBody): AxiosPromise<IMonitoringCheck[]> => {
  const url = `/api/schedule/monitoring-check`;
  return axios.post(url, body);
};

export const postMonitoringCallCheck = (body: IMonitoringCheckBody): AxiosPromise<IMonitoringCallCheck[]> => {
  const url = '/api/schedule/monitoring-call-check';
  return axios.post(url, body);
};

export const patchMonitoringCallViewed = (id: number): AxiosPromise => {
  const url = '/api/schedule/monitoring-call-viewed';
  return axios.patch(url, { studyIssueId: id });
};
