import { FailReason } from '../models/Enum';

interface IFailReasonSelect {
  value: FailReason;
  text: string;
}

export const failReasonSelect: IFailReasonSelect[] = [
  { value: FailReason.STUDENT_NOSHOW, text: 'S-No show' },
  { value: FailReason.TUTOR_NOSHOW, text: 'T-No show' },
  { value: FailReason.ALL_NOSHOW, text: 'All-No show' },
  { value: FailReason.STUDENT_IT_ISSUE, text: 'S-IT Issue' },
  { value: FailReason.TUTOR_IT_ISSUE, text: 'T-IT Issue' },
  { value: FailReason.COMPANY_IT_ISSUE, text: 'C-IT Issue' },
];

export const excelColumn: string[] = ['id', 'firstName', 'lastName', 'email', 'Total Classes Taught', 'Student NoShow Study', 'IT Issue Payment', 'Tier Bonus', 'Credit Bonus', 'Substitute Classes Bonus', 'Deduction (24-Hour cancellation)', 'Deduction (No-Shows Penalty)'];
