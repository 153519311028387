import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGetUserSubscriptionListSuccess, IStudentUnSubscription,
  IStudentUserSubscriptionByUser,
  IUserSubscriptionListParam,
} from '../models/UserSubscription';
import { AxiosError } from 'axios';
import { AppThunk } from './index';
import { getUserSubscriptionList, postUnsubscription } from '../api/userSubscription';

interface IUserSubscriptionState {
  studentUserSubscriptions: IStudentUserSubscriptionByUser[];
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IUserSubscriptionState = {
  studentUserSubscriptions: [],
  total: 0,
  isLoading: false,
  error: undefined,
};

const userSubscriptionSlicer = createSlice({
  name: 'userSubscription',
  initialState,
  reducers: {
    getUserSubscriptionListStart: (state: IUserSubscriptionState) => {
      state.studentUserSubscriptions = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getUserSubscriptionListSuccess: (state: IUserSubscriptionState, action: PayloadAction<IGetUserSubscriptionListSuccess>) => {
      state.studentUserSubscriptions = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getUserSubscriptionListFailure: (state: IUserSubscriptionState, action: PayloadAction<AxiosError>) => {
      state.studentUserSubscriptions = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
    postUnsubscriptionStart: (state: IUserSubscriptionState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    postUnsubscriptionSuccess: (state: IUserSubscriptionState, action: PayloadAction<IStudentUserSubscriptionByUser>) => {
      state.isLoading = false;
      state.studentUserSubscriptions = state.studentUserSubscriptions.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
      state.error = undefined;
    },
    postUnsubscriptionFailure: (state: IUserSubscriptionState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getUserSubscriptionListStart,
  getUserSubscriptionListSuccess,
  getUserSubscriptionListFailure,
  postUnsubscriptionStart,
  postUnsubscriptionSuccess,
  postUnsubscriptionFailure,
} = userSubscriptionSlicer.actions;

export default userSubscriptionSlicer.reducer;

export const doGetUserSubscriptionList = (param?: IUserSubscriptionListParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserSubscriptionListStart());
    const response = await getUserSubscriptionList(param);
    const data: IGetUserSubscriptionListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getUserSubscriptionListSuccess(data));
  } catch (e) {
    dispatch(getUserSubscriptionListFailure(e));
  }
};

export const doPostUnsubscription = (data: IStudentUnSubscription): AppThunk => async (dispatch) => {
  try {
    dispatch(postUnsubscriptionStart());
    const response = await postUnsubscription(data);
    dispatch(postUnsubscriptionSuccess(response.data));
  } catch (e) {
    dispatch(postUnsubscriptionFailure(e));
  }
};
