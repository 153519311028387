import React, { FC, useState } from 'react';
import { CouponListResponse } from '../models/Coupon';
import { ColumnsType } from 'antd/lib/table';
import { Button, Table, Checkbox, Modal } from 'antd';
import { tableStyle } from '../style/antdCssProperty';
import { format } from 'date-fns';

interface IProps {
  couponList: CouponListResponse[];

  onClick(id: number): void;
}

const CouponListTable: FC<IProps> = ({ couponList, onClick }) => {
  const [ids, setIds] = useState<Array<number>>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onChange = (e: any, id: number) => {
    if (e.target.checked) {
      const newIds = ids.slice();
      newIds.push(id);
      setIds(newIds);
    } else {
      const newIds = ids.slice();
      const index = newIds.indexOf(id);
      newIds.splice(index, 0);
      setIds(newIds);
    }
    console.log(ids);
  };

  const onRemove = () => {
    if (ids.length !== 0) {
      for (const id of ids) {
        onClick(id);
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    onRemove();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns: ColumnsType<CouponListResponse> = [
    {
      title: '',
      key: 'remove',
      render: (value, record) => (
        <Checkbox onChange={(e) => onChange(e, record.id)} />
      ),
    },
    {
      title: '쿠폰번호',
      key: 'couponNumber',
      render: (value, record) => (
        `${record.couponNumber.substring(0, 5)}-${record.couponNumber.substring(5, 10)}-${record.couponNumber.substring(10, 15)}-${record.couponNumber.substring(15, 20)}`
      ),
    },
    {
      title: '등록유무',
      key: 'isUsed',
      render: (value, record) => (
        record.studentName !== null ? 'O' : ''
      ),
    },
    {
      title: '사용유무',
      key: 'isUsed',
      render: (value, record) => (
        record.isUsed ? 'O' : ''
      ),
    },
    {
      title: '고객 이메일',
      key: 'userEmail',
      render: (value, record) => (
        record.userEmail !== null ? record.userEmail : ''
      ),
    },
    {
      title: '고객 이름',
      key: 'userName',
      render: (value, record) => (
        record.userName !== null ? record.userName : ''
      ),
    },
    {
      title: '학생',
      key: 'studentName',
      render: (value, record) => (
        record.studentName !== null ? record.studentName : ''
      ),
    },
    {
      title: '등록일',
      key: 'createdAt',
      render: (value, record) => (
        format(new Date(record.createdAt), 'yyyy-MM-dd HH:mm:ss')
      ),
    },
    {
      title: '사용일',
      key: 'updatedAt',
      render: (value, record) => (
        record.isUsed ? record.updatedAt : ''
      ),
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={couponList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false} />
      <div style={{
        marginTop: '1rem',
        textAlign: 'center',
      }}>
        <Button type='primary' onClick={showModal}>삭제</Button>
      </div>
      <Modal title='삭제' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>정말로 삭제하시겠습니까?</p>
      </Modal>
    </>
  );
};

export default CouponListTable;