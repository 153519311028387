import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, PageHeader, Pagination } from 'antd';
import PaymentForm from '../components/payment/PaymentForm';
import PaymentTable from '../components/payment/PaymentTable';
import { useDispatch, useSelector } from 'react-redux';
import { doGetPaymentList } from '../store/payment.slice';
import { RootState } from '../store/rootReducer';
import { getSession, setSession } from '../libs/session';
import { IPaymentParam } from '../models/payment.model';

const PaymentPage: FC = () => {
  const dispatch = useDispatch();

  const { total } = useSelector((root: RootState) => root.paymentState);

  const [params, setParams] = useState<IPaymentParam>();

  useEffect(() => {
    const sessionParams = getSession<IPaymentParam>('payment');
    if (sessionParams) {
      setParams(sessionParams);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(doGetPaymentList(params));
  }, [params]);

  const onPageChange = (page: number) => {
    const newParams = { ...params, page };
    setParams(newParams);
    setSession('payment', newParams);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>강사 급여 관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='강사 급여 관리' />
      <PaymentForm />
      <PaymentTable />
      <Pagination current={params?.page ?? 1} total={total} defaultPageSize={20} onChange={onPageChange}
                  showSizeChanger={false} style={{ marginTop: '1.5rem' }} />
    </>
  );
};

export default PaymentPage;
