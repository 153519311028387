import React, { FC } from 'react';
import { IFeedback } from '../../models/feedback.model';
import { Descriptions } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';

interface IProps {
  feedback: IFeedback;
}

const DashboardFeedback: FC<IProps> = ({ feedback }) => {
  return (
    <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
      <Descriptions.Item label='학생 이름' span={2}>
        {feedback.student.name}
      </Descriptions.Item>
      <Descriptions.Item label='점수' span={1}>
        {feedback.score}
      </Descriptions.Item>
      <Descriptions.Item label='코멘트' span={1}>
        {feedback.comment}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DashboardFeedback;
