import React, { FC } from 'react';
import { IIssueStudent } from '../models/Issue';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { IssueStatus } from '../models/Enum';
import { LinkStyle, tableStyle } from '../style/antdCssProperty';
import { Link } from 'react-router-dom';

interface IProps {
  issueStudents: IIssueStudent[]
  user: string;
}

const IssueList: FC<IProps> = ({ issueStudents, user }) => {
  const studentColumns: ColumnsType<IIssueStudent> = [
    {
      title: 'NO',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '구분',
      key: 'category',
      render: (value, record, index) => (
        <Link to={`/one/${user}/${record.id}`} style={LinkStyle}>{record.issueCategory.name}</Link>
      ),
    },
    {
      title: '회원이메일',
      key: 'email',
      render: (value, record, index) => (record.user.email),
    },
    {
      title: '학생명',
      key: 'student',
      render: (value, record, index) => (record.student ? record.student.name : ''),
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: '등록일',
      key: 'createdAt',
      render: (value, record, index) => (format(new Date(record.createdAt), 'yyyy.MM.dd')),
    },
    {
      title: '상태',
      key: 'replyDate',
      render: (value, record, index) => {
        if (record.replyDate) {
          return <>
            답변완료<br />
            {format(new Date(record.replyDate), '(yyyy.MM.dd)')}
          </>;
        } else {
          return '접수중';
        }
      },
    },
    {
      title: '처리',
      key: 'status',
      render: (value, record, index) => (record.status === IssueStatus.IN_PROCESS ? '처리중' : '처리완료'),
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={issueStudents}
        columns={studentColumns}
        size='small'
        rowKey='id'
        pagination={false} />
    </>
  );
};

export default IssueList;

