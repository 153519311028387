import { FC } from 'react';
import { IUserMemo } from '../../models/userMemo.model';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import CustomerUserMemoTab from './CustomerUserMemoTab';

interface IProps {
  userId: number;
}

const CustomerUserMemoTable: FC<IProps> = ({ userId }) => {
  const { userMemoList } = useSelector((state: RootState) => state.userState);

  const columns: ColumnsType<IUserMemo> = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '내용',
      key: 'memo',
      dataIndex: 'memo',
      width: '75%',
    },
    {
      title: '작성일',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value: string) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      title: '작성자',
      render: ({ staff: { firstName, lastName } }: IUserMemo) => `${lastName}${firstName}`,
    },
  ];

  return <>
    <Table columns={columns} dataSource={userMemoList} pagination={false} />
    <CustomerUserMemoTab userId={userId} />
  </>;
};

export default CustomerUserMemoTable;
