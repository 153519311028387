import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Comment as UserComment } from '../../models/Comment';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Comment, Tooltip } from 'antd';
import styled from 'styled-components';
import { commentRemove } from '../../store/comment.slice';
import CommentEditor from './CommentEditor';
import { RootState } from '../../store/rootReducer';

interface IProps {
  item: UserComment;
}

interface IPropsStyle {
  size: number;
}

const Box = styled.div<IPropsStyle>`
  padding-left: ${props => props.size * 0.5 + 'rem'};
`;

const CommentListItem: FC<IProps> = ({ item }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.authState);

  const [edit, setEdit] = useState(false);
  const [reply, setReply] = useState(false);

  const createdAt = (createdAt: string) => {
    const date = new Date(createdAt);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const onUpdateClick = () => {
    setEdit(!edit);
  };

  const onDeleteClick = () => {
    dispatch(commentRemove(item.id));
  };

  const onReplyClick = () => {
    setReply(!reply);
  };

  const checkOwner = () => {
    if (user) {
      return user.email === item.staff.email;
    }
    return false;
  };

  const actions = [
    <>
      {
        checkOwner() &&
        <Tooltip title="update">
          <EditOutlined onClick={onUpdateClick} />
        </Tooltip>
      }
    </>,
    <>
      {
        checkOwner() &&
        <Tooltip title="delete">
          <DeleteOutlined onClick={onDeleteClick} />
        </Tooltip>
      }
    </>,
    <span onClick={onReplyClick}>Reply to</span>,
  ];

  return (
    <Box size={Number(item.seq)}>
      <Comment
        actions={actions}
        author={item.staff.firstName + item.staff.lastName}
        content={item.content}
        datetime={createdAt(item.createdAt)} />
      {
        edit &&
        <CommentEditor type='update' commentId={item.id} item={item} />
      }
      {
        reply &&
        <CommentEditor type='create' item={item} />
      }
    </Box>
  );
};

export default CommentListItem;