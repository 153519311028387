import React, { FC } from 'react';
import BoardEditor from '../../components/Board/BoardEditor';
import { Link, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'antd';

interface IMatch {
  id: string;
}

const BoardWritePage: FC = () => {
  const match = useRouteMatch<IMatch>();
  const id = Number(match.params.id);

  const headerTitle = (id: number) => {
    switch (id) {
      case 1:
        return 'freeBoard';
      case 2:
        return 'noticeBoard';
      case 3:
        return 'Contents Introduction';
      case 4:
        return 'Contents Video';
      case 5:
        return 'Guide Video';
      case 6:
        return 'Lesson Plan';
      case 7:
        return 'General Preparation';
      default:
        return 'board Write';
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{headerTitle(id)}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={headerTitle(id)} />
      <BoardEditor editor='create' bmId={id} />
    </>
  );
};

export default BoardWritePage;
