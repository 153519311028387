import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message } from '../models/Message';

const initialState: Message = {
  message: '',
  type: 'success',
  timestamps: 0,
};

const messageSlicer = createSlice({
  name: 'message',
  initialState,
  reducers: {
    messageAdd: (state: Message, action: PayloadAction<Message>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.timestamps = Date.now();
    },
    messageInit: (state: Message) => {
      state.message = '';
    },
  },
});

export const {
  messageAdd,
  messageInit,
} = messageSlicer.actions;

export default messageSlicer.reducer;

export const showMessage = (type: 'success' | 'error', msg: string): Message => {
  return {
    message: msg,
    type: type,
    timestamps: Date.now(),
  };
};