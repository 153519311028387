import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { AxiosError } from 'axios';
import { messageAdd, showMessage } from './message.slice';
import { Auth, AuthLogin } from '../models/Auth';
import { check, logout, login } from '../api/auth';
import { message } from 'antd';
import { IStaffNewPassword } from '../models/Staff';
import { deleteStaff, patchStaffPassword } from '../api/staff';
import { staffSliceDeleteStaffSuccess } from './staff.slice';

interface IAuthState {
  user: Auth | null;
  total: number;
  isLoading: boolean;
  isUpdated: boolean;
  error: AxiosError | null;
}

const initialState: IAuthState = {
  user: null,
  total: 0,
  isLoading: false,
  isUpdated: false,
  error: null,
};

const authSlicer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    requestStart: (state: IAuthState) => {
      state.error = null;
      state.isLoading = true;
    },
    loginSuccess: (state: IAuthState, action: PayloadAction<Auth>) => {
      state.user = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IAuthState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    logoutSuccess: (state: IAuthState) => {
      state.error = null;
      state.isLoading = false;
      state.user = null;
    },
    patchStaffPasswordStart: (state: IAuthState) => {
      state.isUpdated = false;
      state.isLoading = true;
      state.error = null;
    },
    patchStaffPasswordSuccess: (state: IAuthState) => {
      state.isUpdated = true;
      state.isLoading = false;
      state.error = null;
    },
    patchStaffPasswordFailure: (state: IAuthState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.isUpdated = false;
      state.error = action.payload;
    },
    deleteStaffStart: (state: IAuthState) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteStaffSuccess: (state: IAuthState) => {
      state.isLoading = false;
      state.error = null;
    },
    deleteStaffFailure: (state: IAuthState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    initIsUpdated(state: IAuthState) {
      state.isUpdated = false;
    },
  },
});

export const {
  requestStart,
  loginSuccess,
  requestFailure,
  logoutSuccess,
  patchStaffPasswordStart,
  patchStaffPasswordSuccess,
  patchStaffPasswordFailure,
  deleteStaffStart,
  deleteStaffSuccess,
  deleteStaffFailure,
  initIsUpdated
} = authSlicer.actions;

export default authSlicer.reducer;

export const authLogin = (data: AuthLogin): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await login(data);
    dispatch(loginSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError;
    dispatch(requestFailure(error));
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          message.error('아이디나 비밀번호가 틀렸습니다.');
          break;
        case 403:
          message.error('튜터 계정으로 로그인하실수 없습니다.');
          break;
      }
    }
  }
};

export const authCheck = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await check();
    dispatch(loginSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const authLogout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await logout();
    dispatch(messageAdd(showMessage('success', 'logout')));
    dispatch(logoutSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const doPatchStaffPassword = (body: IStaffNewPassword): AppThunk => async (dispatch) => {
  try {
    dispatch(patchStaffPasswordStart());
    await patchStaffPassword(body);
    dispatch(patchStaffPasswordSuccess());
    message.success('비밀번호 변경에 성공했습니다.');
  } catch (e) {
    dispatch(patchStaffPasswordFailure(e));
  }
};

export const doDeleteStaff = (id:number):AppThunk => async (dispatch) => {
  try {
    dispatch(deleteStaffStart());
    await deleteStaff(id);
    dispatch(deleteStaffSuccess());
    dispatch(staffSliceDeleteStaffSuccess(id));
    message.success('성공적으로 탈퇴했습니다.');
  } catch (e) {
    dispatch(deleteStaffFailure(e));
  }
}
