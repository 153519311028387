import { FC } from 'react';
import { IUserTransactionHistory } from '../../models/userTransactionHistory.model';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { format } from 'date-fns';

const CustomerUserTransactionHistoryTab: FC = () => {
  const { userTransactionHistories } = useSelector((root: RootState) => root.userTransactionState);

  const columns: ColumnsType<IUserTransactionHistory> = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '기존 시작 날짜',
      key: 'orgStartDate',
      dataIndex: 'orgStartDate',
    },
    {
      title: '기존 종료 날짜',
      key: 'orgEndDate',
      dataIndex: 'orgEndDate',
    },
    {
      title: '기존 수업 개수',
      key: 'orgTotalClassCount',
      dataIndex: 'orgTotalClassCount',
      render: (value: number, { usedClassCount }) => value - usedClassCount,
    },
    {
      title: '기존 수업 취소 개수',
      key: 'orgCancelClassCount',
      dataIndex: 'orgCancelClassCount',
    },
    {
      title: '변경 시작 날짜',
      key: 'startDate',
      dataIndex: 'startDate',
    },
    {
      title: '변경 종료 날짜',
      key: 'endDate',
      dataIndex: 'endDate',
    },
    {
      title: '변경 수업 개수',
      key: 'totalClassCount',
      dataIndex: 'totalClassCount',
      render: (value: number, { usedClassCount }) => value - usedClassCount,
    },
    {
      title: '변경 수업 취소 개수',
      key: 'cancelClassCount',
      dataIndex: 'cancelClassCount',
    },
    {
      title: '사유',
      key: 'reason',
      dataIndex: 'reason',
    },
    {
      title: '상세 사유',
      key: 'reasonDetail',
      dataIndex: 'reasonDetail',
    },
    {
      title: '변경 날짜',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value: string) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      title: '변경자',
      render: ({ staff: { lastName, firstName } }: IUserTransactionHistory) => `${lastName}${firstName}`,
    },
  ];

  return <Table columns={columns} dataSource={userTransactionHistories} pagination={false} />;
};

export default CustomerUserTransactionHistoryTab;
