import React, { FC } from 'react';
import { ISchedule2Meta } from '../../models/schedule2.model';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ScheduleStatus } from '../../models/Enum';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';

interface IProps {
  schedule2MetaList: ISchedule2Meta[];
}

const CustomerScheduleTable: FC<IProps> = ({ schedule2MetaList }) => {
    const getScheduleStatus = (schedule: ISchedule2Meta) => {
      switch (schedule.status) {
        case ScheduleStatus.COMPLETE:
          return '수업완료';
        case ScheduleStatus.RESERVED:
          return '예약완료';
        case ScheduleStatus.FAIL:
          return getFail(schedule);
        case ScheduleStatus.STUDENT_CANCEL:
          return '학생결석';
        case ScheduleStatus.STUDYING:
          return '수업 진행중';
      }
    };

    const getFail = (schedule: ISchedule2Meta) => {
      if (!schedule.tutorEnterDateTime && !schedule.studentEnterDateTime) {
        return '모두 결석';
      }
      if (!schedule.tutorEnterDateTime) {
        return '튜터 결석';
      }
      if (!schedule.studentEnterDateTime) {
        return '학생 결석';
      }
    };

    const columns: ColumnsType<ISchedule2Meta> = [
      {
        title: '수강 리스트',
        render: (value, record) => `${record.episodeName} - ${record.episodeLessonName}`,
      },
      {
        title: '수강일자',
        render: (value, record) => `${format(utcToZonedTime(new Date(record.startDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss')}`,
      },
      {
        title: '강사',
        render: (value, record) => <a style={LinkStyle} target='_blank'
                                      href={`/tutor/${record.staffId}`}>{record.staffFirstName} {record.staffLastName}({record.staffEmail})</a>,
      },
      {
        title: '상태',
        render: (value, record) => getScheduleStatus(record),
      },
    ];

    return (
      <Table
        style={tableStyle}
        dataSource={schedule2MetaList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
    );
  }
;

export default CustomerScheduleTable;
