import { User, UserParam, UserRead } from '../models/User';
import { AxiosPromise } from 'axios';
import axios from './axiosConfig';
import qs from 'qs';

export const list = (param?: UserParam): AxiosPromise<User[]> => {
  let url = `/api/user`;
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const read = (userId: number): AxiosPromise<UserRead> => {
  return axios.get(`/api/user/${userId}`);
};
