import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import {
  IIssueCategory,
  IIssueParam,
  IIssueRead,
  IIssueReply,
  IIssueStudent,
  IIssueTutor,
  IIssueUpdate,
} from '../models/Issue';
import { IssueType } from '../models/Enum';
import qs from 'qs';

export const studentList = (param?: IIssueParam): AxiosPromise<IIssueStudent[]> => {
  let url = '/api/issues?user=student';
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `&${qsParams}`;
  }
  return axios.get(url);
};

export const read = (id: number): AxiosPromise<IIssueRead> => {
  return axios.get(`/api/issues/${id}?user=student`);
};

export const update = (id: number, data: IIssueUpdate): AxiosPromise => {
  return axios.patch(`/api/issues/${id}`, data);
};

export const tutorList = (param?: IIssueParam): AxiosPromise<IIssueTutor[]> => {
  let url = '/api/issues?user=tutor';
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `&${qsParams}`;
  }
  return axios.get(url);
};
export const categoryList = (type: IssueType): AxiosPromise<IIssueCategory[]> => {
  return axios.get(`/api/issues/category?issue_type=${type}`);
};

export const patchIssueReply = (id: number, body: IIssueReply): AxiosPromise => {
  return axios.patch(`/api/issues/${id}/reply2`, body);
};
