import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { stringOrDate, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { dateFormat, settingCalender, thisWeek } from '../../libs/helper';
import BigCalender, { IEvent } from '../common/BigCalender';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import { RootState } from '../../store/rootReducer';
import { ICalenderParam } from '../../models/calender.model';
import { doGetCalenderList } from '../../store/calender.slice';
import { message } from 'antd';
import { utcToZonedTime } from 'date-fns-tz';

interface IProps {
  timeZone: string;
}

const TutorScheduleDetail: FC<IProps> = ({ timeZone }) => {
    const dispatch = useDispatch();
    const match = useRouteMatch<{ id: string }>();
    const {
      calenders,
    } = useSelector((state: RootState) => state.calenderState);
    const [scheduleList, setScheduleList] = useState<Array<any>>([]);

    useEffect(() => {
      const { firstDay, lastDay } = thisWeek();
      const data: ICalenderParam = {
        staffId: Number(match.params.id),
        startDateTime: firstDay,
        endDateTime: lastDay,
      };
      dispatch(doGetCalenderList(data));
    }, []);

    const onToolBarClick = (range: Date[] | { start: stringOrDate; end: stringOrDate }, view?: View) => {
      if (Array.isArray(range)) {
        const data: ICalenderParam = {
          staffId: Number(match.params.id),
          startDateTime: dateFormat(new Date(range[0])),
          endDateTime: dateFormat(addDays(new Date(range[range.length - 1]), 1)),
        };
        dispatch(doGetCalenderList(data));
      }
    };

    useEffect(() => {
      if (calenders.length >= 1) {
        const newScheduleList = calenders.map((item) => {
          return {
            id: item.id,
            start: settingCalender(item.startDateTime, 'Asia/Seoul'),
            end: settingCalender(item.endDateTime, 'Asia/Seoul'),
            status: item.status,
            originalStart: item.startDateTime,
          };
        });
        setScheduleList(newScheduleList);
      }
    }, [calenders, timeZone]);

    const onSelectEvent = (event: IEvent, e: SyntheticEvent) => {
      message.destroy();
      const date = utcToZonedTime(event.originalStart ? event.originalStart : event.start, timeZone);
      message.info({
        content: `${timeZone}: ${format(date, 'yyyy-MM-dd HH:mm:ss')}`,
        style: {
          position: 'absolute',
          // @ts-ignore
          top: e.clientY,
          // @ts-ignore
          left: e.clientX
        }
      });
    };

    return (
      <>
        <BigCalender events={scheduleList} onToolBarClick={onToolBarClick} onSelectEvent={onSelectEvent} />
      </>
    );
  }
;

export default TutorScheduleDetail;
