import { IFeedback } from '../models/feedback.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getFeedback } from '../api/feedback.api';

interface IFeedbackState {
  feedback?: IFeedback;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IFeedbackState = {
  isLoading: false,
};

const feedbackSlicer = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    getFeedbackStart: (state: IFeedbackState) => {
      state.feedback = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getFeedbackSuccess: (state: IFeedbackState, action: PayloadAction<IFeedback>) => {
      state.feedback = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getFeedbackFailure: (state: IFeedbackState, action: PayloadAction<AxiosError>) => {
      state.feedback = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getFeedbackStart,
  getFeedbackSuccess,
  getFeedbackFailure,
} = feedbackSlicer.actions;
export default feedbackSlicer.reducer;

export const doGetFeedback = (scheduleId: number):AppThunk => async (dispatch) => {
  try {
    dispatch(getFeedbackStart());
    const response = await getFeedback(scheduleId);
    dispatch(getFeedbackSuccess(response.data));
  } catch (e) {
    dispatch(getFeedbackFailure(e));
  }
}
