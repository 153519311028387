import { ChangeEvent, FC, useState } from 'react';
import { Modal, Descriptions, Input, Radio, message, InputNumber } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';
import { ExtraType } from '../../models/Enum';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { ITutorExtraPaymentCreate } from '../../models/payment.model';

interface IProps {
  visible: boolean;

  onOk(body: ITutorExtraPaymentCreate): void;

  onCancel(): void;
}

const AddPaymentModal: FC<IProps> = ({ visible, onOk, onCancel }) => {
  const [name, setName] = useState('');
  const [extraType, setExtraType] = useState<ExtraType>();
  const [amount, setAmount] = useState<number>();
  const [quantinty, setQuantinty] = useState<number>();

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onExtraTypeChange = (event: RadioChangeEvent) => {
    setExtraType(event.target.value as ExtraType);
  };

  const onAmountChange = (value: string | number | null | undefined) => {
    if (typeof value === 'number') {
      setAmount(value);
    }
  };

  const onQuantintyChange = (value: string | number | null | undefined) => {
    if (typeof value === 'number') {
      setQuantinty(value);
    }
  };

  const handleOk = () => {
    if (!name) {
      message.warn('항목명을 작성해주세요.');
      return;
    }
    if (!extraType) {
      message.warn('구분을 선택해주세요.');
      return;
    }
    if (!amount) {
      message.warn('금액을 작성해주세요.');
      return;
    }
    if (!quantinty) {
      message.warn('수량을 작성해주세요.');
      return;
    }
    const data: ITutorExtraPaymentCreate = {
      name,
      extraType,
      amount,
      quantinty,
    };
    onOk(data);
  };

  return (
    <Modal visible={visible} onOk={handleOk} onCancel={onCancel} title='추가 항목 등록' okText='등록' cancelText='취소'>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='항목명' span={2}>
          <Input value={name} onChange={onNameChange} />
        </Descriptions.Item>
        <Descriptions.Item label='구분' span={2}>
          <Radio.Group onChange={onExtraTypeChange}>
            <Radio value={ExtraType.BONUS}>Bonus</Radio>
            <Radio value={ExtraType.DEDUCTION}>Deduction</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item label='금액 (단가)' span={2}>
          <InputNumber value={amount} onChange={onAmountChange} />
        </Descriptions.Item>
        <Descriptions.Item label='수량' span={2}>
          <InputNumber value={quantinty} onChange={onQuantintyChange} width='40%' style={{marginRight:'1.5rem'}} />
          합계: ${(amount && quantinty) ? amount * quantinty : ''}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default AddPaymentModal;
