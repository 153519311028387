import React, { FC } from 'react';
import { BookItem } from '../models/Book';
import { Table } from 'antd';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { LinkStyle, tableStyle } from '../style/antdCssProperty';

interface IProps {
  books: BookItem[];
}

const BookList: FC<IProps> = ({ books }) => {
  const columns = [
    {
      title: 'Full Name',
      key: 'name',
      render: (record: BookItem) => {
        return <Link to={`/book/${record.id}/episode`} style={LinkStyle}>{record.name}</Link>;
      },
    },
    {
      title: 'Short Name',
      key: 'shortName',
      dataIndex: 'shortName',
    },
    {
      title: '등록일',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (record: string) => {
        const date = new Date(record);
        return format(date, 'yyyy.MM.dd kk:mm:ss');
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={books}
        columns={columns}
        size='small'
        pagination={false}
        rowKey='id'
        style={tableStyle} />
    </>
  );
};

export default BookList;