import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { commentUpdate, commentCreate } from '../../store/comment.slice';
import { CommentRequest, Comment, CommentUpdate } from '../../models/Comment';
import { messageAdd, showMessage } from '../../store/message.slice';

interface IProps {
  type: 'create' | 'update';
  commentId?: number;
  item?: Comment
}

interface IMatch {
  id?: string;
}

const { TextArea } = Input;

const CommentEditor: FC<IProps> = ({ type, commentId, item }) => {
  const [content, setContent] = useState('');
  const dispatch = useDispatch();

  const match = useRouteMatch<IMatch>();

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const onClick = () => {
    const boardId = Number(match.params.id);
    switch (type) {
      case 'create':
        if (item) {
          const comment: CommentRequest = {
            content: content, parentId: item.id, seq: item.seq + 1, boardId: boardId,
          };
          console.log(content);
          if (!content) {
            dispatch(messageAdd(showMessage('error', 'write message!')));
            return;
          }
          dispatch(commentCreate(comment));
          return;
        }
        const comment: CommentRequest = {
          content: content, parentId: null, seq: 0, boardId: boardId,
        };
        if (!content) {
          dispatch(messageAdd(showMessage('error', 'write message!')));
          return;
        }
        dispatch(commentCreate(comment));
        break;
      case 'update':
        if (commentId) {
          if (!content) {
            dispatch(messageAdd(showMessage('error', 'write message!')));
            return;
          }
          const contentObject: CommentUpdate = { content };
          dispatch(commentUpdate(commentId, contentObject));
        }
        break;
    }
  };

  return (
    <>
      <Form.Item>
        <TextArea
          rows={4}
          value={content}
          onChange={onChange} />
      </Form.Item>
      <Form.Item>
        <Button htmlType='submit' type='primary' onClick={onClick}>
          Submit
        </Button>
      </Form.Item>
    </>
  );
};

export default CommentEditor;