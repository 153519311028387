import { CommentRequest, CommentUpdate, Comment } from '../models/Comment';
import { AxiosPromise } from 'axios';
import axios from './axiosConfig';

export const create = (data: CommentRequest): AxiosPromise => {
  return axios.post('/api/comments', data);
};

export const list = (boardId: number): AxiosPromise<Comment[]> => {
  return axios.get(`/api/comments/${boardId}`);
};

export const update = (commentId: number, data: CommentUpdate): AxiosPromise => {
  return axios.patch(`/api/comments/${commentId}`, data);
};

export const remove = (commentId: number): AxiosPromise => {
  return axios.delete(`/api/comments/${commentId}`);
};