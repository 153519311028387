import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Select, Input, message, Button } from 'antd';
import { divStyle } from '../../style/antdCssProperty';
import { UserParam } from '../../models/User';
import { list } from '../../api/user';
import { excelInit } from '../../libs/helper';
import { FileExcelOutlined } from '@ant-design/icons';
import { getSession } from '../../libs/session';

interface IProps {
  total: number

  onSubmit(param: UserParam): void;
}

export interface IExcelData {
  id: number;
  name: string;
  email: string;
  phone: string;
  studentName: string;
  isSubscription: string;
}

const { Option } = Select;
const { Search } = Input;

const CustomerFilterBox: FC<IProps> = ({ total, onSubmit }) => {
  const [search, setSearch] = useState('');
  const [select, setSelect] = useState('선택');

  useEffect(() => {
    const sessionParams = getSession<UserParam>('customer');
    console.log('sessionParams: ', sessionParams);
    if (sessionParams) {
      if (sessionParams.search) {
        setSearch(sessionParams.search);
      }
      if (sessionParams.type) {
        setSelect(sessionParams.type);
      }
    }
  }, []);

  const onChange = (value: string) => {
    setSelect(value);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onDataInit = () => {
    if (search && select === '선택') {
      message.warn('타입을 골라주세요.');
    }
    const data: UserParam = {
      type: select,
      search: search.trim(),
    };
    return data;
  };

  const onSearch = () => {
    const data = onDataInit();
    if (data) {
      onSubmit(data);
    }
  };

  const onExcelClick = async () => {
    const data = onDataInit();
    if (data) {
      data.all = true;

      const response = await list(data);
      if (response.data.length >= 1) {
        const excelData: IExcelData[] = [];
        for (const item of response.data) {
          excelData.push({
            id: item.id,
            name: item.name,
            email: item.email,
            phone: item.phone,
            studentName: item.studentName ? item.studentName : '',
            isSubscription: item.userSubscriptionId ? '수강중' : '',
          });
        }
        await excelInit('customer', 'customer', excelData);
      }
    }
  };

  return (
    <div style={divStyle}>
      <Select
        style={{width: '10%'}}
        placeholder='select type'
        value={select}
        onChange={onChange}>
        <Option value='email'>이메일</Option>
        <Option value='name'>학부모이름</Option>
        <Option value='studentName'>자녀 닉네임</Option>
        <Option value='phone'>전화번호</Option>
      </Select>
      <Search placeholder='input search' value={search} onSearch={onSearch} onChange={onSearchChange} style={{
        width: '30rem',
        marginRight: '0.5rem',
      }} />
      total: {total}
      <Button icon={<FileExcelOutlined />} onClick={onExcelClick} style={{ marginLeft: '0.5rem', float: 'right' }} />
    </div>
  );
};

export default CustomerFilterBox;
