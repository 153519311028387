import { ICourse } from '../models/Course';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getCourseList } from '../api/course';

interface ICourseState {
  courses: ICourse[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: ICourseState = {
  courses: [],
  error: undefined,
  isLoading: false,
};

const courseSlicer = createSlice({
  name: 'course',
  initialState,
  reducers: {
    getCourseListStart: (state: ICourseState) => {
      state.courses = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getCourseListSuccess: (state: ICourseState, action: PayloadAction<ICourse[]>) => {
      state.courses = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getCourseListFailure: (state: ICourseState, action: PayloadAction<AxiosError>) => {
      state.courses = [];
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getCourseListStart,
  getCourseListSuccess,
  getCourseListFailure,
} = courseSlicer.actions;
export default courseSlicer.reducer;

export const doGetCourseList = (productId?: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getCourseListStart());
    const response = await getCourseList(productId);
    dispatch(getCourseListSuccess(response.data));
  } catch (e) {
    dispatch(getCourseListFailure(e));
  }
};
