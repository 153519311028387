import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Button, Card, Modal, PageHeader,List } from 'antd';
import { RootState } from '../../store/rootReducer';
import { boardRead, boardRemove, doBoardFileList } from '../../store/board.slice';
import { useRouteMatch } from 'react-router-dom';
import Loader from '../../components/Loader';
import { commentList } from '../../store/comment.slice';
import CommentList from '../../components/Comment/CommentList';
import CommentEditor from '../../components/Comment/CommentEditor';
import styled from 'styled-components';

interface MatchParams {
  id?: string;
}

const ButtonsContainer = styled.div`
  text-align: center;
  padding: 1.5rem;
`;

const BoardPage: FC = () => {
  const { board, isLoading, boardFiles } = useSelector((state: RootState) => state.boardState);
  const commentState = useSelector((state: RootState) => state.commentState);
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const boardId = Number(match.params.id);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(boardRead(boardId));
    dispatch(commentList(boardId));
    dispatch(doBoardFileList(boardId));
  }, [dispatch, boardId]);

  if (isLoading || commentState.isLoading || !board) {
    return <Loader />;
  }

  const showModal = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    setVisible(true);
  };

  const handleOk = () => {
    dispatch(boardRemove(boardId));
    setVisible(false);
    history.goBack();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link
          //@ts-ignore
          to={board!.boardMaster.id <= 2 ? `/boards` : `/resource/${board!.boardMaster.id}`}>{board!.boardMaster.name}</Link></Breadcrumb.Item>
        <Breadcrumb.Item>information</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='information' />
      <Card
        bodyStyle={{
          display: (board && board.content !== null) ? 'block' : 'none',
        }}
        title={board ? board.title : ''}>
        {
          (board && board.content !== null) && <div dangerouslySetInnerHTML={{ __html: board ? board.content : '' }} />
        }
      </Card>
      <List dataSource={boardFiles} renderItem={(item)=>(
        <List.Item>
          <a href={item.url} target='_blank'>{item.filename}</a>
        </List.Item>
      )}/>
      {
        (commentState.comments && commentState.comments.length !== 0) &&
        <>
          <CommentList comments={commentState.comments ? commentState.comments : []} />
          <CommentEditor type='create' />
        </>
      }
      <ButtonsContainer>
        <Button onClick={() => history.push({
          pathname: `/resource/${board.boardMaster.id}/update`,
          state: board.id,
        })}
                style={{
                  marginRight: '1.5rem',
                }}
        >편집</Button>
        <Button onClick={showModal}>삭제</Button>
      </ButtonsContainer>
      <Modal
        title='remove board'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure you want to delete it?</p>
      </Modal>
    </>
  );
};

export default BoardPage;
