import React, { FC, useState } from 'react';
import { Modal, List, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { IDashboardReadList } from '../../models/Schedule';

interface IProps {
  visible: boolean;
  dashboardList: IDashboardReadList[];

  onOk(scheduleId: number): void;

  onCancel(): void;
}

const DashboardModal: FC<IProps> = ({ visible, dashboardList, onOk, onCancel }) => {
  const [scheduleId, setScheduleId] = useState<number>(0);

  const onRadioChange = (e: RadioChangeEvent) => {
    setScheduleId(e.target.value);
  };

  const onTutorNameClick = (scheduleId: number) => {
    setScheduleId(scheduleId);
  };

  const handleOk = () => {
    onOk(scheduleId);
  }

  return (
    <Modal title='강사 리스트' visible={visible} onOk={handleOk} onCancel={onCancel}>
      <Radio.Group onChange={onRadioChange} style={{ width: '100%' }} value={scheduleId}>
        <List
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          bordered
          dataSource={dashboardList}
          renderItem={item => (
            <List.Item style={{
              display: 'block'
            }}>
              <Radio value={item.id} />
              <p style={{
                display: 'inline-block',
                paddingLeft: 0,
                cursor: 'pointer'
              }} onClick={() => onTutorNameClick(item.id)}>{item.staff.firstName} {item.staff.lastName} {item.staff.email}({item.staff.id})</p>
            </List.Item>
          )}
        />
      </Radio.Group>
    </Modal>
  );
};

export default DashboardModal;
