import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Button, Divider, Form, Input, PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { doPatchIssueReply, issueStudent as doGetIssueStudent, issueStudentUpdate } from '../store/issue.slice';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import { IssueStatus } from '../models/Enum';
import IssueInfo from '../components/issue/IssueInfo';

interface IMatch {
  user: string;
  id: string;
}

const { TextArea } = Input;

const IssuePage: FC = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch<IMatch>();
    const history = useHistory();
    const [reply, setReply] = useState('');
    const [reply2, setReply2] = useState('');
    const { issueStudent, isLoading } = useSelector((root: RootState) => root.issueState);
    const { user } = useSelector((root: RootState) => root.authState);

    const id = Number(match.params.id);

    useEffect(() => {
      dispatch(doGetIssueStudent(id));
    }, [id, dispatch]);

    const onReplyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setReply(event.target.value);
    };

    const onReplyChange2 = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setReply2(event.target.value);
    };

    const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      dispatch(issueStudentUpdate(id, {
        reply,
        replyId: user!.id,
        status: IssueStatus.COMPLETE,
      }));
      history.push(`/one/${match.params.user}`);
    };

    const onReply2Click = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      dispatch(doPatchIssueReply(id, { reply2, replyId2: user!.id }));
      history.push(`/one/${match.params.user}`);
    }

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/one/${match.params.user}`}>1:1 문의 관리</Link></Breadcrumb.Item>
          <Breadcrumb.Item>Information</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title='Information' />
        {
          isLoading ? (
            <Loader />
          ) : issueStudent && <>
            <IssueInfo issue={issueStudent} />
            {!issueStudent.reply && <>
              <Divider />
              <Form.Item>
                <TextArea rows={4} value={reply} onChange={onReplyChange} />
              </Form.Item>
              <Form.Item>
                <Button htmlType='submit' type='primary' onClick={onClick}>
                  Submit
                </Button>
              </Form.Item>
            </>}
            {issueStudent.reply && !issueStudent.reply2 && <>
              <Divider />
              <Form.Item>
                <TextArea rows={4} value={reply2} onChange={onReplyChange2} />
              </Form.Item>
              <Form.Item>
                <Button htmlType='submit' type='primary' onClick={onReply2Click}>
                  Submit
                </Button>
              </Form.Item>
            </>}
          </>
        }
      </>
    );
  }
;

export default IssuePage;
