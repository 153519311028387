import { BookItem } from '../models/Book';
import { AxiosPromise } from 'axios';
import axios from './axiosConfig';

export const list = (): AxiosPromise<BookItem[]> => {
  return axios.get(`/api/books`);
};

export const read = (id: number): AxiosPromise<BookItem> => {
  return axios.get(`/api/books/${id}`);
};