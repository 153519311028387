import {
  IMonitoringCallCheck,
  IMonitoringCheck,
  IMonitoringCheckBody,
  IMonitoringCount,
} from '../models/monitoring.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import {
  getMonitoringCount,
  patchMonitoringCallViewed,
  postMonitoringCallCheck,
  postMonitoringCheck,
} from '../api/monitoring.api';

interface IMonitoringState {
  monitoringCount?: IMonitoringCount;
  monitoringCheckList: IMonitoringCheck[];
  monitoringCallCheckList: IMonitoringCallCheck[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IMonitoringState = {
  monitoringCheckList: [],
  monitoringCallCheckList: [],
  isLoading: false,
};

const monitoringSlicer = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    getMonitoringCountStart: (state: IMonitoringState) => {
      state.monitoringCount = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getMonitoringCountSuccess: (state: IMonitoringState, action: PayloadAction<IMonitoringCount>) => {
      state.monitoringCount = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getMonitoringCountFailure: (state: IMonitoringState, action: PayloadAction<AxiosError>) => {
      state.monitoringCount = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    postMonitoringCheckStart: (state: IMonitoringState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    postMonitoringCheckSuccess: (state: IMonitoringState, action: PayloadAction<IMonitoringCheck[]>) => {
      state.monitoringCheckList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    postMonitoringCheckFailure: (state: IMonitoringState, action: PayloadAction<AxiosError>) => {
      state.monitoringCheckList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    postMonitoringCallCheckStart: (state: IMonitoringState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    postMonitoringCallCheckSuccess: (state: IMonitoringState, action: PayloadAction<IMonitoringCallCheck[]>) => {
      state.monitoringCallCheckList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    postMonitoringCallCheckFailure: (state: IMonitoringState, action: PayloadAction<AxiosError>) => {
      state.monitoringCallCheckList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    patchMonitoringCallViewedStart: (state: IMonitoringState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchMonitoringCallViewedSuccess: (state: IMonitoringState, action: PayloadAction<number>) => {
      state.monitoringCallCheckList = state.monitoringCallCheckList.filter((item) => item.id !== action.payload);
      state.isLoading = false;
      state.error = undefined;
    },
    patchMonitoringCallViewedFailure: (state: IMonitoringState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getMonitoringCountStart,
  getMonitoringCountSuccess,
  getMonitoringCountFailure,
  postMonitoringCheckStart,
  postMonitoringCheckSuccess,
  postMonitoringCheckFailure,
  postMonitoringCallCheckStart,
  postMonitoringCallCheckSuccess,
  postMonitoringCallCheckFailure,
  patchMonitoringCallViewedStart,
  patchMonitoringCallViewedSuccess,
  patchMonitoringCallViewedFailure,
} = monitoringSlicer.actions;
export default monitoringSlicer.reducer;

export const doGetMonitoringCount = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getMonitoringCountStart());
    const { data } = await getMonitoringCount();
    dispatch(getMonitoringCountSuccess(data));
  } catch (e) {
    dispatch(getMonitoringCountFailure(e));
  }
};

export const doPostMonitoringCheck = (body: IMonitoringCheckBody): AppThunk => async (dispatch) => {
  try {
    dispatch(postMonitoringCheckStart());
    const { data } = await postMonitoringCheck(body);
    dispatch(postMonitoringCheckSuccess(data));
  } catch (e) {
    dispatch(postMonitoringCheckFailure(e));
  }
};

export const doPostMonitoringCallCheck = (body: IMonitoringCheckBody): AppThunk => async (dispatch) => {
  try {
    dispatch(postMonitoringCallCheckStart());
    const { data } = await postMonitoringCallCheck(body);
    dispatch(postMonitoringCallCheckSuccess(data));
  } catch (e) {
    dispatch(postMonitoringCallCheckFailure(e));
  }
};

export const doPatchMonitoringCallViewed = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(patchMonitoringCallViewedStart());
    await patchMonitoringCallViewed(id);
    dispatch(patchMonitoringCallViewedSuccess(id));
  } catch (e) {
    dispatch(patchMonitoringCallViewedFailure(e));
  }
};
