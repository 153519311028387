import React, { FC, useEffect, useState } from 'react';
import { Button, Descriptions, Divider, Input, Modal, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { staffRead, staffUpdate } from '../store/staff.slice';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import { descriptionContentStyle, descriptionLabelStyle } from '../style/antdCssProperty';
import { FileType, Status, Steps } from '../models/Enum';
import { StaffApplicantPatch } from '../models/Staff';
import { format } from 'date-fns';
import { studyRoomVideoList as studyRoomVideoListSlicer } from '../store/studyRoomVideo.slice';

interface IMatch {
  id: string;
}

const SurveyPage: FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const { isLoading, staff } = useSelector((state: RootState) => state.staffState);
  const { studyRoomVideoList } = useSelector((state: RootState) => state.studyRoomVideoState);
  const [loginProvider, setLoginProvider] = useState('');
  const [isPassModalVisible, setIsPassModalVisible] = useState(false);
  const [isFinalPassModalVisible, setIsFinalPassModalVisible] = useState(false);
  const [isFailModalVisible, setIsFailModalVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(staffRead(Number(match.params.id)));
    dispatch(studyRoomVideoListSlicer(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    setLoginProvider('');
    if (staff) {
      let newLoginProvider;
      for (const item of staff.staffLoginMethods) {
        if (loginProvider === '') {
          newLoginProvider = item.loginProvider;
        } else {
          newLoginProvider = `${loginProvider} / ${item.loginProvider}`;
        }
        setLoginProvider(newLoginProvider);
      }
    }
  }, [staff]);

  const onFailClick = async () => {
    if (staff) {
      const data: StaffApplicantPatch = {
        steps: staff.applicant.steps,
        status: staff.applicant.status,
      };
      switch (staff.applicant.steps) {
        case Steps.SURVEY:
          data.status = Status.FAIL;
          break;
        case Steps.MOCK:
          data.steps = Steps.MOCK_RETRY;
          data.status = Status.APPLYING;
          break;
        case Steps.MOCK_RETRY:
          data.status = Status.FAIL;
          break;
        case Steps.DOCUMENT:
          data.status = Status.FAIL;
          break;
      }
      await dispatch(staffUpdate(Number(match.params.id), data));
    }
  };

  const onPassClick = () => {
    if (staff) {
      const data: StaffApplicantPatch = {
        steps: staff.applicant.steps,
        status: staff.applicant.status,
      };
      switch (staff.applicant.steps) {
        case Steps.SURVEY:
          data.steps = Steps.MOCK;
          data.status = Status.APPLYING;
          break;
        case Steps.MOCK:
        case Steps.MOCK_RETRY:
          data.steps = Steps.DOCUMENT;
          data.status = Status.APPLYING;
          break;
        case Steps.DOCUMENT:
          data.steps = Steps.CONTRACT;
          data.status = Status.APPLYING;
          break;
      }
      dispatch(staffUpdate(Number(match.params.id), data));
    }
  };

  const showPassModal = () => {
    setIsPassModalVisible(true);
  };

  const showFinalPassModal = () => {
    setIsFinalPassModalVisible(true);
  };

  const showFailModal = () => {
    setIsFailModalVisible(true);
  };

  const handlePassOk = () => {
    onPassClick();
    setIsPassModalVisible(false);
  };

  const handleFinalPassOk = () => {
    setIsFinalPassModalVisible(false);
    history.push(`/tutor/${Number(match.params.id)}`);
  };

  const handlePassCancel = () => {
    setIsPassModalVisible(false);
  };

  const handleFinalPassCancel = () => {
    setIsFinalPassModalVisible(false);
  };

  const handleFailOk = () => {
    onFailClick();
    setIsFailModalVisible(false);
  };

  const handleFailCancel = () => {
    setIsFailModalVisible(false);
  };

  if (isLoading || !staff) {
    return <Loader />;
  } else {
    return (
      <>
        <Descriptions title='채용관리' bordered column={2} labelStyle={descriptionLabelStyle}
                      contentStyle={descriptionContentStyle}>
          <Descriptions.Item label='firstName' span={1}>{staff.firstName}</Descriptions.Item>
          <Descriptions.Item label='lastName' span={1}>{staff.lastName}</Descriptions.Item>
          <Descriptions.Item label='Email' span={2}>{staff.email}</Descriptions.Item>
          <Descriptions.Item label='Nationality' span={2}>{staff.country.name}</Descriptions.Item>
          <Descriptions.Item label='Cell Phone' span={2}>{staff.phone}</Descriptions.Item>
          <Descriptions.Item label='Highest Level of Education' span={2}>{staff.educationLevel}</Descriptions.Item>
          <Descriptions.Item label='Certificate of Teaching' span={2}>{staff.certificate}</Descriptions.Item>
          <Descriptions.Item label='Teaching Experience' span={2}>{staff.teachingExperience}</Descriptions.Item>
          <Descriptions.Item label='Referral Code' span={2}>{staff.referralCode}</Descriptions.Item>
          <Descriptions.Item label='Time Zone' span={2}>{staff.timeZone}</Descriptions.Item>
          <Descriptions.Item label='Login Method' span={2}>{loginProvider}</Descriptions.Item>
          <Descriptions.Item label='Step' span={2}>
            <Input
              value={staff.applicant.steps}
              style={{
                width: '120px',
                marginRight: '1rem',
              }}
              readOnly
            />
            <Button type='primary' style={{
              marginRight: '0.2rem',
            }} onClick={showFailModal}>Fail</Button>
            {
              (staff && (staff.applicant.steps === Steps.DOCUMENT)) ? (
                <Button type='primary' onClick={showFinalPassModal}>최종 합격</Button>
              ) : (
                <Button type='primary' onClick={showPassModal}>Pass</Button>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='Status' span={2}>
            <Input
              value={staff.applicant.status}
              style={{
                width: '120px',
              }}
              readOnly
            />
          </Descriptions.Item>
        </Descriptions>
        <Divider />
        <Descriptions bordered column={2} labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Descriptions.Item label='Self Introduction video' span={2}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.SURVEY) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='Mock Recording' span={1}>
            {
              studyRoomVideoList.length === 0 ? (
                <></>
              ) : (
                <Button type='primary'><a href={studyRoomVideoList[0].url}>View</a></Button>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='Mock Retry Recording' span={1}>
            {
              studyRoomVideoList.length >= 2 ? (
                <Button type='primary'><a href={studyRoomVideoList[1].url}>View</a></Button>
              ) : (
                <></>
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='강사 약관 동의' span={2}>{staff.applicant.isContracted ?
            <Radio checked>동의 완료 {format(new Date(staff.applicant.contractDate!), 'yyyy-MM-dd HH:MM:ss')}</Radio> :
            <Radio>동의하지 않음</Radio>}</Descriptions.Item>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Descriptions.Item label='Photo' span={1}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.RESUME) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='Passport picture' span={1}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.PASSPORT) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='Background Check' span={1}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.BACKGROUND_CHECK) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label={`Bachelor's degree`} span={1}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.DEGREE) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='independent' span={1}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.INDEPENDENT) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='exhibit_A' span={1}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.EXHIBIT_A) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='non_disclosure' span={1}>
            {
              staff.applicantFiles.map((item) => {
                  if (item.fileType === FileType.NON_DISCLOSURE) {
                    return <Button type='primary'><a href={item.url}>View</a></Button>;
                  }
                  return <></>;
                },
              )
            }
          </Descriptions.Item>
          <Descriptions.Item label='comment' span={1}>{staff.applicant.staffComments}</Descriptions.Item>
        </Descriptions>
        <Modal title='applicant 합격' visible={isPassModalVisible} onOk={handlePassOk} onCancel={handlePassCancel}>
          합격시키겠습니까?
        </Modal>
        <Modal title='applicant 불합격' visible={isFailModalVisible} onOk={handleFailOk} onCancel={handleFailCancel}>
          불합격시키겠습니까?
        </Modal>
        <Modal title='applicant 최종 합격' visible={isFinalPassModalVisible} onOk={handleFinalPassOk} onCancel={handleFinalPassCancel}>
          <p>최종합격자의 정보는 채용관리에서 강사관리로 이관되며, 강사관리의 상세정보에서 계약기관과 계약서를 업로드 하여야 합니다.</p>
          <br/>
          <p>해당 채용자의 채용을 최종 합격하고 강사관리 페이지로 이동하시겠습니까?</p>
        </Modal>
      </>
    );
  }
};

export default SurveyPage;
