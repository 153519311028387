import { EpisodeCount, EpisodeItem, EpisodeReadItem } from '../models/Episode';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { list, create, update, remove, read, updateAll, updateFile } from '../api/episode';

interface IEpisodeState {
  episodes: EpisodeItem[] | null;
  episode: EpisodeReadItem | null;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IEpisodeState = {
  episodes: null,
  episode: null,
  isLoading: false,
  error: null,
};

const episodeSlicer = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    requestStart: (state: IEpisodeState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestListSuccess: (state: IEpisodeState, action: PayloadAction<EpisodeItem[]>) => {
      state.episodes = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestReadSuccess: (state: IEpisodeState, action: PayloadAction<EpisodeReadItem>) => {
      state.episode = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IEpisodeState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    requestNotResponseSuccess: (state: IEpisodeState) => {
      state.error = null;
      state.isLoading = false;
    },
    doPatchEpisodeFileStart: (state: IEpisodeState) => {
      state.error = null;
      state.isLoading = true;
    },
    doPatchEpisodeFileSuccess: (state: IEpisodeState, action: PayloadAction<{
      id: number,
      type: 'lessonPlan' | 'classTool'
    }>) => {
      if (action.payload.type === 'lessonPlan') {
        state.episodes = state.episodes!.map((item, index) => {
          if (index === action.payload.id) {
            item.lessonPlanName = '';
            item.lessonPlan = '';
          }
          return item;
        });
      } else {
        state.episodes = state.episodes!.map((item, index) => {
          if (index === action.payload.id) {
            item.classToolName = '';
            item.classTool = '';
          }
          return item;
        });
      }
      state.error = null;
      state.isLoading = false;
    },
    doPatchEpisodeFileFailure: (state: IEpisodeState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestReadSuccess,
  requestNotResponseSuccess,
  requestFailure,
  doPatchEpisodeFileStart,
  doPatchEpisodeFileSuccess,
  doPatchEpisodeFileFailure,
} = episodeSlicer.actions;

export default episodeSlicer.reducer;

export const episodeList = (bookId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(bookId);
    dispatch(requestListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const episodeCreate = (data: FormData): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await create(data);
    dispatch(requestNotResponseSuccess());


  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const episodeUpdate = (data: FormData): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await update(data);
    dispatch(requestNotResponseSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const episodeRemove = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await remove(id);
    dispatch(requestNotResponseSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const episodeRead = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await read(id);

    dispatch(requestReadSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const episodeUpdateAll = (data: EpisodeCount[]): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await updateAll(data);
    dispatch(requestNotResponseSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const doPatchEpisodeFile = (id: number, type: 'lessonPlan' | 'classTool', index: number): AppThunk => async (dispatch) => {
  try {
    dispatch(doPatchEpisodeFileStart());
    await updateFile(id, type);
    dispatch(doPatchEpisodeFileSuccess({
      id: index,
      type,
    }));
  } catch (e) {
    dispatch(doPatchEpisodeFileFailure(e));
  }
};
