import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { userList } from '../store/user.slice';
import { Breadcrumb, Col, PageHeader, Pagination, Row } from 'antd';
import UserList from '../components/UserList';
import { Link } from 'react-router-dom';
import { UserParam } from '../models/User';
import CustomerFilterBox from '../components/CustomerList/CustomerFilterBox';
import { getSession, setSession } from '../libs/session';

const CustomerListPage: FC = () => {
  const dispatch = useDispatch();
  const { users, total } = useSelector((state: RootState) => state.userState);
  const [current, setCurrent] = useState(1);
  const [param, setParam] = useState<UserParam>();

  const onChange = (page: number) => {
    const newParams: UserParam = {
      ...param,
      page,
    };
    setSession('customer', newParams);
    setCurrent(page);
  };

  useEffect(() => {
    const sessionParams = getSession<UserParam>('customer');
    if (sessionParams) {
      if (sessionParams.page) {
        setCurrent(sessionParams.page);
      }
      dispatch(userList(sessionParams));
    } else {
      const data: UserParam = {
        ...param,
        page: current,
      };
      dispatch(userList(data));
    }
  }, [dispatch, current]);

  useEffect(() => {
    if (param) {
      setSession('customer', param);
    }
  }, [param]);

  const onSubmit = (param: UserParam): void => {
    param.page = current;
    setParam(param);
    dispatch(userList(param));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>회원관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='회원관리'>
      </PageHeader>
      <CustomerFilterBox onSubmit={onSubmit} total={total} />
      <Row gutter={10}>
        <Col span={24}>
          <UserList users={users ? users : []} />
        </Col>
      </Row>
      <Pagination
        current={current}
        onChange={onChange}
        defaultPageSize={20}
        total={total}
        style={{ marginTop: '1.5rem' }}
        showSizeChanger={false}
      />
    </>
  );
};

export default CustomerListPage;
