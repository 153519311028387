import { useRef, FC, MouseEvent, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BoardListItem } from 'models/Board';
import { Breadcrumb, Button, Modal, PageHeader, Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { boardRemove } from '../store/board.slice';
import { localDateFormat } from '../libs/helper';

interface IProps {
  boards: BoardListItem[];
  title: string;
}

const BoardList: FC<IProps> = ({ boards, title }) => {
  const history = useHistory();
  const id = useRef(0);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const onEditClick = useCallback((e: MouseEvent<HTMLSpanElement>, record: BoardListItem) => {
    e.preventDefault();
    history.push(`/board/edit/${record.id}`);
  }, [history]);

  const onRemoveClick = useCallback((e: MouseEvent<HTMLSpanElement>, record: BoardListItem) => {
    e.preventDefault();
    showModal();
    id.current = record.id;
  }, []);


  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    dispatch(boardRemove(id.current));
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: 'Title',
      key: 'title',
      render: (record: BoardListItem) => {
        return <Link to={`/boards/${record.id}`}>{record.title}</Link>;
      },
    },
    {
      title: 'Writer',
      key: 'name',
      render: (record: BoardListItem) => {
        return `${record.staffFirstName} ${record.staffLastName}`;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record: string) => {
        return localDateFormat(record);
      },
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (record: BoardListItem) => (
        <EditOutlined
          onClick={(e: MouseEvent<HTMLSpanElement>) => onEditClick(e, record)}
        />
      ),
    },
    {
      title: 'Remove',
      key: 'remove',
      render: (record: BoardListItem) => (
        <DeleteOutlined
          onClick={(e: MouseEvent<HTMLSpanElement>) => onRemoveClick(e, record)}
        />
      ),
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={title}>
      </PageHeader>
      <Tooltip title='write'>
        <Button
          type='primary'
          icon={<EditOutlined />}
          style={{
            marginBottom: '1.5rem',
            float: 'right',
            background: '#001529',
          }}
          onClick={() => history.push(`/boards/${boards.length >= 1 ? boards[0].boardMasterId :'1'}/create`)}
        >
        </Button>
      </Tooltip>
      <Table
        dataSource={boards}
        columns={columns}
        size='small'
        pagination={false}
        rowKey='id' />
      <Modal
        title='remove board'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure you want to delete it?</p>
      </Modal>
    </>
  );
};

export default BoardList;

