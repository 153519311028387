import React, { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TutorScheduleMasterResponse } from '../../models/TutorScheduleMaster';
import { getDayOfWeekString } from '../../libs/helper';
import { tableStyle } from '../../style/antdCssProperty';

interface IProps {
  tutorScheduleMasters: TutorScheduleMasterResponse[];
}

const TutorScheduleMaster: FC<IProps> = ({ tutorScheduleMasters }) => {
  const columns: ColumnsType<TutorScheduleMasterResponse> = [
    {
      title: '요일',
      key: 'dayOfWeek',
      render: (value, record, index) => (
        getDayOfWeekString(record.dayOfWeek)
      ),
    },
    {
      title: '시간',
      key: 'time',
      render: (value, record, index) => (
        `${record.startClassTime} ~ ${record.endClassTime}`
      ),
    },
    {
      title: '시작일',
      key: 'startDate',
      dataIndex: 'startDate',
    },
  ];
  return (
    <>
      <Table
        style={tableStyle}
        dataSource={tutorScheduleMasters}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false} />
    </>
  );
};

export default TutorScheduleMaster;