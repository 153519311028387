import React, { FC, useEffect, useState } from 'react';
import { divStyle } from '../../style/antdCssProperty';
import { Select, Input, Button } from 'antd';
import { ProductItem } from '../../models/Product';
import { ICourse } from '../../models/Course';
import { IUserSubscriptionListParam } from '../../models/UserSubscription';
import { FileExcelOutlined } from '@ant-design/icons';
import { getUserSubscriptionList, getUserUnsubscriptionList } from '../../api/userSubscription';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { excelInit } from '../../libs/helper';
import { getSession } from '../../libs/session';
import CustomDatePicker from '../common/CustomDatePicker';
import { ProductCourseType, productCourseTypes } from '../../models/Enum';

interface IExcelData {
  id: number;
  isActive: boolean;
  isCanceled: boolean;
  createdAt: string;
  productId: number;
  productName: string;
  studentId: number;
  studentName: string;
  userId: number;
  phone: string;
  userEmail: string;
  userName: string;
  courseId: number;
  courseName: string;
}

interface IExcelDataForUnsubscription {
  Id: number;
  수업구분: string;
  과정: string;
  고객명: string;
  고객Id: number;
  전화번호: string;
  학생명: string;
  학생Id: number;
  이메일: string;
  해지: boolean;
  신청일: string;
  구독해지일: string;
  종료일: string;
  해지사유: string;
  상세사유: string;
}

interface IProps {
  products: ProductItem[];
  courses: ICourse[];
  total: number;
  deactive: boolean;

  onSubmit(params?: IUserSubscriptionListParam): void;
}

const { Option } = Select;
const { Search } = Input;

const SubscriptionFilterBox: FC<IProps> = ({ products, courses, total, deactive, onSubmit }) => {
  const [courseType, setCourseType] = useState<'구분' | ProductCourseType>('구분');
  const [courseId, setCourseId] = useState<'과정' | number>('과정');
  const [isActive, setIsActive] = useState('상태');
  const [type, setType] = useState('userName');
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    const sessionParams = deactive
      ? getSession<IUserSubscriptionListParam>('unsubscription')
      : getSession<IUserSubscriptionListParam>('subscription');
    if (sessionParams) {
      if (sessionParams.courseType) setCourseType(sessionParams.courseType);
      else setCourseType('구분');

      if (sessionParams.courseId) setCourseId(sessionParams.courseId);
      else setCourseId('과정');

      try {
        if (sessionParams.isCanceled === true) setIsActive('true');
        else if (sessionParams.isCanceled === false) setIsActive('false');
      } catch (e) {
        setIsActive('상태');
      }

      if (sessionParams.type) setType(sessionParams.type);
      else setType('userName');

      if (sessionParams.search) setSearch(sessionParams.search);
      else setSearch('');

      if (sessionParams.startDate) setStartDate(sessionParams.startDate);
      else setStartDate('');

      if (sessionParams.endDate) setEndDate(sessionParams.endDate);
      else setEndDate('');
    }
  }, [deactive]);

  const onCourseTypeChange = (value: '구분' | ProductCourseType) => {
    setCourseType(value);
  };

  const onCourseIdChange = (value: '과정' | number) => {
    setCourseId(value);
  };

  const onIsActiveChange = (value: string) => {
    setIsActive(value);
  };

  const onTypeChange = (value: string) => {
    setType(value);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onStartDateChange = (date: any, dateString: string) => {
    setStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: string) => {
    setEndDate(dateString);
  };

  const initData = () => {
    const params: IUserSubscriptionListParam = {};

    if (courseType !== '구분') {
      params.courseType = courseType;
    }
    if (courseId !== '과정') {
      params.courseId = courseId;
    }
    if (isActive !== '상태') {
      if (isActive === 'true') params.isCanceled = true;
      else params.isCanceled = false;
    }
    if (deactive) params.isCanceled = false;
    if (search) {
      params.type = type;
      params.search = search.trim();
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }

    return params;
  };

  const onSearchClick = () => {
    const params = initData();
    onSubmit(params);
  };

  const onExcelClick = async () => {
    const params = initData();
    deactive ? params.isAll = true : params.all = true;
    const response = deactive
      ? await getUserUnsubscriptionList(params)
      : await getUserSubscriptionList(params);

    if (response.data.length >= 1) {
      const excelData: (IExcelData | IExcelDataForUnsubscription)[] = [];
      for (const item of response.data) {
        deactive
          ? excelData.push({
            Id: item.id,
            수업구분: item.product.name,
            과정: item.course.name,
            고객명: item.student.user.name,
            고객Id: item.student.user.id,
            전화번호: item.student.user.phone,
            학생명: item.student.name,
            학생Id: item.student.id,
            이메일: item.student.user.email,
            해지: item.isCanceled,
            신청일: format(utcToZonedTime(new Date(item.createdAt), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            구독해지일: format(utcToZonedTime(new Date(item.unsubscription.createdAt), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            종료일: format(utcToZonedTime(new Date(item.unsubscription.endDate), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
          // @ts-ignore
            최종결제일: format(utcToZonedTime(new Date(item.userTransaction.createdAt), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            해지사유: item.unsubscription.unsubscriptionReason,
            상세사유: item.unsubscription.reasonDetail,
          })
          : excelData.push({
            id: item.id,
            isActive: item.isActive,
            isCanceled: item.isCanceled,
            createdAt: format(utcToZonedTime(new Date(item.createdAt), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            productId: item.product.id,
            productName: item.product.name,
            studentId: item.student.id,
            studentName: item.student.name,
            userId: item.student.user.id,
            phone: item.student.user.phone,
            userEmail: item.student.user.email,
            userName: item.student.user.name,
            courseId: item.course.id,
            courseName: item.course.name,
          });
      }
      deactive
        ? await excelInit('unsubscription', 'unsubscription', excelData)
        : await excelInit('subscription', 'subscription', excelData);
    }
  };

  return (
    <div style={divStyle}>
      <Select style={{ width: '10%' }} value={courseType} onChange={onCourseTypeChange}>
        <Option value='구분'>구분</Option>
        {productCourseTypes.map(item => (
          <Option value={item} key={`product-${item}`}>{item}</Option>
        ))}
      </Select>
      <Select style={{ width: '10%' }} value={courseId} onChange={onCourseIdChange}>
        <Option value='과정'>과정</Option>
        {courses.map(item => (
          <Option value={item.id} key={`course-${item.id}`}>{item.name}</Option>
        ))}
      </Select>
      {
        deactive
          ? null
          : (
            <Select style={{ width: '10%' }} value={isActive} onChange={onIsActiveChange}>
              <Option value='상태'>상태</Option>
              <Option value='true'>active</Option>
              <Option value='false'>inActive</Option>
            </Select>
          )
      }
      <Select style={{ width: '10%' }} value={type} onChange={onTypeChange}>
        <Option value='userName'>고객이름</Option>
        <Option value='studentName'>학생이름</Option>
        <Option value='email'>이메일</Option>
        <Option value='phone'>전화번호</Option>
      </Select>
      <Search style={{ width: '20%' }} enterButton='검색' value={search} onChange={onSearchChange}
              onSearch={onSearchClick} />
      신청일
      <CustomDatePicker value={startDate ? new Date(startDate) : undefined} onChange={onStartDateChange} />
      ~
      <CustomDatePicker value={endDate ? new Date(endDate) : undefined} onChange={onEndDateChange} />
      <Button type='primary' onClick={onSearchClick} style={{ marginRight: '0.5rem' }}>검색</Button>
      total: {total}
      <Button icon={<FileExcelOutlined />} onClick={onExcelClick} style={{ marginLeft: '0.5rem', float: 'right' }} />
    </div>
  );
};

export default SubscriptionFilterBox;
