import { ChangeEvent, FC } from 'react';
import { Descriptions, Input, Modal, Radio, Select, message } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';
import { FailReason } from '../../models/Enum';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { setUserTransactionBody } from '../../store/userTransaction.slice';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

const { Option } = Select;
const { TextArea } = Input;

const reasons: Array<{ text: string, value: FailReason }> = [
  {
    text: 'Other',
    value: FailReason.OTHER,
  },
  {
    text: 'S-No Show',
    value: FailReason.STUDENT_NOSHOW,
  },
  {
    text: 'T-No Show',
    value: FailReason.TUTOR_NOSHOW,
  },
  {
    text: 'All-No Show',
    value: FailReason.ALL_NOSHOW,
  },
  {
    text: 'S-IT Issue',
    value: FailReason.STUDENT_IT_ISSUE,
  },
  {
    text: 'T-IT Issue',
    value: FailReason.TUTOR_IT_ISSUE,
  },
  {
    text: 'C-IT Issue',
    value: FailReason.COMPANY_IT_ISSUE,
  },
];

const counts: Array<number> = [1, 2, 3];

interface IProps {
  visible: boolean;

  onOk(): void;

  onCancel(): void;
}

const CustomerAddClassCountModal: FC<IProps> = ({ visible, onOk, onCancel }) => {
  const dispatch = useDispatch();

  const { body } = useSelector((state: RootState) => state.userTransactionState);

  const onReasonChange = (value: FailReason) => {
    if (value !== FailReason.TEMP) {
      dispatch(setUserTransactionBody({
        ...body,
        addClassCount: { ...body?.addClassCount, reason: value },
      }));
    }
  };

  const onReasonDetailChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => dispatch(setUserTransactionBody({
    ...body,
    addClassCount: { ...body?.addClassCount, reasonDetail: value },
  }));

  const onCntChange = (cnt: number) => {
    if (cnt !== 0) {
      dispatch(setUserTransactionBody({
        ...body,
        addClassCount: { ...body?.addClassCount, cnt },
      }));
    }
  };

  const onIsEpisodeRecoveryChange = (e: RadioChangeEvent) => dispatch(setUserTransactionBody({
    ...body,
    isEpisodeRecovery: e.target.value,
  }));

  const onLocalOk = () => {
    if (!body?.addClassCount?.reason) {
      message.warn('사유를 선택해주세요.');
      return;
    }
    if (!body?.addClassCount?.reasonDetail) {
      message.warn('상세 사유를 작성해주세요.');
      return;
    }
    if (!body?.addClassCount?.cnt) {
      message.warn('추가 갯수를 선택해주세요.');
      return;
    }
    onOk();
  }

  return <Modal title='수업권 추가' visible={visible} onOk={onLocalOk} onCancel={onCancel}>
    <Descriptions column={1} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
      <Descriptions.Item label='사유' span={1}>
        <Select onChange={onReasonChange} defaultValue={FailReason.TEMP} value={body?.addClassCount?.reason}
                style={{ minWidth: 120 }}>
          <Option disabled value={FailReason.TEMP}>선택</Option>
          {reasons.map(({ text, value }, index) => <Option key={`reason-${index}`} value={value}>{text}</Option>)}
        </Select>
      </Descriptions.Item>
      <Descriptions.Item label='상세 사유' span={1}>
        <TextArea onChange={onReasonDetailChange} value={body?.addClassCount?.reasonDetail} />
      </Descriptions.Item>
      <Descriptions.Item label='추가 갯수' span={1}>
        <Select onChange={onCntChange} defaultValue={0} value={body?.addClassCount?.cnt} style={{ minWidth: 120 }}>
          <Option disabled value={0}>선택</Option>
          {counts.map((item) => <Option key={`cnt-${item}`} value={item}>{item}</Option>)}
        </Select>
      </Descriptions.Item>
      <Descriptions.Item label='차시 복구 여부' span={1}>
        <Radio.Group onChange={onIsEpisodeRecoveryChange} value={body?.isEpisodeRecovery}>
          <Radio value={true}>Y</Radio>
          <Radio value={false}>N</Radio>
        </Radio.Group>
      </Descriptions.Item>
    </Descriptions>
  </Modal>;
};

export default CustomerAddClassCountModal;
