import React, { FC } from 'react';
import { IContract } from '../../models/Contract';
import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { divStyle, LinkStyle, tableStyle } from '../../style/antdCssProperty';
import { contractIsActive } from '../../libs/helper';

interface IProps {
  contractList: IContract[];

  onClick(event: React.MouseEvent<HTMLElement, MouseEvent>): void;

  onUpdateClick(event: React.MouseEvent<HTMLParagraphElement, MouseEvent>, index: number): void;
}

const TutorContractTable: FC<IProps> = ({ contractList, onClick, onUpdateClick }) => {
  const columns: ColumnsType<IContract> = [
    {
      title: '구분',
      key: 'isValid',
      render: (value, record) => (contractIsActive(record.startDate, record.endDate) ? 'Active' : 'InActive'),
    },
    {
      title: '계약 기간',
      key: 'date',
      render: (value, record, index) => (
        <p style={{ ...LinkStyle, cursor: 'pointer' }}
           onClick={e => onUpdateClick(e, index)}>{record.startDate} ~ {record.endDate}</p>),
    },
    {
      title: 'Base Pay',
      key: 'classPerPay',
      dataIndex: 'classPerPay',
    },
    {
      title: 'Contract',
      key: 'contract',
      render: (value, record) => (<a target='_blank' href={record.contractFiles}>View</a>),
    },
    {
      title: '계약일',
      key: 'createdAt',
      render: (value, record) => (format(new Date(record.createdAt), 'yyyy.MM.dd')),
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={contractList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
      <div style={divStyle}>
        <Button type='primary' onClick={onClick}>등록</Button>
      </div>
    </>
  );
};

export default TutorContractTable;
