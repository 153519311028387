import {
  IGetStaffListSuccess, IGetStaffListSuccess2, IStaff, IStaffListQuery, IStaffRegister,
  StaffApplicantPatch,
  StaffListQuery,
  StaffTutorListItem,
  StaffTutorReadItem,
} from '../models/Staff';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getStaffList, list, postStaff, read, update } from '../api/staff';
import { message } from 'antd';

interface IStaffState {
  staffs: StaffTutorListItem[];
  staff: StaffTutorReadItem | null;
  staffList: IStaff[];
  total: number;
  isLoading: boolean;
  isCreated: boolean;
  error: AxiosError | null;
}

const initialState: IStaffState = {
  staffs: [],
  staffList: [],
  staff: null,
  total: 0,
  isLoading: false,
  isCreated: false,
  error: null,
};

const staffSlicer = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    requestStart: (state: IStaffState) => {
      state.staffs = [];
      state.staff = null;
      state.total = 0;
      state.error = null;
      state.isLoading = true;
    },
    requestListSuccess: (state: IStaffState, action: PayloadAction<IGetStaffListSuccess>) => {
      state.staffs = action.payload.data;
      state.total = action.payload.total;
      state.error = null;
      state.isLoading = false;
    },
    requestReadSuccess: (state: IStaffState, action: PayloadAction<StaffTutorReadItem>) => {
      state.staff = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestUpdateSuccess: (state: IStaffState) => {
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IStaffState, action: PayloadAction<AxiosError>) => {
      state.staffs = [];
      state.error = action.payload;
      state.isLoading = false;
    },
    requestTutorContractCreate: (state: IStaffState) => {
      state.error = null;
      state.isLoading = false;
    },
    getStaffListStart: (state: IStaffState) => {
      state.staffList = [];
      state.total = 0;
      state.isLoading = true;
      state.error = null;
    },
    getStaffListSuccess: (state: IStaffState, action: PayloadAction<IGetStaffListSuccess2>) => {
      state.staffList = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = null;
    },
    getStaffListFailure: (state: IStaffState, action: PayloadAction<AxiosError>) => {
      state.staffList = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
    postStaffStart: (state: IStaffState) => {
      state.isCreated = false;
      state.isLoading = true;
      state.error = null;
    },
    postStaffSuccess: (state: IStaffState, action: PayloadAction<IStaff>) => {
      const newStaff = [...state.staffList];
      newStaff.push(action.payload);
      state.staffList = newStaff;
      state.isCreated = true;
      state.isLoading = false;
      state.error = null;
    },
    postStaffFailure: (state: IStaffState, action: PayloadAction<AxiosError>) => {
      state.isCreated = false;
      state.isLoading = false;
      state.error = action.payload;
    },
    staffSliceDeleteStaffSuccess: (state: IStaffState, action: PayloadAction<number>) => {
      state.staffList = state.staffList.filter(item => item.id !== action.payload);
    },
    initIsCreated(state: IStaffState) {
      state.isCreated = false;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestReadSuccess,
  requestUpdateSuccess,
  requestFailure,
  requestTutorContractCreate,
  getStaffListStart,
  getStaffListSuccess,
  getStaffListFailure,
  postStaffStart,
  postStaffSuccess,
  postStaffFailure,
  staffSliceDeleteStaffSuccess,
  initIsCreated,
} = staffSlicer.actions;

export default staffSlicer.reducer;

export const staffList = (params?: StaffListQuery): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(params);
    const data: IGetStaffListSuccess = {
      data: response.data, total: response.headers['total'],
    };
    dispatch(requestListSuccess(data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const staffRead = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await read(id);
    dispatch(requestReadSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const staffUpdate = (staffId: number, data: StaffApplicantPatch): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await update(staffId, data);
    dispatch(requestReadSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const doGetStaffList = (param?: IStaffListQuery): AppThunk => async (dispatch) => {
  try {
    dispatch(getStaffListStart());
    const response = await getStaffList(param);
    const data: IGetStaffListSuccess2 = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getStaffListSuccess(data));
  } catch (e) {
    dispatch(getStaffListFailure(e));
  }
};

export const doPostStaff = (body: IStaffRegister): AppThunk => async (dispatch) => {
  try {
    dispatch(postStaffStart());
    const { data } = await postStaff(body);
    dispatch(postStaffSuccess(data));
    message.success('스태프를 생성하였습니다.');
  } catch (e) {
    dispatch(postStaffFailure(e));
  }
};
