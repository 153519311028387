import { FC, useState } from 'react';
import { format, addHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useDispatch, useSelector } from 'react-redux';
import { doDashboardScheduleList } from '../store/schedule.slice';
import { IDashboardSchedule, IDashboardScheduleParam } from '../models/Schedule';
import { dashboardScheduleList as dashboardScheduleListAPI } from '../api/schedule';
import DashboardTable from '../components/dashboard/DashboardTable';
import { RootState } from '../store/rootReducer';
import { excelInit, getMonthDay } from '../libs/helper';
import DashboardMain from '../components/dashboard/DashboardMain';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import CustomDatePicker from '../components/common/CustomDatePicker';

const DashBoardPage: FC = () => {
  const dispatch = useDispatch();
  const [month, setMonth] = useState('');

  const { dashboardScheduleList } = useSelector((root: RootState) => root.scheduleState);

  const onExcelClick = async () => {
    if (month) {
      const { firstDay, lastDay } = getMonthDay(month);
      const formatStartDate = format(firstDay, 'yyyy-MM-dd');
      const formatEndDate = format(addHours(lastDay, 24), 'yyyy-MM-dd');
      const params: IDashboardScheduleParam = {
        endDateTime: formatEndDate,
        startDateTime: formatStartDate,
      };
      const response = await dashboardScheduleListAPI(params);
      if (response.data.length >= 1) {
        const newDataList: IDashboardSchedule[] = [];
        for (const item of response.data) {
          newDataList.push({
            ...item,
            startDateTime: format(utcToZonedTime(new Date(item.startDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
          });
        }
        await excelInit('dashboard', 'dashboard', newDataList);
      }
    } else {
      if (dashboardScheduleList.length >= 1) {
        const newDataList: IDashboardSchedule[] = [];
        for (const item of dashboardScheduleList) {
          newDataList.push({
            ...item,
            startDateTime: format(utcToZonedTime(new Date(item.startDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            tutorEnterDateTime: item.tutorEnterDateTime ? format(utcToZonedTime(new Date(item.tutorEnterDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            studentEnterDateTime: item.studentEnterDateTime ? format(utcToZonedTime(new Date(item.studentEnterDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
          });
        }
        await excelInit('dashboard', 'dashboard', newDataList);
      }
    }
    return;
  };

  const onMonthChange = (value: Date | null, dateString: string) => {
    setMonth(dateString);
  };

  const onChangeDate = (startDate: string, endDate: string) => {
    const params: IDashboardScheduleParam = {
      endDateTime: endDate,
      startDateTime: startDate,
    };
    dispatch(doDashboardScheduleList(params));
  };

  return (
    <DashboardMain title='DashBoard' table={<DashboardTable dashboardScheduleList={dashboardScheduleList} />}
                   sessionName='dashboard' onChangeDate={onChangeDate} excelButton={<>
      <Button icon={<FileExcelOutlined style={{ fontSize: 36 }} />} block={false} size='large'
              style={{ border: 'none', float: 'right' }} onClick={onExcelClick} />
      <CustomDatePicker onChange={onMonthChange} picker='month' style={{ float: 'right', marginTop: 7.5 }} />
    </>} />
  );
};

export default DashBoardPage;
