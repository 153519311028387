import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from 'containers/LoginPage';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import LoginPage from 'containers/LoginPage';

const RootRouter = () => {
  const { user } = useSelector((root: RootState) => root.authState);

  if (!user) {
    return <LoginPage />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path='/login' component={Login} />
        <Redirect path='/' to='/login' exact />
      </Switch>
    </BrowserRouter>
  );
};

export default RootRouter;
