import {
  IGetScheduleCancelLogListSuccess,
  IScheduleCancelLog,
  IScheduleCancelLogParam,
} from '../models/scheduleCancelLog.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getScheduleCancelLogList } from '../api/scheduleCancelLog.api';

interface IScheduleCancelLogState {
  scheduleCancelLogList: IScheduleCancelLog[];
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IScheduleCancelLogState = {
  scheduleCancelLogList: [],
  total: 0,
  isLoading: false,
};

const scheduleCancelLogSlice = createSlice({
  name: 'scheduleCancelLog',
  initialState,
  reducers: {
    getScheduleCancelLogListStart: (state: IScheduleCancelLogState) => {
      state.scheduleCancelLogList = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getScheduleCancelLogListSuccess: (state: IScheduleCancelLogState, action: PayloadAction<IGetScheduleCancelLogListSuccess>) => {
      state.scheduleCancelLogList = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getScheduleCancelLogListFailure: (state: IScheduleCancelLogState, action: PayloadAction<AxiosError>) => {
      state.scheduleCancelLogList = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getScheduleCancelLogListStart,
  getScheduleCancelLogListSuccess,
  getScheduleCancelLogListFailure,
} = scheduleCancelLogSlice.actions;
export default scheduleCancelLogSlice.reducer;

export const doGetScheduleCancelLogList = (params?: IScheduleCancelLogParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getScheduleCancelLogListStart());
    const { data, headers } = await getScheduleCancelLogList(params);
    const success: IGetScheduleCancelLogListSuccess = {
      data, total: headers['total'],
    };
    dispatch(getScheduleCancelLogListSuccess(success));
  } catch (e) {
    dispatch(getScheduleCancelLogListFailure(e));
  }
};
