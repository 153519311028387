import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ColumnsType } from 'antd/lib/table';
import { IEvaluationDetail } from '../../models/evaluation.model';
import { Descriptions, Table } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle, tableStyle } from '../../style/antdCssProperty';

const DashboardEvaluation2: FC = () => {
  const { evaluation, evaluationDescriptions } = useSelector((root: RootState) => root.evaluationState);

  const columns: ColumnsType<IEvaluationDetail> = [
    {
      title: '항목',
      key: 'name',
      render: (value, record) => record.evaluationItem.name,
    },
    {
      title: '의견',
      key: 'description',
      render: (value, record) => getDescription(record.score, record.evaluationItem.id),
    },
    {
      title: '점수',
      key: 'score',
      render: (value, record) => record.score,
    },
  ];

  const getDescription = (inputScore: number, itemId: number): string => {
    if (evaluationDescriptions.length === 0) return '';
    const filter = evaluationDescriptions.filter(({
                                                    evaluationItemId,
                                                    score,
                                                  }) => score === inputScore && itemId === evaluationItemId);
    if (filter.length === 1) return filter[0].koDescription;
    else return '';
  };

  return (
    <>
      <Table columns={columns} style={{ ...tableStyle, marginTop: '1.5rem' }} dataSource={evaluation?.evaluationDetails}
             size='small' rowKey='id' pagination={false} />
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}
                    style={{ marginTop: '1.5rem' }}>
        <Descriptions.Item label='잘한 점' span={1}>
          {evaluation?.strengths ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label='도움이 필요한 점' span={1}>
          {evaluation?.improvements ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label='Comments for HR' span={2}>
          {evaluation?.comment ?? ''}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
    ;
};

export default DashboardEvaluation2;
