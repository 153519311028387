import React, { FC } from 'react';
import { Descriptions, Table } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle, tableStyle } from '../../style/antdCssProperty';
import { IEvaluation2, IEvaluationDetail2 } from '../../models/evaluation.model';
import { format } from 'date-fns';
import { ColumnsType } from 'antd/lib/table';

interface IProps {
  evaluation?: IEvaluation2;
}

const DashboardEvaluation: FC<IProps> = ({ evaluation }) => {
  const columns: ColumnsType<IEvaluationDetail2> = [
    {
      title: '항목',
      key: 'name',
      render: (value, record) => record.evaluationItem.name,
    },
    {
      title: '점수',
      key: 'score',
      render: (value, record) => record.score,
    },
    {
      title: '설명',
      key: 'description',
      render: (value, record) => record.evaluationItem.description,
    },
  ];

  return (
    <>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle} style={{
        marginTop: '1.5rem'
      }}>
        <Descriptions.Item label='튜터 평가' span={1}>
          {evaluation?.comment}
        </Descriptions.Item>
        <Descriptions.Item label='다음수업의 튜터에게 남길 말' span={1}>
          {evaluation?.toNextTutor}
        </Descriptions.Item>
        <Descriptions.Item label='EggSchool에게 남길 말' span={1}>
          {evaluation?.toEggSchool}
        </Descriptions.Item>
        <Descriptions.Item label='평균' span={1}>
          {evaluation?.average}
        </Descriptions.Item>
        <Descriptions.Item label='퍼센트' span={1}>
          {evaluation?.percentage ? `${evaluation.percentage}%` : ''}
        </Descriptions.Item>
        <Descriptions.Item label='고객 확인' span={1}>
          {evaluation?.isCustomerViewed ? '확인' : '확인 불가'}
        </Descriptions.Item>
        <Descriptions.Item label='작성 시간' span={1}>
          {evaluation?.createdAt ? format(new Date(evaluation.createdAt), 'yyyy-MM-dd HH:mm:ss') : ''}
        </Descriptions.Item>
      </Descriptions>
      <Table
        style={{
          ...tableStyle,
          marginTop: '1.5rem'
        }}
        dataSource={evaluation ? evaluation.evaluationDetails : []}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
    </>
  );
};

export default DashboardEvaluation;
