import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { CouponCreate, CouponListItem, CouponListQuery, CouponListResponse } from '../models/Coupon';

export const list = (search?: string): AxiosPromise<CouponListItem[]> => {
  let url = '/api/coupons';
  if (search) {
    url += `?search=${search}`;
  }
  return axios.get(url);
};

export const create = (data: CouponCreate): AxiosPromise<void> => {
  return axios.post('/api/coupons', data);
};

export const couponList = (data: CouponListQuery): AxiosPromise<CouponListResponse[]> => {
  let url = `/api/coupons/list?couponId=${data.couponId}`;
  if (data.name) {
    url += `&name=${data.name}`;
  }
  if (data.page) {
    url += `&page=${data.page}`;
  }
  return axios.get(url);

};

export const remove = (id: number): AxiosPromise<void> => {
  return axios.delete(`/api/coupons/list/${id}`);
};

export const postLevelTestCoupon = (studentId: number): AxiosPromise<void> => {
  return axios.post(`/api/coupons/level-test`, { studentId });
};
