import { IEvaluation, IEvaluation2, IEvaluationDescriptions } from '../models/evaluation.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getEvaluation, getEvaluation2, getEvaluationDescriptionList } from '../api/evaluation.api';

interface IEvaluationState {
  evaluation2?: IEvaluation2;
  evaluation?: IEvaluation;
  evaluationDescriptions: IEvaluationDescriptions[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IEvaluationState = {
  evaluationDescriptions: [],
  isLoading: false,
};

const evaluationSlicer = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    getEvaluation2Start: (state: IEvaluationState) => {
      state.evaluation2 = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getEvaluation2Success: (state: IEvaluationState, action: PayloadAction<IEvaluation2>) => {
      state.evaluation2 = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getEvaluation2Failure: (state: IEvaluationState, action: PayloadAction<AxiosError>) => {
      state.evaluation2 = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getEvaluationStart: (state: IEvaluationState) => {
      state.evaluation = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getEvaluationSuccess: (state: IEvaluationState, action: PayloadAction<IEvaluation>) => {
      state.evaluation = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getEvaluationFailure: (state: IEvaluationState, action: PayloadAction<AxiosError>) => {
      state.evaluation = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getEvaluationDescriptionListStart: (state: IEvaluationState) => {
      state.evaluationDescriptions = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getEvaluationDescriptionListSuccess: (state: IEvaluationState, action: PayloadAction<IEvaluationDescriptions[]>) => {
      state.evaluationDescriptions = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getEvaluationDescriptionListFailure: (state: IEvaluationState, action: PayloadAction<AxiosError>) => {
      state.evaluationDescriptions = [];
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getEvaluation2Start,
  getEvaluation2Success,
  getEvaluation2Failure,
  getEvaluationStart,
  getEvaluationSuccess,
  getEvaluationFailure,
  getEvaluationDescriptionListStart,
  getEvaluationDescriptionListSuccess,
  getEvaluationDescriptionListFailure,
} = evaluationSlicer.actions;
export default evaluationSlicer.reducer;

export const doGetEvaluation2 = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getEvaluation2Start());
    const response = await getEvaluation2(scheduleId);
    dispatch(getEvaluation2Success(response.data));
  } catch (e) {
    dispatch(getEvaluation2Failure(e));
  }
};

export const doGetEvaluation = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getEvaluationStart());
    const { data } = await getEvaluation(scheduleId);
    dispatch(getEvaluationSuccess(data));
  } catch (e) {
    dispatch(getEvaluationFailure(e));
  }
};

export const doGetEvaluationDescriptionList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getEvaluationDescriptionListStart());
    const { data } = await getEvaluationDescriptionList();
    dispatch(getEvaluationDescriptionListSuccess(data));
  } catch (e) {
    dispatch(getEvaluationDescriptionListFailure(e));
  }
};
