import { FC, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, PageHeader, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import StaffNewPasswordForm from '../components/Staff/StaffNewPasswordForm';
import { IStaffNewPassword } from '../models/Staff';
import { doPatchStaffPassword, initIsUpdated } from '../store/auth.slice';
import { RootState } from '../store/rootReducer';

const NewPassword: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, isUpdated } = useSelector((root: RootState) => root.authState);

  const onFinish = (values: IStaffNewPassword) => {
    dispatch(doPatchStaffPassword(values));
  };

  useEffect(() => {
    if (isUpdated) {
      history.push('/dashBoard');
      dispatch(initIsUpdated());
    }
  }, [isUpdated]);

  useEffect(() => {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          message.warn('비밀번호가 맞지 않습니다.');
          break;
      }
    }
  }, [error]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>패스워드 변경</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='패스워드 변경' />
      <StaffNewPasswordForm onFinish={onFinish} />
    </>
  );
};

export default NewPassword;
