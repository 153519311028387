import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IIssueTutor } from '../models/Issue';
import { ColumnsType } from 'antd/lib/table';
import { LinkStyle, tableStyle } from '../style/antdCssProperty';
import { format } from 'date-fns';
import { IssueStatus } from '../models/Enum';
import { Table } from 'antd';

interface IProps {
  issueTutors: IIssueTutor[];
}

const IssueTutorList: FC<IProps> = ({ issueTutors }) => {
  const columns: ColumnsType<IIssueTutor> = [
    {
      title: 'NO',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '구분',
      key: 'category',
      render: (value, record, index) => (
        <Link to={`/one/tutor/${record.id}`} style={LinkStyle}>{record.issueCategoryName}</Link>
      ),
    },
    {
      title: '강사명',
      key: 'tutorName',
      dataIndex: 'tutorName',
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: '등록일',
      key: 'createdAt',
      render: (value, record, index) => {
        return <>
          {format(new Date(record.createdAt), 'yyyy-MM-dd')}<br />
          {format(new Date(record.createdAt), 'hh:mm:ss')}<br />
        </>
      },
    },
    {
      title: '상태',
      key: 'status',
      render: (value, record, index) => (
        record.replyDate ?
          <>
            {format(new Date(record.replyDate), '(yyyy.MM.dd hh:mm:ss)')}
          </> : '미답변'
      ),
    },
    {
      title: '처리',
      key: 'status',
      render: (value, record, index) => (
        record.status === IssueStatus.IN_PROCESS ? '처리중' : '처리 완료'
      ),
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={issueTutors}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false} />
    </>
  );
};

export default IssueTutorList;
