import React, { FC, useState } from 'react';
import { Button, Form, Input, InputNumber } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { LinkStyle } from '../style/antdCssProperty';
import { DeleteOutlined } from '@ant-design/icons';

interface IProps {
  isCreate?: boolean;
  name?: string;
  episodeCount?: number;
  lessonName?: string;
  lessonPlan?: string;
  lessonPlanName?: string;
  classTool?: string;
  classToolName?: string;

  onCreate(episodeCount: number, name: string, lessonName: string, lessonPlan: File, classTool: File): void;

  onUpdate(name: string, lessonName: string, lessonPlan: File, classTool: File): void;

  onLessonDelete?(): void;

  onClassToolDelete?(): void;
}

interface IOnFinish {
  episodeCount: number;
  name: string;
  lessonPlan: File;
  classTool: File;
}

const EpisodeForm: FC<IProps> = ({
                                   isCreate = true,
                                   onCreate,
                                   onUpdate,
                                   name,
                                   episodeCount,
                                   lessonName,
                                   lessonPlan,
                                   lessonPlanName,
                                   classTool,
                                   classToolName,
                                   onLessonDelete,
                                   onClassToolDelete,
                                 }) => {
  const [localName, setLocalName] = useState(name);
  const [localLessonName, setLocalLessonName] = useState(lessonName);
  const [pageNo, setPageNo] = useState(episodeCount);
  const [localLessonPlan, setLocalLessonPlan] = useState<File>();
  const [localClassTool, setLocalClassTool] = useState<File>();

  const layout: FormItemProps = {
    labelCol: { span: 2 },
    wrapperCol: { span: 8 },
  };

  const tailLayout: FormItemProps = {
    wrapperCol: {
      offset: 5,
    },
  };

  const onLessonPlanClick = () => {
    if (onLessonDelete) {
      onLessonDelete();
    }
  };

  const onClassToolClick = () => {
    if (onClassToolDelete) {
      onClassToolDelete();
    }
  };

  const onFinish = (values: IOnFinish) => {
    if (isCreate) {
      onCreate(pageNo!, localName!, localLessonName!, localLessonPlan!, localClassTool!);
    } else {
      onUpdate(localName!, localLessonName!, localLessonPlan!, localClassTool!);
    }
  };

  const onLessonPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setLocalLessonPlan(event.target.files[0]);
    }
  };

  const onClassToolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setLocalClassTool(event.target.files[0]);
    }
  };

  const onLocalNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalName(event.target.value);
  };

  const onPageNoChange = (value: string | number | null | undefined) => {
    setPageNo(Number(value));
  };

  return (
    <>
      <Form
        {...layout}
        name='basic'
        onFinish={onFinish}>
        {
          isCreate ? (
            <Form.Item
              label='차시'
              name='pageNo'>
              <InputNumber defaultValue={pageNo} onChange={onPageNoChange}
                           style={{ width: '100%' }} />
            </Form.Item>
          ) : (<></>)
        }
        <Form.Item
          label='Episode Name'
          name='name'>
          <Input defaultValue={name ? name : ''} onChange={onLocalNameChange} />
        </Form.Item>
        <Form.Item
          label='Lesson Name'
          name='lessonName'>
          <Input defaultValue={localLessonName ? localLessonName : ''}
                 onChange={e => setLocalLessonName(e.target.value)} />
        </Form.Item>
        <Form.Item
          label='Lesson Plan'
          name='lessonPlan'
        >
          {
            lessonPlan ? (
              <>
                <a style={LinkStyle} href={lessonPlan}>{lessonPlanName ? lessonPlanName : lessonPlan}</a>
                <Button icon={<DeleteOutlined />} onClick={onLessonPlanClick} />
              </>
            ) : (
              <Input type='file' onChange={onLessonPlanChange} />
            )
          }
        </Form.Item>
        <Form.Item
          label='Class Tool'
          name='classTool'
        >
          {
            classTool ? (
              <>
                <a style={LinkStyle} href={classTool}>{classToolName ? classToolName : classTool}</a>
                <Button icon={<DeleteOutlined />} onClick={onClassToolClick} />
              </>
            ) : (
              <Input type='file' onChange={onClassToolChange} />
            )
          }
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type='primary' htmlType='submit'>
            저장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EpisodeForm;
