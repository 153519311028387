import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { doGetUserSubscriptionList, doPostUnsubscription } from '../store/userSubscription.slice';
import { doGetUserUnsubscriptionList } from '../store/userUnsubscription.slice';
import { Breadcrumb, PageHeader, Pagination, Modal, Input, message } from 'antd';
import { doGetCourseList } from '../store/course.slice';
import { productList } from '../store/product.slice';
import SubscriptionFilterBox from '../components/Subscription/SubscriptionFilterBox';
import SubscriptionTable, { StudentUserSubscription } from '../components/Subscription/SubscriptionTable';
import {
  IStudentUnSubscription,
  IStudentUserSubscriptionByUser,
  IUserSubscriptionListParam,
} from '../models/UserSubscription';
import { getSession, setSession } from '../libs/session';

interface IMatch {
  params: {
    status: string
  };
}

const { TextArea } = Input;

const SubscriptionPage = ({ match }: { match: IMatch }) => {
  const { status } = match.params;

  const dispatch = useDispatch();
  const { studentUserSubscriptions, total } = useSelector((root: RootState) => {
    if (status === 'active') return root.userSubscriptionState
    else return root.userUnsubscriptionState
  });
  const { products } = useSelector((root: RootState) => root.productState);
  const { courses } = useSelector((root: RootState) => root.courseState);
  const { user } = useSelector((root: RootState) => root.authState);
  const [params, setParams] = useState<IUserSubscriptionListParam>();
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [record, setRecord] = useState<IStudentUserSubscriptionByUser>();
  const [reasonDetail, setReasonDetail] = useState('');
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (status === 'deactive') setIsActive(false);
  }, []);

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname === '/subscription/active') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [window.location.pathname])

  useEffect(() => {
    dispatch(doGetCourseList());
    dispatch(productList());
  }, [dispatch]);

  useEffect(() => {
    const sessionParams = isActive
      ? getSession<IUserSubscriptionListParam>('subscription')
      : getSession<IUserSubscriptionListParam>('unsubscription');
    if (sessionParams) {
      if (sessionParams.page) {
        setPage(sessionParams.page);
      }
      if (isActive) dispatch(doGetUserSubscriptionList(sessionParams));
      else dispatch(doGetUserUnsubscriptionList(sessionParams));
    } else {
      const data: IUserSubscriptionListParam = {
        ...params,
        page
      };
      setParams(data);
      if (isActive) dispatch(doGetUserSubscriptionList());
      else dispatch(doGetUserUnsubscriptionList());
    }
  }, [dispatch, page, isActive]);

  useEffect(() => {
    if (params) {
      if (isActive) setSession('subscription', params);
      else setSession('unsubscription', params);
    }
  }, [params]);

  const onSubmit = (params?: IUserSubscriptionListParam) => {
    if (params) {
      params.page = page;
      setParams(params);
      if (isActive) dispatch(doGetUserSubscriptionList(params));
      else dispatch(doGetUserUnsubscriptionList(params));
    } else {
      if (isActive) dispatch(doGetUserSubscriptionList());
      else dispatch(doGetUserUnsubscriptionList());
    }
  };

  const onPageChange = (page: number) => {
    const newParams: IUserSubscriptionListParam = {
      ...params,
      page,
    };
    isActive
      ? setSession('subscription', newParams)
      : setSession('unsubscription', newParams)
    setPage(page);
  };

  const showModal = (record: IStudentUserSubscriptionByUser) => {
    setRecord(record);
    setVisible(true);
  };

  const handleOk = () => {
    if (!user) {
      message.warn('user: ');
      return;
    }
    if (!record) {
      message.warn('record: ');
      return;
    }
    if (!reasonDetail) {
      message.warn('이유를 작성해주세요.');
      return;
    }
    const data: IStudentUnSubscription = {
      subscriptionId: record.id,
      studentId: record.student.id,
      unsubscriptionReason: 'LMS 취소',
      reasonDetail,
      staffId: user.id,
    };
    dispatch(doPostUnsubscription(data));
    setVisible(false);
    setReasonDetail('');
  };

  const handleCancel = () => {
    // LMS 취소
    setVisible(false);
    setReasonDetail('');
  };

  const onReasonDetailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setReasonDetail(event.target.value);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{isActive ? '수강목록' : '구독해지'}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={isActive ? '수강목록' : '구독해지'} />
      <SubscriptionFilterBox
        products={products}
        courses={courses}
        total={total}
        deactive={!isActive}
        onSubmit={onSubmit}
      />
      <SubscriptionTable
        data={studentUserSubscriptions as StudentUserSubscription[]}
        deactive={!isActive}
        showModal={showModal}
      />
      <Pagination current={page} total={total} defaultPageSize={20} onChange={onPageChange}
                  style={{ marginTop: '1.5rem' }}
                  showSizeChanger={false}
      />
      <Modal title='구독 취소' visible={visible} onOk={handleOk} onCancel={handleCancel}>
        <TextArea value={reasonDetail} onChange={onReasonDetailChange} />
      </Modal>
    </>
  );
};

export default SubscriptionPage;
