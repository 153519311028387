import {
  IGetIssueListSuccess,
  IGetIssueTutorListSuccess,
  IIssueCategory,
  IIssueParam,
  IIssueRead, IIssueReply,
  IIssueStudent,
  IIssueTutor,
  IIssueUpdate,
} from '../models/Issue';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { categoryList, patchIssueReply, read, studentList, tutorList, update } from '../api/issue';
import { message } from 'antd';
import { IssueStatus, IssueType } from '../models/Enum';

interface IIssueState {
  issueStudents: IIssueStudent[];
  issueStudent?: IIssueRead;
  issueTutors: IIssueTutor[];
  issueCategories: IIssueCategory[];
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IIssueState = {
  issueStudents: [],
  issueStudent: undefined,
  issueTutors: [],
  issueCategories: [],
  total: 0,
  error: undefined,
  isLoading: false,
};

const issueSlicer = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    getIssueListStart: (state: IIssueState) => {
      state.issueStudents = [];
      state.issueTutors = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getIssueListSuccess: (state: IIssueState, action: PayloadAction<IGetIssueListSuccess>) => {
      state.issueStudents = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getIssueListFailure: (state: IIssueState, action: PayloadAction<AxiosError>) => {
      state.issueStudents = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
    getIssueStudentStart: (state: IIssueState) => {
      state.issueStudent = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getIssueStudentSuccess: (state: IIssueState, action: PayloadAction<IIssueRead>) => {
      state.issueStudent = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getIssueStudentFailure: (state: IIssueState, action: PayloadAction<AxiosError>) => {
      state.issueStudent = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    updateIssueStudentStart: (state: IIssueState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    updateIssueStudentSuccess: (state: IIssueState, action: PayloadAction<{ id: number, data: IIssueUpdate }>) => {
      state.issueStudents = state.issueStudents.map((item) => {
        if (item.id === action.payload.id) {
          item.reply = action.payload.data.reply;
          item.replyDate = new Date().toString();
          item.status = IssueStatus.COMPLETE;
        }
        return item;
      });
      state.isLoading = false;
      state.error = undefined;
    },
    updateIssueStudentFailure: (state: IIssueState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getIssueTutorListStart: (state: IIssueState) => {
      state.issueStudents = [];
      state.issueTutors = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getIssueTutorListSuccess: (state: IIssueState, action: PayloadAction<IGetIssueTutorListSuccess>) => {
      state.issueTutors = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getIssueTutorListFailure: (state: IIssueState, action: PayloadAction<AxiosError>) => {
      state.issueTutors = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
    getIssueCategoryListStart: (state: IIssueState) => {
      state.issueCategories = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getIssueCategoryListSuccess: (state: IIssueState, action: PayloadAction<IIssueCategory[]>) => {
      state.issueCategories = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getIssueCategoryListFailure: (state: IIssueState, action: PayloadAction<AxiosError>) => {
      state.issueCategories = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    patchIssueReplyStart: (state: IIssueState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchIssueReplySuccess: (state: IIssueState, action: PayloadAction<{ id: number, data: IIssueReply }>) => {
      state.issueStudents = state.issueStudents.map((item) => {
        if (item.id === action.payload.id) {
          item.reply2 = action.payload.data.reply2;
          item.replyDate2 = new Date().toString();
        }
        return item;
      });
      state.isLoading = false;
      state.error = undefined;
    },
    patchIssueReplyFailure: (state: IIssueState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getIssueListStart,
  getIssueListSuccess,
  getIssueListFailure,
  getIssueStudentStart,
  getIssueStudentSuccess,
  getIssueStudentFailure,
  updateIssueStudentStart,
  updateIssueStudentSuccess,
  updateIssueStudentFailure,
  getIssueTutorListStart,
  getIssueTutorListSuccess,
  getIssueTutorListFailure,
  getIssueCategoryListStart,
  getIssueCategoryListSuccess,
  getIssueCategoryListFailure,
  patchIssueReplyStart,
  patchIssueReplySuccess,
  patchIssueReplyFailure,
} = issueSlicer.actions;

export default issueSlicer.reducer;

export const issueStudentList = (param: IIssueParam = {}): AppThunk => async (dispatch) => {
  try {
    dispatch(getIssueListStart());
    const response = await studentList(param);
    const data: IGetIssueListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getIssueListSuccess(data));
  } catch (e) {
    dispatch(getIssueListFailure(e));
  }
};

export const issueStudent = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getIssueStudentStart());
    const response = await read(id);
    dispatch(getIssueStudentSuccess(response.data));
  } catch (e) {
    dispatch(getIssueStudentFailure(e));
  }
};

export const issueStudentUpdate = (id: number, data: IIssueUpdate): AppThunk => async (dispatch) => {
  try {
    dispatch(updateIssueStudentStart());
    await update(id, data);
    const success = {
      id,
      data,
    };
    dispatch(updateIssueStudentSuccess(success));
    message.success('댓글이 작성되었습니다.');
  } catch (e) {
    dispatch(updateIssueStudentFailure(e));
  }
};

export const issueTutorList = (param: IIssueParam = {}): AppThunk => async (dispatch) => {
  try {
    dispatch(getIssueTutorListStart());
    const response = await tutorList(param);
    const data: IGetIssueTutorListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getIssueTutorListSuccess(data));
  } catch (e) {
    dispatch(getIssueTutorListFailure(e));
  }
};

export const issueCategoryList = (type: IssueType): AppThunk => async (dispatch) => {
  try {
    dispatch(getIssueCategoryListStart());
    const response = await categoryList(type);
    dispatch(getIssueCategoryListSuccess(response.data));
  } catch (e) {
    dispatch(getIssueCategoryListFailure(e));
  }
};

export const doPatchIssueReply = (id: number, body: IIssueReply): AppThunk => async (dispatch) => {
  try {
    dispatch(patchIssueReplyStart());
    await patchIssueReply(id, body);
    dispatch(patchIssueReplySuccess({ id, data: body }));
    message.success('추가 댓글이 작성되었습니다.');
  } catch (e) {
    dispatch(patchIssueReplyFailure(e));
  }
};
