import React, { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CouponListItem } from '../models/Coupon';
import { CouponType } from '../models/Enum';
import { format } from 'date-fns';
import { tableStyle } from '../style/antdCssProperty';
import { Link } from 'react-router-dom';

interface IProps {
  coupons: CouponListItem[];
}

const CouponList: FC<IProps> = ({ coupons }) => {
  const columns: ColumnsType<CouponListItem> = [
    {
      title: '쿠폰명',
      key: 'name',
      render: (value, record) => (
        <Link to={`/coupons/${record.id}`}>{record.name}</Link>
      ),
    },
    {
      title: '할인율',
      key: 'discountRatio',
      render: (value, record) => (
        `${record.discountRatio}%`
      ),
    },
    {
      title: '유형',
      key: 'couponType',
      render: (value, record) => (
        record.couponType === CouponType.AUTO ? '자동생성' : '수동생성'
      ),
    },
    {
      title: '생성수(발행수)',
      key: 'publishCount',
      dataIndex: 'publishCount',
    },
    {
      title: '등록수',
      key: 'registrationCount',
      dataIndex: 'registrationCount',
    },
    {
      title: '사용수',
      key: 'usedCount',
      dataIndex: 'usedCount',
    },
    {
      title: '등록일',
      key: 'createdAt',
      render: (value, record) => (
        format(new Date(record.createdAt), 'yyyy.MM.dd')
      ),
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={coupons}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false} />
    </>
  );
};

export default CouponList;