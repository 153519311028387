import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import {
  ILevelTestConsulting,
  ILevelTestConsultingPatch, ILevelTestFail, ILevelTestFailPatch,
  ILevelTestItem, ILevelTestItemElement,
  ILevelTestSurvey,
} from '../models/levelTest.model';

export const getLevelTestItem = (scheduleId: number): AxiosPromise<ILevelTestItem> => {
  const url = `/api/level-test/item?scheduleId=${scheduleId}`;
  return axios.get(url);
};

export const getLevelTestSurveyList = (scheduleId: number): AxiosPromise<ILevelTestSurvey[]> => {
  const url = `/api/level-test/survey?scheduleId=${scheduleId}`;
  return axios.get(url);
};

export const patchLevelTestConsulting = (body: ILevelTestConsultingPatch): AxiosPromise<ILevelTestConsulting> => {
  const url = `/api/level-test/consulting`;
  return axios.patch(url, body);
};

export const getLevelTestConsulting = (scheduleId: number): AxiosPromise<ILevelTestConsulting> => {
  const url = `/api/level-test/consulting?scheduleId=${scheduleId}`;
  return axios.get(url);
};

export const patchLevelTestFail = (body: ILevelTestFailPatch): AxiosPromise<ILevelTestFail> => {
  const url = `/api/level-test/fail`;
  return axios.patch(url, body);
};

export const getLevelTestFail = (scheduleId: number): AxiosPromise<ILevelTestFail> => {
  const url = `/api/level-test/fail?scheduleId=${scheduleId}`;
  return axios.get(url);
};

export const deleteLevelTestRecovery = (scheduleId: number): AxiosPromise => {
  const url = `/api/level-test/recovery/${scheduleId}`;
  return axios.delete(url);
};

export const getLevelTestItemElement = (): AxiosPromise<ILevelTestItemElement[]> => {
  const url = `/api/level-test/item/element`;
  return axios.get(url);
};
