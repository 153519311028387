import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import {
  IDashboardRead, IDashboardReadList, IDashboardReadListParam,
  IDashboardSchedule, IDashboardScheduleParam, IDashboardViewChangeUpdate, IScheduleFail, IStatic,
  StudentScheduleLmsListItem, StudentScheduleLmsQuery,
  TutorScheduleEvaluationItem,
  TutorScheduleFeedbackItem,
  TutorScheduleLmsHistoryListItem,
  TutorScheduleLmsHistoryQuery,
  TutorScheduleLmsListItem,
  TutorScheduleLmsQuery
} from '../models/Schedule';
import qs from 'qs';

export const lmsList = (staffId: number, query: TutorScheduleLmsQuery): AxiosPromise<TutorScheduleLmsListItem[]> => {
  let url = `/api/tutor/${staffId}/schedule/lms/tutor`;
  const qsParams = qs.stringify(query);
  url += `?${qsParams}`;
  return axios.get(url);
};

export const lmsHistoryList = (staffId: number, query: TutorScheduleLmsHistoryQuery): AxiosPromise<TutorScheduleLmsHistoryListItem[]> => {
  let url = `/api/tutor/${staffId}/schedule/lms/student/history?page=${query.page ? query.page : 1}`;
  if (query.isTrialClass) {
    url += `&isTrialClass=${query.isTrialClass}`;
  }
  if (query.name) {
    url += `&name=${query.name}`;
  }
  if (query.startDate) {
    url += `&startDate=${query.startDate}`;
  }
  if (query.endDate) {
    url += `&endDate=${query.endDate}`;
  }
  return axios.get(url);
};

export const feedbackRead = (id: number): AxiosPromise<TutorScheduleFeedbackItem> => {
  return axios.get(`/api/schedule/${id}/feedback`);
};

export const evaluationRead = (id: number): AxiosPromise<TutorScheduleEvaluationItem> => {
  return axios.get(`/api/schedule/${id}/evaluation`);
};

export const lmsStudentList = (staffId: number, query: StudentScheduleLmsQuery): AxiosPromise<StudentScheduleLmsListItem[]> => {
  let url = `/api/tutor/${staffId}/schedule/lms/student?studentId=${query.studentId}`;
  if (query.startDate) {
    url += `&startDate=${query.startDate}`;
  }
  if (query.endDate) {
    url += `&endDate=${query.endDate}`;
  }
  if (query.reserved) {
    url += `&reserved=${query.reserved}`;
  }
  return axios.get(url);
};

export const dashboardScheduleList = (params: IDashboardScheduleParam): AxiosPromise<IDashboardSchedule[]> => {
  const qsParams = qs.stringify(params);
  return axios.get(`/api/schedule/dashboard?${qsParams}`);
};

export const getDashboardSchedule = (id: number): AxiosPromise<IDashboardRead> => {
  return axios.get(`/api/schedule/dashboard-view?id=${id}`);
};

export const getDashboardScheduleList = (data: IDashboardReadListParam): AxiosPromise<IDashboardReadList[]> => {
  return axios.get(`/api/schedule/dashboard-view-list?${qs.stringify(data)}`);
};

export const patchDashboardSchedule = (data: IDashboardViewChangeUpdate): AxiosPromise<IDashboardRead> => {
  return axios.patch(`/api/schedule/dashboard-view-change`, data);
};

export const patchScheduleFail = (id: number, data: IScheduleFail): AxiosPromise<IDashboardRead> => {
  return axios.patch(`/api/schedule/${id}/fail`, data);
};

export const getStaticList = (): AxiosPromise<IStatic> => {
  return axios.get(`/api/schedule/statics`);
};
