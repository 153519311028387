import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { IContract } from '../models/Contract';

export const getContractList = (staffId: number): AxiosPromise<IContract[]> => {
  return axios.get(`/api/contract?staffId=${staffId}`);
};

export const getContract = (id: number): AxiosPromise<IContract> => {
  return axios.get(`/api/contract/${id}`);
};

export const postContract = (data: FormData): AxiosPromise<IContract> => {
  return axios.post(`/api/contract`, data);
};

export const patchContract = (id: number, data: FormData): AxiosPromise<IContract> => {
  return axios.patch(`/api/contract/${id}`, data);
};
