import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, PageHeader, Pagination } from 'antd';
import ScheduleCancelLogFilterBox from '../components/ScheduleCancelLogList/ScheduleCancelLogFilterBox';
import { IScheduleCancelLogParam } from '../models/scheduleCancelLog.model';
import { useDispatch, useSelector } from 'react-redux';
import { doGetScheduleCancelLogList } from '../store/scheduleCancelLog.slice';
import { RootState } from '../store/rootReducer';
import ScheduleCancelLogTable from '../components/ScheduleCancelLogList/ScheduleCancelLogTable';
import { getSession, setSession } from '../libs/session';

const ScheduleCancelLogListPage: FC = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [params, setParams] = useState<IScheduleCancelLogParam>();

  const { scheduleCancelLogList, total } = useSelector((root: RootState) => root.scheduleCancelLogState);

  useEffect(() => {
    const sessionParams = getSession<IScheduleCancelLogParam>('scheduleCancelLog');
    if (sessionParams) {
      if (sessionParams.page) {
        setPage(sessionParams.page);
      }
      dispatch(doGetScheduleCancelLogList(sessionParams));
    } else {
      const data: IScheduleCancelLogParam = {
        ...params,
        page,
      };
      dispatch(doGetScheduleCancelLogList(data));
    }
  }, [dispatch, page]);

  useEffect(() => {
    if (params) {
      setSession('scheduleCancelLog', params);
    }
  }, [params]);

  const onSubmit = (params: IScheduleCancelLogParam, isUpdate: boolean) => {
    if (isUpdate) {
      params.page = 1;
      setPage(1);
    } else {
      params.page = page;
    }
    setParams(params);
    dispatch(doGetScheduleCancelLogList(params));
  };

  const onPageChange = (page: number) => {
    const newParams: IScheduleCancelLogParam = {
      ...params,
      page,
    };
    setSession('scheduleCancelLog', newParams);
    setPage(page);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>수업 취소 List</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='수업 취소 List' />
      <ScheduleCancelLogFilterBox onSubmit={onSubmit} total={total} />
      <ScheduleCancelLogTable scheduleCancelLogList={scheduleCancelLogList} />
      <Pagination current={page} total={total} defaultPageSize={20} onChange={onPageChange}
                  style={{ marginTop: '1.5rem' }} showSizeChanger={false} />
    </>
  );
};

export default ScheduleCancelLogListPage;
