import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { bookList } from '../store/book.slice';
import Loader from '../components/Loader';
import { Breadcrumb, Col, PageHeader, Row } from 'antd';
import { Link } from 'react-router-dom';
import BookList from '../components/BookList';

const BookListPage: FC = () => {
  const dispatch = useDispatch();
  const { isLoading, books } = useSelector((state: RootState) => state.bookState);

  useEffect(() => {
    dispatch(bookList());
  }, [dispatch]);

  if (isLoading || !books) {
    return <Loader />;
  } else {
    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
          <Breadcrumb.Item>교재관리</Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader title='교재관리'>
        </PageHeader>
        <Row gutter={10}>
          <Col span={24}>
            <BookList books={books} />
          </Col>
        </Row>
      </>
    );
  }
};

export default BookListPage;