import React, { FC } from 'react';
import { Descriptions, List } from 'antd';
import { IIssueRead } from '../../models/Issue';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';
import { format } from 'date-fns';

interface IProps {
  issue: IIssueRead;
}

const IssueInfo: FC<IProps> = ({ issue }) => {
  return (
    <>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='제목' span={1}>
          {issue.title}
        </Descriptions.Item>
        <Descriptions.Item label='작성일' span={1}>
          {format(new Date(issue.createdAt), 'yyyy-MM-dd HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label='학생 이름' span={1}>
          {issue.student ? issue.student.name : ''}
        </Descriptions.Item>
        <Descriptions.Item label='수업 정보' span={1}>
          {issue.schedule ? `[${issue.schedule.course.name}] ${issue.schedule.episode.name} / ${format(new Date(issue.schedule.startDateTime), 'yyyy-MM-dd HH:mm')} / Tutor ${issue.schedule.tutor.nickName}` : ''}
        </Descriptions.Item>
        <Descriptions.Item label='본문' span={2}>
          <div dangerouslySetInnerHTML={{ __html: issue.contents.replaceAll('\n', '<br/>') }} />
        </Descriptions.Item>
        <Descriptions.Item label='답변' span={2}>
          <div dangerouslySetInnerHTML={{ __html: issue.reply ? issue.reply.replaceAll('\n', '<br/>') : '' }} />
        </Descriptions.Item>
        <Descriptions.Item label='추가 답변' span={2}>
          <div dangerouslySetInnerHTML={{ __html: issue.reply2 ? issue.reply2.replaceAll('\n', '<br/>') : '' }} />
        </Descriptions.Item>
        <Descriptions.Item label='첨부 파일' span={2}>
          {
            issue.issueAttachments && <List dataSource={issue.issueAttachments} renderItem={(item) => (
              <List.Item>
                <a href={item.uri}>{item.filename}</a>
              </List.Item>
            )} />
          }
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default IssueInfo;
