import { IStudentCourse, IStudentCourseUpdate } from '../models/studyCourse.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getStudentCourseList, patchStudentCourse } from '../api/studentCourse.api';

interface IStudentCourseState {
  studentCourseList: IStudentCourse[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IStudentCourseState = {
  studentCourseList: [],
  isLoading: false,
};

const studentCourseSlicer = createSlice({
  name: 'studentCourse',
  initialState,
  reducers: {
    getStudentCourseListStart: (state: IStudentCourseState) => {
      state.studentCourseList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getStudentCourseListSuccess: (state: IStudentCourseState, action: PayloadAction<IStudentCourse[]>) => {
      state.studentCourseList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getStudentCourseListFailure: (state: IStudentCourseState, action: PayloadAction<AxiosError>) => {
      state.studentCourseList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    patchStudentCourseStart: (state: IStudentCourseState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchStudentCourseSuccess: (state: IStudentCourseState, action: PayloadAction<IStudentCourse[]>) => {
      state.studentCourseList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    patchStudentCourseFailure: (state: IStudentCourseState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getStudentCourseListStart,
  getStudentCourseListSuccess,
  getStudentCourseListFailure,
  patchStudentCourseStart,
  patchStudentCourseSuccess,
  patchStudentCourseFailure,
} = studentCourseSlicer.actions;
export default studentCourseSlicer.reducer;

export const doGetStudentCourseList = (studentId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getStudentCourseListStart());
    const response = await getStudentCourseList(studentId);
    dispatch(getStudentCourseListSuccess(response.data));
  } catch (e) {
    dispatch(getStudentCourseListFailure(e));
  }
};

export const doPatchStudentCourse = (id: number, data: IStudentCourseUpdate): AppThunk => async (dispatch) => {
  try {
    dispatch(patchStudentCourseStart());
    const response = await patchStudentCourse(id, data);
    dispatch(patchStudentCourseSuccess(response.data));
  } catch (e) {
    dispatch(patchStudentCourseFailure(e));
  }
};
