import { FC, useEffect, useState } from 'react';
import { Input, Button, message } from 'antd';
import CustomDatePicker from '../common/CustomDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { doPatchLevelTestConsulting } from '../../store/levelTest.slice';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { useRouteMatch } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import { doPostLevelTestCoupon } from '../../store/coupon.slice';

interface IMatch {
  id: string;
}

const { TextArea } = Input;

const LevelTestForm: FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const scheduleId = Number(match.params.id);

  const [date, setDate] = useState(new Date());
  const [notes, setNotes] = useState('');

  const { levelTestConsulting } = useSelector((root: RootState) => root.levelTestState);
  const { dashboardRead } = useSelector((root: RootState) => root.scheduleState);

  useEffect(() => {
    if (levelTestConsulting) {
      setDate(new Date(levelTestConsulting.consultingDate));
      setNotes(levelTestConsulting.consultingNotes);
    } else {
      setDate(new Date());
      setNotes('');
    }
  }, [levelTestConsulting]);


  const onFinish = () => {
    if (!notes) {
      message.warn('교육상담 내용을 입력해주세요.');
      return;
    }
    dispatch(doPatchLevelTestConsulting({
      consultingNotes: notes,
      consultingDate: format(utcToZonedTime(date, 'UTC'), 'yyyy-MM-dd'),
      scheduleId,
    }));
  };

  const onDateChange = (value: any, dateString: string) => {
    setDate(value);
  };

  const onCreateCoupon = () => {
    if (!dashboardRead) {
      message.warn('학생 정보가 존재하지 않습니다.');
      return;
    }
    dispatch(doPostLevelTestCoupon(dashboardRead.student.id));
  };

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <div>
        교육상담 일자: <CustomDatePicker value={new Date(date)} onChange={onDateChange} />
      </div>
      <div style={{ display: 'flex', marginTop: '1.5rem' }}>
        <label style={{ width: 90 }}>교육상담 내용: </label>
        <TextArea value={notes} rows={4} onChange={e => setNotes(e.target.value)} />
      </div>
      <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
        <Button type='primary' onClick={onFinish}>작성</Button>
        <Button type='primary' onClick={onCreateCoupon} style={{ marginLeft: '1.5rem' }}>쿠폰 발급하기</Button>
      </div>
    </div>
  );
};

export default LevelTestForm;
