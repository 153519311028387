import { IGetTutor2ListSuccess, ITutor2, ITutor2Param, ITutor2Read, ITutor2Request } from '../models/tutor2.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getTutor2, getTutor2List, updateTutor2 } from '../api/tutor2.api';
import { messageAdd, showMessage } from './message.slice';
import { Error } from '../models/Error';

interface ITutor2State {
  tutors: ITutor2[];
  tutor?: ITutor2Read;
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: ITutor2State = {
  isLoading: false, total: 0, tutors: [],
};

const tutor2Slice = createSlice({
  name: 'tutor2',
  initialState,
  reducers: {
    getTutor2ListStart: (state: ITutor2State) => {
      state.tutors = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getTutor2ListSuccess: (state: ITutor2State, action: PayloadAction<IGetTutor2ListSuccess>) => {
      state.tutors = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getTutor2ListFailure: (state: ITutor2State, action: PayloadAction<AxiosError>) => {
      state.tutors = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
    getTutor2Start: (state: ITutor2State) => {
      state.tutor = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getTutor2Success: (state: ITutor2State, action: PayloadAction<ITutor2Read>) => {
      state.tutor = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getTutor2Failure: (state: ITutor2State, action: PayloadAction<AxiosError>) => {
      state.tutor = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    requestStart: (state: ITutor2State) => {
      state.isLoading = true;
      state.error = undefined;
    },
    requestSuccess: (state: ITutor2State) => {
      state.isLoading = false;
      state.error = undefined;
    },
    requestFailure: (state: ITutor2State, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getTutor2ListStart,
  getTutor2ListSuccess,
  getTutor2ListFailure,
  getTutor2Start,
  getTutor2Success,
  getTutor2Failure,
  requestStart,
  requestSuccess,
  requestFailure,
} = tutor2Slice.actions;
export default tutor2Slice.reducer;

export const doGetTutor2List = (params?: ITutor2Param): AppThunk => async (dispatch) => {
  try {
    dispatch(getTutor2ListStart());
    const response = await getTutor2List(params);
    const data: IGetTutor2ListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getTutor2ListSuccess(data));
  } catch (e) {
    dispatch(getTutor2ListFailure(e));
  }
};

export const doGetTutor2 = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getTutor2Start());
    const response = await getTutor2(id);
    dispatch(getTutor2Success(response.data));
  } catch (e) {
    dispatch(getTutor2Failure(e));
  }
};

export const doUpdateTutor2 = (id: number, data: ITutor2Request): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await updateTutor2(id, data);
    dispatch(messageAdd(showMessage('success', 'tutor info update')));
    dispatch(requestSuccess());
  } catch (e) {
    const error = e as AxiosError<Error>;
    dispatch(messageAdd(showMessage('error', error.response ? error.response.data.reason : 'error')));
    dispatch(requestFailure(e));
  }
};
