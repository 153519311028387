import { FC } from 'react';
import { FormItemProps, FormProps, Form, Button, Input, message } from 'antd';
import { IStaffNewPassword } from '../../models/Staff';

interface IProps {
  onFinish(values: IStaffNewPassword): void;
}

const StaffNewPasswordForm: FC<IProps> = ({ onFinish }) => {
  const formProps: FormProps = {
    wrapperCol: { span: 16 },
    onFinish: (values) => {
      const { currentPass, newPass, confirmPass } = values;
      if (newPass !== confirmPass) {
        message.warn('새로 입력하신 비밀번호가 맞지 않습니다.');
        return;
      }
      onFinish({ currentPass, newPass });
    },
  };

  const tailLayout: FormItemProps = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const currentPassFormItemProps: FormItemProps = {
    label: '현재 비밀번호',
    name: 'currentPass',
    rules: [{ required: true, message: '현재 비밀번호를 입력해주세요.' }],
  };

  const newPassFormItemProps: FormItemProps = {
    label: '새로운 비밀번호',
    name: 'newPass',
    rules: [{ required: true, message: '새로운 비밀번호를 입력해주세요.' }],
  };

  const confirmPassFormItemProps: FormItemProps = {
    label: '확인용 비밀번호',
    name: 'confirmPass',
    rules: [{ required: true, message: '확인용 비밀번호를 입력해주세요.' }],
  };

  return (
    <Form {...formProps}>
      <Form.Item {...currentPassFormItemProps}>
        <Input type='password' />
      </Form.Item>
      <Form.Item {...newPassFormItemProps}>
        <Input type='password' />
      </Form.Item>
      <Form.Item {...confirmPassFormItemProps}>
        <Input type='password' />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>제출</Button>
      </Form.Item>
    </Form>
  );
};

export default StaffNewPasswordForm;
