import React, { FC } from 'react';
import { ITutorWorkingLog } from '../../models/TutorWorkingLog';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { tableStyle } from '../../style/antdCssProperty';

interface IProps {
  tutorWorkingLogList: ITutorWorkingLog[];
}

const TutorWorkingLogTable: FC<IProps> = ({ tutorWorkingLogList }) => {
  const columns: ColumnsType<ITutorWorkingLog> = [
    {
      title: 'Type',
      key: 'logType',
      dataIndex: 'logType',
    },
    {
      title: 'scheduleId',
      key: 'scheduleId',
      render: (value, record) => (record.scheduleId ? record.scheduleId : '-'),
    },
    {
      title: '수업 시작 시간',
      key: 'scheduleStartDateTime',
      render: (value, record) => (record.scheduleStartDateTime ? format(new Date(record.scheduleStartDateTime), 'yyyy-MM-dd HH:mm:ss') : '-'),
    },
    {
      title: '시간',
      key: 'createdAt',
      render: (value, record) => (format(new Date(record.createdAt), 'yyyy-MM-dd HH:mm:ss')),
    },
  ];

  return (
    <Table
      style={tableStyle}
      dataSource={tutorWorkingLogList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
    />
  );
};

export default TutorWorkingLogTable;
