import { FC } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import MonitoringPage from '../containers/MonitoringPage';

const MonitoringRoute: FC = () => {
  return (
    <BrowserRouter>
      <Route path='/monitoring' component={MonitoringPage} />
    </BrowserRouter>
  );
};

export default MonitoringRoute;
