import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { ICalender, ICalenderParam } from '../models/calender.model';
import qs from 'qs'

export const getCalenderList = (param?: ICalenderParam): AxiosPromise<ICalender[]> => {
  let url = `/api/schedule/calender`;
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};
