import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { IGetUserListSuccess, User, UserParam, UserRead } from '../models/User';
import { AxiosError } from 'axios';
import { list, read } from '../api/user';
import { IUserMemo, IUserMemoBody } from '../models/userMemo.model';
import { getUserMemoList, postUserMemo } from '../api/userMemo.api';
import { message } from 'antd';

interface IUserState {
  users: User[];
  user: UserRead | null;
  total: number;
  isLoading: boolean;
  error: AxiosError | null;
  userMemoList: IUserMemo[];
}

const initialState: IUserState = {
  users: [],
  user: null,
  total: 0,
  isLoading: false,
  error: null,
  userMemoList: [],
};

const userSlicer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    requestStart: (state: IUserState) => {
      state.user = null;
      state.error = null;
      state.isLoading = true;
    },
    listSuccess: (state: IUserState, action: PayloadAction<IGetUserListSuccess>) => {
      state.total = action.payload.total;
      state.users = action.payload.data;
      state.error = null;
      state.isLoading = false;
    },
    readSuccess: (state: IUserState, action: PayloadAction<UserRead>) => {
      state.user = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IUserState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    getUserMemoListStart: (state: IUserState) => {
      state.isLoading = true;
      state.error = null;
    },
    getUserMemoListSuccess: (state: IUserState, { payload }: PayloadAction<IUserMemo[]>) => {
      state.userMemoList = payload;
      state.isLoading = false;
    },
    getUserMemoListFailure: (state: IUserState, { payload }: PayloadAction<AxiosError>) => {
      state.error = payload;
      state.isLoading = false;
    },
    postUserMemoSuccess: (state: IUserState, { payload }: PayloadAction<IUserMemo>) => {
      state.userMemoList = [{ ...payload }, ...state.userMemoList];
    },
    postUserMemoFailure: (state: IUserState, { payload }: PayloadAction<AxiosError>) => {
      state.error = payload;
    },
  },
});

export const {
  requestStart,
  listSuccess,
  readSuccess,
  requestFailure,
  getUserMemoListStart,
  getUserMemoListSuccess,
  getUserMemoListFailure,
  postUserMemoSuccess,
  postUserMemoFailure,
} = userSlicer.actions;

export default userSlicer.reducer;

export const userList = (param?: UserParam): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(param);
    const data: IGetUserListSuccess = {
      data: response.data, total: response.headers['total'],
    };
    dispatch(listSuccess(data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const userRead = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await read(id);
    dispatch(readSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const doGetUserMemoList = (userId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserMemoListStart());
    const { data } = await getUserMemoList(userId);
    dispatch(getUserMemoListSuccess(data));
  } catch (e) {
    dispatch(getUserMemoListFailure(e));
  }
};

export const doPostUserMemo = (body: IUserMemoBody): AppThunk => async (dispatch) => {
  try {
    const { data } = await postUserMemo(body);
    dispatch(postUserMemoSuccess(data));
    message.success('저장되었습니다.');
  } catch (e) {
    dispatch(postUserMemoFailure(e));
    message.error('저장에 실패했습니다.');
  }
};
