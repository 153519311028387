import React, { FC, useEffect } from 'react';
import { Breadcrumb, PageHeader } from 'antd';
import { Link } from 'react-router-dom';
import CouponForm from '../components/CouponForm';
import { useDispatch, useSelector } from 'react-redux';
import { productList } from '../store/product.slice';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import { CouponCreate } from '../models/Coupon';
import { couponCreate } from '../store/coupon.slice';

const CouponFormPage: FC = () => {
  const dispatch = useDispatch();
  const { isLoading: isProductLoading, products } = useSelector((root: RootState) => root.productState);

  useEffect(() => {
    dispatch(productList());
  }, [dispatch]);

  const onCouponFormSubmit = async (data: CouponCreate) => {
    await dispatch(couponCreate(data));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='dashboard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to='coupons'>쿠폰관리</Link></Breadcrumb.Item>
        <Breadcrumb.Item>쿠폰등록</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='쿠폰등록' />
      {
        isProductLoading ? (
          <Loader />
        ) : products ? (
          <CouponForm productItem={products} onCouponFormSubmit={onCouponFormSubmit} />
        ) : (<></>)
      }
    </>
  );
};

export default CouponFormPage;