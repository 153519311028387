import React, { FC } from 'react';
import { List } from 'antd';
import { Comment } from '../../models/Comment';
import CommentListItem from './CommentListItem';

interface IProps {
  comments: Comment[];
}

const CommentList: FC<IProps> = ({ comments }) => {
  return (
    <>
      <List
        dataSource={comments}
        renderItem={(item: Comment) => (
          <li>
            <CommentListItem item={item} />
          </li>
        )}
      />
    </>
  );
};

export default CommentList;
