import { FC, CSSProperties } from 'react';
import { Descriptions, Image } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';
import { IStudent } from '../../models/Student';
import { format } from 'date-fns';
import { dateFormat } from '../../libs/helper';
import { StudentSubstituteAgree } from '../../models/Enum';

interface Props {
  student?: IStudent;
  style?: CSSProperties
}

const CustomerInfo: FC<Props> = ({ student, style }) => {
  const getSubstituteString = (studentSubstituteAgree: StudentSubstituteAgree) => {
    switch (studentSubstituteAgree) {
      case StudentSubstituteAgree.AGREE:
        return '동의';
      case StudentSubstituteAgree.WAIT:
        return '대기중';
      case StudentSubstituteAgree.DENY:
        return '미동의';
    }
  };

  return (
    <Descriptions style={style} column={2} bordered labelStyle={descriptionLabelStyle}
                  contentStyle={descriptionContentStyle}>
      <Descriptions.Item label='닉네임' span={1}>{student?.name}</Descriptions.Item>
      <Descriptions.Item label='생년월일'
                         span={1}>{student && student.birthDay ? dateFormat(new Date(student.birthDay)) : ''}</Descriptions.Item>
      <Descriptions.Item label='가입일'
                         span={1}>{student ? format(new Date(student.createdAt), 'yyyy-MM-dd') : ''}</Descriptions.Item>
      <Descriptions.Item label='캐릭터' span={1}>{
        student && <Image src={student.avatar.url} alt='아바타' />
      }</Descriptions.Item>
      <Descriptions.Item label='대강 동의' span={2}>
        {student ? getSubstituteString(student.substituteAgree) : ''}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default CustomerInfo;
