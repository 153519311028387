import React, { FC, useEffect, useRef, useState } from 'react';
import MonitoringCountBox from '../components/monitoring/MonitoringCountBox';
import MonitoringTable from '../components/monitoring/MonitoringTable';
import { useDispatch, useSelector } from 'react-redux';
import { doDashboardScheduleList } from '../store/schedule.slice';
import { IDashboardScheduleParam } from '../models/Schedule';
import { addDays, addMinutes, format, isAfter, isBefore } from 'date-fns';
import {
  doGetMonitoringCount,
  doPatchMonitoringCallViewed,
  doPostMonitoringCallCheck,
  doPostMonitoringCheck,
} from '../store/monitoring.slice';
import { RootState } from '../store/rootReducer';
import { isStudying } from '../libs/helper';
import { IMonitoringCallCheck } from '../models/monitoring.model';
import { Modal, message } from 'antd';
import { StudyUserType } from '../models/Enum';

const MonitoringPage: FC = () => {
    const dispatch = useDispatch();

    const { dashboardScheduleList } = useSelector((root: RootState) => root.scheduleState);
    const { error, monitoringCallCheckList } = useSelector((root: RootState) => root.monitoringState);
    const { user } = useSelector((root: RootState) => root.authState);

    const [visible, setVisible] = useState(false);
    const [monitorCallCheck, setMonitorCallCheck] = useState<IMonitoringCallCheck>();

    const audioRef = useRef<HTMLAudioElement>(null);

    const initDashboardScheduleList = () => {
      // const today = new Date('2021-05-31');
      const today = new Date();
      const startDateTime = format(today, 'yyyy-MM-dd');
      const endDateTime = format(addDays(today, 1), 'yyyy-MM-dd');
      const params: IDashboardScheduleParam = {
        startDateTime,
        endDateTime,
        isTrialClass: 'a',
      };
      dispatch(doDashboardScheduleList(params));
    };

    useEffect(() => {
      initDashboardScheduleList();
      dispatch(doGetMonitoringCount());
    }, [dispatch]);

    useEffect(() => {
      if (error && error.response) {
        switch (error.response.status) {
          case 409:
            message.warn('다른 상담원이 접수등록을 했습니다.');
        }
      }
    }, [error]);

    const getMonitorCheck = () => {
      const scheduleIds: number[] = [];
      for (const item of dashboardScheduleList) {
        if (isStudying(item.startDateTime)) {
          scheduleIds.push(item.id);
        }
      }
      if (scheduleIds.length >= 1) {
        dispatch(doPostMonitoringCheck({ scheduleIds }));
      }
    };

    const getMonitorCallCheck = () => {
      const scheduleIds: number[] = [];
      for (const item of dashboardScheduleList) {
        scheduleIds.push(item.id);
      }
      if (scheduleIds.length >= 1) {
        dispatch(doPostMonitoringCallCheck({ scheduleIds }));
      }
    };

    const getRefreshDashboardScheduleList = () => {
      const filter = dashboardScheduleList.filter((item) => {
        const isStudy = isAfter(new Date(), new Date(item.startDateTime)) && isBefore(new Date(), addMinutes(new Date(item.startDateTime), 30));
        return isStudy && (item.tutorEnterDateTime === null || item.studentEnterDateTime === null);
      });
      if (filter.length >= 1) {
        initDashboardScheduleList();
      }
    };

    useEffect(() => {
      getMonitorCheck();
      const tick = setInterval(getMonitorCheck, 3000);
      return () => clearInterval(tick);
    }, [dispatch, dashboardScheduleList]);

    useEffect(() => {
      getMonitorCallCheck();
      const tick = setInterval(getMonitorCallCheck, 3000);
      return () => clearInterval(tick);
    }, [dispatch, dashboardScheduleList]);

    useEffect(() => {
      const tick = setInterval(getRefreshDashboardScheduleList, 3000);
      return () => clearInterval(tick);
    }, [dispatch, dashboardScheduleList]);

    const showModal = (monitorCallCheck: IMonitoringCallCheck) => {
      setMonitorCallCheck(monitorCallCheck);
      setVisible(true);
    };

    const handleOk = () => {
      if (monitorCallCheck) {
        dispatch(doPatchMonitoringCallViewed(monitorCallCheck.id));
      }
      setVisible(false);
    };

    const handleCancel = () => {
      setVisible(false);
    };

    useEffect(() => {
      if (audioRef !== null && audioRef.current) {
        if (monitoringCallCheckList.length >= 1) {
          console.log('monitoringCallCheckList: ',monitoringCallCheckList);
          if (user) {
            console.log('user: ', user);
            if (user.id === 1638) {
              const studentFilter = monitoringCallCheckList.filter((item) => item.userType === StudyUserType.STUDENT);
              if (studentFilter.length >= 1) {
                audioRef.current.play();
              } else {
                audioRef.current.pause();
              }
            }
            if (user.id === 1628) {
              const studentFilter = monitoringCallCheckList.filter((item) => item.userType === StudyUserType.TUTOR);
              if (studentFilter.length >= 1) {
                audioRef.current.play();
              } else {
                audioRef.current.pause();
              }
            }
          }
        } else {
          audioRef.current.pause();
        }
      }
    }, [monitoringCallCheckList]);

    return (
      <>
        <div style={{ padding: '1.5rem' }}>
          <MonitoringCountBox />
          <MonitoringTable showModal={showModal} />
        </div>
        <Modal visible={visible} onOk={handleOk} onCancel={handleCancel} okText='접수완료'>
          <p>Call By {monitorCallCheck?.userType ?? ''}</p>
          {monitorCallCheck && monitorCallCheck.userType === StudyUserType.STUDENT ? <>
            <p>userName: {monitorCallCheck?.user.name ?? ''}</p>
            <p>userEmail: {monitorCallCheck?.user.email ?? ''}</p>
            <p>userPhone: {monitorCallCheck?.user.phone ?? ''}</p>
          </> : <>
            <p>tutorName: {monitorCallCheck ? `${monitorCallCheck.staff.firstName} ${monitorCallCheck.staff.lastName}` : ''}</p>
            <p>tutorEmail: {monitorCallCheck?.staff.email ?? ''}</p>
          </>}
          <p>studentName: {monitorCallCheck?.student.name ?? ''}</p>
          <p>issue: {monitorCallCheck?.issue ?? ''}</p>
        </Modal>
        <audio ref={audioRef} style={{ display: 'none' }}>
          <source src='./audio/mixkit-security-facility-breach-alarm-994.wav' type='audio/wav' />
        </audio>
      </>
    );
  }
;

export default MonitoringPage;
