import React, { FC, useEffect, useState } from 'react';
import { IContract, IContractSubmit } from '../../models/Contract';
import { Descriptions, Select, Input, Button } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle, divStyle } from '../../style/antdCssProperty';
import { addDays, addMonths, format } from 'date-fns';
import CustomDatePicker from '../common/CustomDatePicker';

interface IProps {
  contract?: IContract;

  onSubmit(data: IContractSubmit): void;

  onCancelClick(): void
}

const { Option } = Select;
const { TextArea } = Input;

const TutorContractEditor: FC<IProps> = ({ contract, onSubmit, onCancelClick }) => {
  const [startDate, setStartDate] = useState<Date>(addDays(new Date(), 1));
  const [endDate, setEndDate] = useState<Date>(addMonths(addDays(new Date(), 1), 6));
  const [classPerPay, setClassPerPay] = useState(8);
  const [file, setFile] = useState<File>();
  const [notes, setNotes] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (contract) {
      setStartDate(new Date(contract.startDate));
      setEndDate(new Date(contract.endDate));
      if (contract.classPerPay !== null) {
        setClassPerPay(contract.classPerPay);
      }
      if (contract.contractFileName) {
        setFileName(contract.contractFileName);
      }
      if (contract.notes) {
        setNotes(contract.notes);
      }
    }
  }, [contract]);

  useEffect(() => {
    if (file) {
      setFileName('');
    }
  }, [file]);

  const onStartDateChange = (value: any) => {
    setStartDate(value);
  };

  const onEndDateChange = (value: any) => {
    setEndDate(value);
  };

  const onClassPerPay = (value: number) => {
    setClassPerPay(value);
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const onNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const onSubmitClick = () => {
    const data: IContractSubmit = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
      file,
      classPerPay,
      notes,
    };
    onSubmit(data);
  };

  return (
    <>
      <Descriptions column={1} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='계약기간'>
          <CustomDatePicker value={startDate} onChange={onStartDateChange} />
          <CustomDatePicker value={endDate} onChange={onEndDateChange} />
        </Descriptions.Item>
        <Descriptions.Item label='base pay'>
          <Select value={classPerPay} onChange={onClassPerPay}>
            <Option value={8}>8</Option>
            <Option value={8.5}>8.5</Option>
            <Option value={9}>9</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label='계약서'>
          <Input type='file' onChange={onFileChange} />
          {(fileName && contract && contract.contractFiles) &&
          <a href={contract.contractFiles} target='_blank'>
            {contract.contractFileName}
          </a>}
        </Descriptions.Item>
        <Descriptions.Item label='메모'>
          <TextArea onChange={onNotesChange} value={notes} />
        </Descriptions.Item>
      </Descriptions>
      <div style={divStyle}>
        <Button type='primary' onClick={onSubmitClick} style={{ marginRight: '1.5rem' }}>등록</Button>
        <Button type='primary' onClick={onCancelClick}>취소</Button>
      </div>
    </>
  );
};

export default TutorContractEditor;
