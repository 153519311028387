import React, { FC, useEffect, useState } from 'react';
import { Breadcrumb, Modal, PageHeader, Pagination } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { boardList, boardRemove, doBoardCategoryList } from '../store/board.slice';
import ResourceList from '../components/ResourceList';
import { getSession, removeSession, setSession } from '../libs/session';
import ResourceFilterBox from '../components/Resource/ResourceFilterBox';
import { IBoardParams } from '../models/Board';

interface IMatch {
  id: string
}

const ResourcePage: FC = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const [id, setId] = useState(Number(match.params.id));
  const { boards, total, boardCategories } = useSelector((state: RootState) => state.boardState);
  const [visible, setVisible] = useState(false);
  const [boardId, setBoardId] = useState(0);
  const [params, setParams] = useState<IBoardParams>();

  const showModal = (boardId: number) => {
    setBoardId(boardId);
    setVisible(true);
  };

  useEffect(() => {
    setId(Number(match.params.id));
    const sessionParams = getSession<IBoardParams>('resource');
    if (sessionParams) {
      setSession('resource', {
        ...sessionParams,
        boardMasterId: Number(match.params.id),
      });
      if (sessionParams.boardMasterId !== Number(match.params.id)) {
        removeSession('resource');
      }
    }
  }, [match.params.id]);

  useEffect(() => {
    const sessionParams = getSession<IBoardParams>('resource');
    console.log('sessionParams: ', sessionParams);
    if (sessionParams) {
      if (sessionParams.page) {
        setPage(sessionParams.page);
      }
      dispatch(boardList({
        ...sessionParams,
        boardMasterId: id,
      }));
    } else {
      const newParams: IBoardParams = { boardMasterId: id, page };
      setParams(newParams);
      dispatch(boardList(newParams));
    }
    dispatch(doBoardCategoryList(id));
  }, [dispatch, page, id]);

  useEffect(() => {
    if (params) {
      setSession('resource', params);
    }
  }, [params]);

  const handlePageChange = (page: number) => {
    const newParams: IBoardParams = {
      ...params,
      page,
      boardMasterId: id,
    };
    setSession('resource', newParams);
    setPage(page);
  };

  const headerTitle = (id: number) => {
    switch (id) {
      case 1:
        return 'freeBoard';
      case 2:
        return 'noticeBoard';
      case 3:
        return 'Contents Introduction';
      case 4:
        return 'Contents Video';
      case 5:
        return 'Guide Video';
      case 6:
        return 'Class Material';
      case 7:
        return 'General Preparation';
      default:
        return 'board Write';
    }
  };

  const onSubmit = (params: IBoardParams) => {
    params.page = page;
    params.boardMasterId = id;
    setParams(params);
    dispatch(boardList(params));
  };

  const handleOk = () => {
    if (boardId !== 0) {
      dispatch(boardRemove(boardId));
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{(boards && boards.length !== 0) ? boards[0].boardMasterName : headerTitle(id)}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={(boards && boards.length !== 0) ? boards[0].boardMasterName : headerTitle(id)}>
      </PageHeader>
      <ResourceFilterBox bmId={id} total={total} boardCategorieList={boardCategories} onSubmit={onSubmit} />
      <ResourceList boards={boards} showModal={showModal} />
      <Pagination current={page} total={total} pageSize={20} onChange={handlePageChange}
                  showSizeChanger={false}
                  style={{ marginTop: '1.5rem' }} />
      <Modal
        title='remove board'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure you want to delete it?</p>
      </Modal>
    </>
  );
};

export default ResourcePage;
