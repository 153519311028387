import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { IEvaluation, IEvaluation2, IEvaluationDescriptions } from '../models/evaluation.model';

export const getEvaluation = (scheduleId: number): AxiosPromise<IEvaluation> => {
  return axios.get(`/api/schedule/${scheduleId}/evaluation`);
};

export const getEvaluation2 = (scheduleId: number): AxiosPromise<IEvaluation2> => {
  return axios.get(`/api/evaluations/read?scheduleId=${scheduleId}`);
};

export const getEvaluationDescriptionList = (): AxiosPromise<IEvaluationDescriptions[]> => {
  return axios.get('/api/schedule/evaluation/description');
};

