import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IPaymentMaster } from '../../models/payment.model';
import { format, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { tableStyle } from '../../style/antdCssProperty';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { doDeletePayment } from '../../store/payment.slice';
import { paymentUserCheck } from '../../libs/helper';

const PaymentTable: FC = () => {
  const dispatch = useDispatch();

  const { paymentMasterList } = useSelector((root: RootState) => root.paymentState);
  const { user } = useSelector((root: RootState) => root.authState);

  const [showModal, setShowModal] = useState(false);
  const [paymentMasterListIndex, setPaymentMasterListIndex] = useState(-1);

  const getDateString = (date: string | Date) => {
    return format(utcToZonedTime(new Date(date), 'Asia/Seoul'), 'yyyy-MM-dd');
  };

  const onDeleteClick = (index: number) => {
    setShowModal(true);
    setPaymentMasterListIndex(index);
  }

  const handleOk = () => {
    if (paymentMasterListIndex === -1) return
    dispatch(doDeletePayment(paymentMasterList[paymentMasterListIndex].id));
    setShowModal(false);
  }

  const handleCancel = () => {
    setShowModal(false);
  }

  const columns: ColumnsType<IPaymentMaster> = [
    {
      title: '생성명',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: '기간',
      key: 'date',
      render: (value, record) => `${getDateString(record.startDateTime)} ~ ${getDateString(subDays(new Date(record.endDateTime), 1))}`,
    },
    {
      title: '',
      render: (value, record,index) => <>
        <Link to={`/payment/${record.id}`}><Button type='primary'>상세</Button></Link>
        <Button type='primary' onClick={() => onDeleteClick(index)} style={{marginLeft: '1rem'}} disabled={paymentUserCheck(user?.id ?? 0)}>삭제</Button>
      </>,
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={paymentMasterList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
      <Modal title='강사 급여 데이터 삭제' visible={showModal} onOk={handleOk} onCancel={handleCancel}>
        <p>정말로 삭제하시겠습니까?</p>
      </Modal>
    </>
  );
};

export default PaymentTable;
