import { ChangeEvent, FC, useEffect, useState } from 'react';
import CustomDatePicker from '../common/CustomDatePicker';
import { Button, Input, message, Radio, Select } from 'antd';
import { FailReason, LevelTestFail, ScheduleStatus } from '../../models/Enum';
import { useDispatch, useSelector } from 'react-redux';
import { doPatchLevelTestFail } from '../../store/levelTest.slice';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RootState } from '../../store/rootReducer';
import styled from 'styled-components';

interface IMatch {
  id: string;
}

const { Option } = Select;
const { TextArea } = Input;

const ButtonBox = styled.div`
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
`;

const LevelTestFailForm: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<IMatch>();
  const scheduleId = +match.params.id;

  const { levelTestFail } = useSelector((root: RootState) => root.levelTestState);
  const { dashboardRead } = useSelector((root: RootState) => root.scheduleState);

  const [failDateTime, setFailDateTime] = useState(new Date());
  const [failReason, setFailReason] = useState<LevelTestFail | undefined>();
  const [failReasonDetail, setFailReasonDetail] = useState('');
  const [status, setStatus] = useState<'fail' | 'complete'>('complete');

  useEffect(() => {
    if (levelTestFail && levelTestFail.isFailed) {
      setFailDateTime(new Date(levelTestFail.failDateTime));
      setFailReason(levelTestFail.failReason);
      setFailReasonDetail(levelTestFail.failReasonDetail);
      setStatus('fail');
    } else {
      setFailDateTime(new Date());
      setFailReason(undefined);
      setFailReasonDetail('');
      setStatus('complete');
    }
  }, [levelTestFail]);

  useEffect(() => {
    if (dashboardRead && dashboardRead.status === ScheduleStatus.FAIL && !levelTestFail) {
      switch (dashboardRead.failReason) {
        case FailReason.ALL_NOSHOW:
          setFailReason(LevelTestFail.ALL_NO_SHOW);
          break;
        case FailReason.STUDENT_NOSHOW:
          setFailReason(LevelTestFail.S_NO_SHOW);
          break;
        case FailReason.TUTOR_NOSHOW:
          setFailReason(LevelTestFail.T_NO_SHOW);
          break;
        case FailReason.COMPANY_IT_ISSUE:
          setFailReason(LevelTestFail.C_IT_ISSUE);
          break;
        case FailReason.STUDENT_IT_ISSUE:
          setFailReason(LevelTestFail.S_IT_ISSUE);
          break;
        case FailReason.TUTOR_IT_ISSUE:
          setFailReason(LevelTestFail.T_IT_ISSUE);
          break;
      }
    }
  }, [dashboardRead, levelTestFail]);

  const onLevelTestFailChange = (value: any) => {
    setFailReason(value);
  };

  const onStatusChange = (e: any) => {
    setStatus(e.target.value);
  };

  const onFailDateTimeChange = (value: Date | null, dateString: string) => {
    if (value) {
      setFailDateTime(value);
    } else {
      setFailDateTime(new Date(dateString));
    }
  };

  const onFailReasonDetailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setFailReasonDetail(e.target.value);
  };

  const onSubmit = () => {
    if (!failReason) {
      message.warn('Fail 유형을 선택해주세요.');
      return;
    }
    if (!failReasonDetail) {
      message.warn('Fail 상담 내용을 작성해주세요.');
      return;
    }
    dispatch(doPatchLevelTestFail({
      failDateTime: failDateTime.toISOString(),
      failReason,
      failReasonDetail,
      scheduleId,
    }));
  };

  return (<div>
    <div>
      Level Test 상태: <Radio.Group value={status} onChange={onStatusChange}>
      <Radio value='complete'>Complete</Radio>
      <Radio value='fail'>Fail</Radio>
    </Radio.Group>
    </div>
    <div style={{ marginTop: '1.5rem' }}>
      Fail 상담 일자: <CustomDatePicker value={failDateTime} style={{ marginRight: '3rem' }}
                                    onChange={onFailDateTimeChange} />
      Fail 유형: <Select style={{ width: '10%' }} value={failReason} onChange={onLevelTestFailChange}>
      <Option value={LevelTestFail.S_NO_SHOW}>{LevelTestFail.S_NO_SHOW}</Option>
      <Option value={LevelTestFail.T_NO_SHOW}>{LevelTestFail.T_NO_SHOW}</Option>
      <Option value={LevelTestFail.ALL_NO_SHOW}>{LevelTestFail.ALL_NO_SHOW}</Option>
      <Option value={LevelTestFail.S_IT_ISSUE}>{LevelTestFail.S_IT_ISSUE}</Option>
      <Option value={LevelTestFail.T_IT_ISSUE}>{LevelTestFail.T_IT_ISSUE}</Option>
      <Option value={LevelTestFail.C_IT_ISSUE}>{LevelTestFail.C_IT_ISSUE}</Option>
    </Select>
    </div>
    <div style={{ display: 'flex', marginTop: '1.5rem' }}>
      <label style={{ width: 90 }}>Fail 상담 내용: </label>
      <TextArea rows={4} value={failReasonDetail} onChange={onFailReasonDetailChange} />
    </div>
    <ButtonBox>
      <Button type='primary' onClick={onSubmit}>작성</Button>
    </ButtonBox>
    <ButtonBox>
      <Button type='primary' onClick={() => {
        history.goBack();
      }}>리스트</Button>
    </ButtonBox>
  </div>);
};

export default LevelTestFailForm;
