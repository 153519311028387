import React, { FC } from 'react';
import { IStudentCourse } from '../../models/studyCourse.model';
import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { tableStyle } from '../../style/antdCssProperty';

interface IProps {
  studyCourseList: IStudentCourse[];

  showModal(productId: number, id: number): void;
}

const CustomerCourseTable: FC<IProps> = ({ studyCourseList, showModal }) => {
  const columns: ColumnsType<IStudentCourse> = [
    {
      title: '코스명',
      key: 'courseName',
      render: (value, record) => record.course.name,
    },
    {
      title: '상태',
      key: 'isValid',
      render: (value, record) => record.isValid ?
        <Button type='primary' onClick={() => showModal(record.course.product.id, record.id)}>변경</Button> : 'InActive',
    },
    {
      title: '시작일',
      key: 'createdAt',
      render: (value, record) => format(new Date(record.createdAt), 'yyyy-MM-dd HH:mm:ss'),
    },
  ];

  return (
    <Table
      style={tableStyle}
      dataSource={studyCourseList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
    />
  );
};

export default CustomerCourseTable;
