import { FC, useEffect, useState } from 'react';
import { Table, Modal, Pagination } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IMessageLog } from '../../models/messageLog.model';
import { format } from 'date-fns';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { getNewline } from '../../libs/helper';
import { doGetMessageLogList } from '../../store/messageLog.slice';

const CustomerAlarmTalkTable: FC = () => {
  const dispatch = useDispatch();

  const { messageLogList, total } = useSelector((root: RootState) => root.messageLogState);
  const { user } = useSelector((root: RootState) => root.userState);

  const [index, setIndex] = useState(-1);
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    user && dispatch(doGetMessageLogList({ userId: user.id, page }));
  }, [page, user]);

  const showModal = (index: number) => {
    setIndex(index);
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const columns: ColumnsType<IMessageLog> = [
    {
      title: '템플릿 코드',
      dataIndex: 'templatcode',
    },
    {
      title: '내용',
      render: (value, record, index) => <p style={{ ...LinkStyle, cursor: 'pointer' }}
                                           onClick={() => {
                                             showModal(index);
                                           }}>{record.message.substring(0, 20)}...</p>,
    },
    {
      title: '결과',
      dataIndex: 'description',
    },
    {
      title: '발송일',
      render: (value, record) => format(new Date(record.createdAt), 'yyyy-MM-dd'),
    },
  ];

  return (
    <>
      <Table
        style={tableStyle}
        dataSource={messageLogList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
      <Pagination current={page} onChange={onPageChange} total={total} defaultPageSize={20}
                  style={{ marginTop: '1.5rem' }}
                  showSizeChanger={false} />
      <Modal visible={visible} onOk={handleOk} onCancel={handleCancel}
             cancelButtonProps={{ style: { display: 'none' } }}>
        {index !== -1 && <p dangerouslySetInnerHTML={{ __html: getNewline(messageLogList[index].message) }} />}
      </Modal>
    </>
  );
};

export default CustomerAlarmTalkTable;
