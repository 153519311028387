import { CouponCreate, CouponListItem, CouponListQuery, CouponListResponse } from '../models/Coupon';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { list, create, couponList as couponListApi, remove, postLevelTestCoupon } from '../api/coupon';
import { message } from 'antd';

interface ICouponState {
  coupons: CouponListItem[] | null,
  couponList: CouponListResponse[] | null,
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: ICouponState = {
  coupons: null,
  couponList: null,
  isLoading: false,
  error: null,
};

const couponSlicer = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    requestStart: (state: ICouponState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestListSuccess: (state: ICouponState, action: PayloadAction<CouponListItem[]>) => {
      state.coupons = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestCouponListSuccess: (state: ICouponState, action: PayloadAction<CouponListResponse[]>) => {
      state.couponList = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestNotResponseSuccess: (state: ICouponState) => {
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: ICouponState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    postLevelTestCouponStart: (state: ICouponState) => {
      state.isLoading = true;
      state.error = null;
    },
    postLevelTestCouponSuccess: (state: ICouponState) => {
      state.isLoading = false;
      state.error = null;
    },
    postLevelTestCouponFailure: (state: ICouponState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestCouponListSuccess,
  requestNotResponseSuccess,
  requestFailure,
  postLevelTestCouponStart,
  postLevelTestCouponSuccess,
  postLevelTestCouponFailure,
} = couponSlicer.actions;

export default couponSlicer.reducer;

export const couponList = (search?: string): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(search);
    dispatch(requestListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const couponCreate = (data: CouponCreate): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await create(data);
    dispatch(requestNotResponseSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const couponListResponse = (data: CouponListQuery): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await couponListApi(data);
    dispatch(requestCouponListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const couponListRemove = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await remove(id);
    dispatch(requestNotResponseSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const doPostLevelTestCoupon = (studentId: number):AppThunk => async (dispatch) => {
  try {
    dispatch(postLevelTestCouponStart());
    await postLevelTestCoupon(studentId);
    dispatch(postLevelTestCouponSuccess());
    message.success('쿠폰이 발급되었습니다.');
  } catch (e) {
    dispatch(postLevelTestCouponFailure(e));
  }
}
