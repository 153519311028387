import React, { FC } from 'react';
import { Table } from 'antd';
import { StaffTutorListItem } from '../models/Staff';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import { tableStyle, LinkStyle } from '../style/antdCssProperty';
import { ColumnsType } from 'antd/lib/table';

interface IProps {
  staffs: StaffTutorListItem[];
}

const StaffList: FC<IProps> = ({ staffs }) => {
  const columns:ColumnsType<StaffTutorListItem> = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Email',
      key: 'email',
      render: (record: StaffTutorListItem) => {
        return <Link to={`/user/${record.id}`} style={LinkStyle}>{record.email}</Link>;
      },
    },
    {
      title: 'First Name',
      key: 'firstName',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
    },
    {
      title: '국적',
      key: 'country',
      render: (record: StaffTutorListItem) => {
        return record.country.name;
      },
    },
    {
      title: 'STEP',
      key: 'step',
      render: (record: StaffTutorListItem) => {
        return record.applicant.steps;
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (record: StaffTutorListItem) => {
        return record.applicant.status;
      },
    },
    {
      title: '최초등록일',
      key: 'createdAt',
      render: (record: StaffTutorListItem) => {
        const utcDate = utcToZonedTime(new Date(record.createdAt), 'Asia/Seoul');
        return format(utcDate, 'yyyy-MM-dd HH:MM:ss');
      },
    },
  ];

  return (
    <>
      <Table
        dataSource={staffs}
        columns={columns}
        size='small'
        pagination={false}
        rowKey='id'
        style={tableStyle}
      />
    </>
  );
};

export default StaffList;
