import React, { FC, useEffect, useState } from 'react';
import { Breadcrumb, Col, PageHeader, Row, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import CouponList from '../components/CouponList';
import { useDispatch, useSelector } from 'react-redux';
import { couponList } from '../store/coupon.slice';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';
import { divStyle } from '../style/antdCssProperty';

const { Search } = Input;

const CouponListPage: FC = () => {
  const dispatch = useDispatch();
  const { isLoading, coupons } = useSelector((root: RootState) => root.couponState);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(couponList());
  }, []);

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onSubmit = () => {
    dispatch(couponList(search));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='dashboard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>쿠폰관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='쿠폰관리' />
      <div style={divStyle}>
        <Search
          style={{ width: '30%' }}
          placeholder='쿠폰명'
          enterButton='검색'
          onSearch={onSearch}
          onSubmit={onSubmit}
        />
      </div>
      <Row gutter={10}>
        <Col span={24}>
          {
            isLoading ? (
              <Loader />
            ) : coupons ? (
              <CouponList coupons={coupons} />
            ) : (<></>)
          }
        </Col>
      </Row>
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Link to='coupons/form'><Button type='primary'>신규 쿠폰 등록</Button></Link>
      </div>
    </>
  );
};

export default CouponListPage;