import React, { FC, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, message, PageHeader } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import {
  doGetDashboardSchedule,
  doGetDashboardScheduleList,
  doPatchDashboardSchedule,
  doPatchScheduleFail,
} from '../store/schedule.slice';
import { doGetStudyroom, doGetStudyroomLogList } from '../store/studyroom.slice';
import DashboardInfo from '../components/dashboard/DashboardInfo';
import DashboardVideoList from '../components/dashboard/DashboardVideoList';
import {
  doGetLevelTestConsulting,
  doGetLevelTestFail,
  doGetLevelTestItem, doGetLevelTestItemElement,
} from '../store/levelTest.slice';
import DashboardLevelTestEvaluation from '../components/dashboard/DashboardLevelTestEvaluation';
import { doGetCourseList } from '../store/course.slice';
import { doGetStudent } from '../store/student.slice';
import CustomerInfo from '../components/Customer/CustomerInfo';
import { FailReason, ScheduleStatus } from '../models/Enum';
import DashboardFailReason from '../components/dashboard/DashboardFailReason';
import { IDashboardViewChangeUpdate, IScheduleFail } from '../models/Schedule';
import LevelTestForm from '../components/levelTest/LevelTestForm';
import DashboardModal from '../components/dashboard/DashboardModal';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import LevelTestFailForm from '../components/levelTest/LevelTestFailForm';

interface IMatch {
  id: string;
}

const LevelTestViewPage: FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const scheduleId = Number(match.params.id);

  const { dashboardRead, dashboardReadList } = useSelector((root: RootState) => root.scheduleState);
  const { studyroomVideoList } = useSelector((root: RootState) => root.studyroomState);
  const { levelTestItem } = useSelector((root: RootState) => root.levelTestState);
  const { courses } = useSelector((root: RootState) => root.courseState);
  const { customerStudent } = useSelector((root: RootState) => root.studentState);

  const [scheduleFailVisible, setScheduleFailVisible] = useState(false);
  const [failReason, setFailReason] = useState<FailReason>();
  const [visible, setVisible] = useState(false);
  const [updateAt, setUpdateAt] = useState(new Date());

  useEffect(() => {
    dispatch(doGetDashboardSchedule(scheduleId));
    dispatch(doGetStudyroom(scheduleId));
    dispatch(doGetLevelTestItem(scheduleId));
    dispatch(doGetCourseList(1));
    dispatch(doGetLevelTestConsulting(scheduleId));
    dispatch(doGetLevelTestFail(scheduleId));
    dispatch(doGetLevelTestItemElement());
    dispatch(doGetStudyroomLogList(scheduleId));
  }, [dispatch, scheduleId]);

  useEffect(() => {
    if (dashboardRead) {
      dispatch(doGetStudent(dashboardRead.student.id));
    }
  }, [dashboardRead]);

  const showScheduleFail = (failReason: FailReason) => {
    setFailReason(failReason);
    setScheduleFailVisible(true);
  };

  const handleFailReasonOk = (failReasonDetail: string, isClassRecovery: boolean, isEpisodeRecovery: boolean) => {
    if (!failReason) {
      return;
    }
    const body: IScheduleFail = {
      failReason,
      failReasonDetail: failReasonDetail,
    };
    if (isClassRecovery) {
      body.isClassRecovery = true;
    }
    if (isEpisodeRecovery) {
      body.isEpisodeRecovery = true;
    }
    dispatch(doPatchScheduleFail(scheduleId, body));
    setScheduleFailVisible(false);
  };

  const handleFailReasonCancel = () => {
    setScheduleFailVisible(false);
  };

  const showModal = () => {
    if (dashboardRead) {
      if (dashboardRead.status === ScheduleStatus.RESERVED) {
        dispatch(doGetDashboardScheduleList({ startDateTime: dashboardRead.startDateTime, isTrialClass: true }));
      } else {
        message.info('예약된 강사만 변경 가능합니다.');
        return;
      }
    }
    setVisible(true);
  };

  const handleOk = (scheduleId: number) => {
    if (scheduleId === 0) {
      message.info('튜터를 선택해주세요.');
      return;
    }
    const filter = dashboardReadList.filter(item => item.id === scheduleId);
    if (filter.length !== 0 && dashboardRead) {
      const data: IDashboardViewChangeUpdate = {
        changeStaffId: filter[0].staff.id,
        deletedId: filter[0].id,
        id: dashboardRead.id,
        originalStaffId: dashboardRead.staff.id,
        updateAt: format(utcToZonedTime(updateAt, 'UTC'), 'yyyy-MM-dd HH:mm:ss'),
      };
      dispatch(doPatchDashboardSchedule(data));
    } else {
      return;
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/level-test'>체험수업</Link></Breadcrumb.Item>
        <Breadcrumb.Item>체험수업 정보</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='체험수업 정보' />
      {dashboardRead &&
      <DashboardInfo isTrialClass dashboard={dashboardRead} showModal={showModal} showFailModal={showScheduleFail} />}
      {customerStudent && <CustomerInfo student={customerStudent} style={{ marginTop: '1.5rem' }} />}
      <DashboardVideoList studyroomVideoList={studyroomVideoList} />
      {levelTestItem && <DashboardLevelTestEvaluation levelTestItem={levelTestItem} courseList={courses} />}
      <DashboardModal visible={visible} dashboardList={dashboardReadList} onOk={handleOk} onCancel={handleCancel} />
      <DashboardFailReason visible={scheduleFailVisible} onOk={handleFailReasonOk} onCancel={handleFailReasonCancel} />
      <LevelTestForm />
      <LevelTestFailForm />
    </>
  );
};

export default LevelTestViewPage;
