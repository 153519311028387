import { FC } from 'react';

interface IProps {
  isCall: boolean;
  text: string;

  onClick?(): void;
}

const CallButton: FC<IProps> = ({ isCall, text, onClick }) => {
  return (
    <div onClick={onClick}
         style={{
           backgroundColor: isCall ? '#f5222d' : '#bfbfbf',
           display: 'inline-block',
           padding: '5px 10px',
           cursor: isCall ? 'pointer' : 'default',
         }}>
      {text}
    </div>
  );
};

export default CallButton;
