import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ITutor2 } from '../../models/tutor2.model';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';

interface IProps {
  tutors: ITutor2[];
}

const TutorTable: FC<IProps> = ({ tutors }) => {
  const columns: ColumnsType<ITutor2> = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Email',
      key: 'email',
      render: (value, record) => (
        <Link to={`/tutor/${record.id}`} style={LinkStyle}>{record.email}</Link>
      ),
    },
    {
      title: 'First Name',
      key: 'firstName',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
    },
    {
      title: '국적',
      key: 'country',
      render: (value, record) => {
        return record.country.name;
      },
    },
    {
      title: '상태',
      key: 'status',
      dataIndex: 'status',
    },
  ];

  return (
    <Table
      style={tableStyle}
      dataSource={tutors}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
    />
  );
};

export default TutorTable;
