import {
  IGetUserTransactionListSuccess,
  IUserTransaction,
  IUserTransactionBody,
  IUserTransactionParam,
} from '../models/UserTransaction';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getUserTransactionList, patchUserTransaction } from '../api/userTransaction';
import { message } from 'antd';
import { IUserTransactionHistory, IUserTransactionHistoryParams } from '../models/userTransactionHistory.model';
import { getUserTransactionHistoryList } from '../api/userTransactionHistory.api';

interface IUserTransactionState {
  userTransactions: IUserTransaction[];
  total: number;
  isLoading: boolean;
  isUpdated: boolean;
  body?: IUserTransactionBody;
  error?: AxiosError;
  userTransactionHistories: IUserTransactionHistory[];
}

const initialState: IUserTransactionState = {
  userTransactions: [], isLoading: false, total: 0, isUpdated: false, body: {
    isEpisodeRecovery: false,
  },
  userTransactionHistories: [],
};

const userTransactionSlicer = createSlice({
  name: 'userTransaction',
  initialState,
  reducers: {
    getUserTransactionListStart: (state: IUserTransactionState) => {
      state.userTransactions = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getUserTransactionListSuccess: (state: IUserTransactionState, action: PayloadAction<IGetUserTransactionListSuccess>) => {
      state.userTransactions = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getUserTransactionListFailure: (state: IUserTransactionState, action: PayloadAction<AxiosError>) => {
      state.userTransactions = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
    patchUserTransactionStart: (state: IUserTransactionState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchUserTransactionSuccess: (state: IUserTransactionState, { payload }: PayloadAction<{ id: number, body: IUserTransactionBody }>) => {
      state.isLoading = false;
      state.userTransactions = state.userTransactions.map((item) => {
        if (item.id === payload.id) {
          const body = payload.body;
          if (body.startDate) item.startDate = body.startDate;
          if (body.endDate) item.endDate = body.endDate;
          if (body.cancelClassCount) item.cancelClassCount = body.cancelClassCount;
          if (body.addClassCount?.cnt) item.totalClassCount = item.totalClassCount + body.addClassCount.cnt;
          return item;
        }
        return item;
      });
      state.isUpdated = true;
    },
    patchUserTransactionFailure: (state: IUserTransactionState, { payload }: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = payload;
    },
    setUserTransactionBody: (state: IUserTransactionState, { payload }: PayloadAction<IUserTransactionBody>) => {
      state.body = payload;
    },
    initIsUpdated: (state: IUserTransactionState) => {
      state.isUpdated = false;
    },
    getUserTransactionHistoryListSuccess: (state: IUserTransactionState, { payload }: PayloadAction<IUserTransactionHistory[]>) => {
      state.userTransactionHistories = payload;
    },
  },
});

export const {
  getUserTransactionListStart,
  getUserTransactionListSuccess,
  getUserTransactionListFailure,
  patchUserTransactionStart,
  patchUserTransactionSuccess,
  patchUserTransactionFailure,
  setUserTransactionBody,
  initIsUpdated,
  getUserTransactionHistoryListSuccess,
} = userTransactionSlicer.actions;
export default userTransactionSlicer.reducer;

export const doGetUserTransactionList = (params?: IUserTransactionParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserTransactionListStart());
    const response = await getUserTransactionList(params);
    const data: IGetUserTransactionListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getUserTransactionListSuccess(data));
  } catch (e) {
    dispatch(getUserTransactionListFailure(e));
  }
};

export const doPatchUserTransaction = (id: number, body: IUserTransactionBody, studentId?: number): AppThunk => async (dispatch) => {
  try {
    dispatch(patchUserTransactionStart());
    await patchUserTransaction(id, body);
    dispatch(patchUserTransactionSuccess({ id, body }));
    if (studentId) {
      const { data } = await getUserTransactionHistoryList({ studentId });
      dispatch(getUserTransactionHistoryListSuccess(data));
    }
    message.success('결제 내역 변경에 성공했습니다.');
  } catch (e) {
    dispatch(patchUserTransactionFailure(e));
  }
};

export const doGetUserTransactionHistoryList = (params: IUserTransactionHistoryParams): AppThunk => async (dispatch) => {
  try {
    const { data } = await getUserTransactionHistoryList(params);
    dispatch(getUserTransactionHistoryListSuccess(data));
  } catch (e) {}
};
