import React, { FC, SyntheticEvent } from 'react';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { dateFnsLocalizer, Calendar, stringOrDate, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

interface ISlotInfo {
  start: stringOrDate;
  end: stringOrDate;
  slots: Date[] | string[];
  action: 'select' | 'click' | 'doubleClick';
}

export interface IEvent {
  id: number;
  start: string | Date;
  end: string | Date;
  originalStart?: string | Date;
  status: string;
  isTrialClass?: boolean;
  isSelected?: boolean;
}

interface IProps {
  events: IEvent[];
  onSelectEvent?: (event: any, e: SyntheticEvent) => any;
  onSelectSlot?: (slotInfo: ISlotInfo) => any;
  eventPropGetter?: Function;

  onToolBarClick?: (range: Date[] | { start: stringOrDate; end: stringOrDate }, view: View | undefined) => void;
}

const locales = {
  ko: require('date-fns/locale/ko'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const styles = {
  width: 1400,
  height: 1200,
};

const BigCalender: FC<IProps> = ({ events, onSelectEvent, onSelectSlot, eventPropGetter, onToolBarClick }) => {
  const localEventPropGetter = (e: any, start: stringOrDate, end: stringOrDate, isSelected: boolean): React.HTMLAttributes<HTMLDivElement> => {
    const { status, isClicked } = e;

    let newStyle = {
      backgroundColor: '#F0F1C6',
      color: 'black',
      fontWeight: 'bold',
      border: 'none',
    };

    switch (status) {
      case 'tutor_ready':
        if (isClicked) {
          newStyle.backgroundColor = '#fbe5b3';
        } else {
          newStyle.backgroundColor = '#F0F1C6';
        }
        break;
      case 'reserved':
        if (isClicked) {
          newStyle.backgroundColor = '#b0e1cb';
        } else {
          newStyle.backgroundColor = '#C0E0E6';
        }
        break;
      case 'complete':
        if (isClicked) {
          newStyle.backgroundColor = '#c9cee9';
        } else {
          newStyle.backgroundColor = '#BDD1EA';
        }
        break;
      case 'holiday':
        if (isClicked) {
          newStyle.backgroundColor = '#ed9ea2';
        } else {
          newStyle.backgroundColor = '#FDD6B3';
        }
        break;
    }
    return {
      className: '',
      // @ts-ignore
      style: newStyle,
    };
  };

  return (
    <Calendar
      localizer={localizer}
      events={events}
      titleAccessor={(e) => e.status !== 'reserved' ? '' : `예약완료`}
      timeslots={1}
      step={30}
      defaultView='week'
      defaultDate={new Date()}
      style={styles}
      selectable
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      // @ts-ignore
      eventPropGetter={eventPropGetter ? eventPropGetter : localEventPropGetter}
      onRangeChange={onToolBarClick}
      views={['week', 'day']}
    />
  );
};

export default BigCalender;
