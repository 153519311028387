import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IScheduleCancelLog } from '../../models/scheduleCancelLog.model';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { CancelBy } from '../../models/Enum';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';
import { getCancelType } from '../../libs/helper';

interface IProps {
  scheduleCancelLogList: IScheduleCancelLog[];
}

const ScheduleCancelLogTable: FC<IProps> = ({ scheduleCancelLogList }) => {
  const getFormat = (date: string) => {
    return format(utcToZonedTime(new Date(date), 'Asia/Seoul'), 'yyyy.MM.dd HH:mm');
  };

  const getCancelByString = (cancelBy: CancelBy) => {
    switch (cancelBy) {
      case CancelBy.STAFF:
        return '관리자취소';
      case CancelBy.STUDENT:
        return '학생취소';
      case CancelBy.TUTOR:
        return '강사취소';
    }
  };

  const columns: ColumnsType<IScheduleCancelLog> = [
    {
      title: '수업일',
      render: (value, record) => getFormat(record.startDateTime),
    },
    {
      title: 'Class',
      render: (value, record) => `[${record.courseShortName}] ${record.episodeName} ${record.episodeLessonName}`,
    },
    {
      title: '학생',
      render: (value, record) => <Link style={LinkStyle} to={`/customer/${record.userId}`}>{record.studentName}({record.studentId})</Link>,
    },
    {
      title: '강사',
      render: (value, record) => `${record.staffFirstName} ${record.staffLastName}(${record.staffId})`,
    },
    {
      title: '취소일',
      render: (value, record) => getFormat(record.cancelDateTime),
    },
    {
      title: '구분',
      render: (value, record) => getCancelByString(record.cancelBy),
    },
    {
      title: '비고',
      render: (value, record) => getCancelType(record.startDateTime, record.cancelDateTime),
    },
  ];

  return (
    <Table
      style={tableStyle}
      dataSource={scheduleCancelLogList}
      columns={columns}
      size='small'
      rowKey='sid'
      pagination={false}
    />
  );
};

export default ScheduleCancelLogTable;
