import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { doPostUserMemo } from '../../store/user.slice';
import { divStyle } from '../../style/antdCssProperty';
import { Button, Input } from 'antd';

interface IProps {
  userId: number;
}

const { TextArea } = Input;

const CustomerUserMemoTab: FC<IProps> = ({ userId }) => {
  const dispatch = useDispatch();

  const [memo, setMemo] = useState('');

  const handleUserMemoSave = () => {
    if (!memo) return;
    dispatch(doPostUserMemo({ userId, memo }));
    setMemo('');
  };

  return <div style={{
    ...divStyle,
    marginTop: '1.5rem',
  }}>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <TextArea value={memo} onChange={({ target: { value } }) => setMemo(value)} />
      <Button style={{ marginLeft: '1rem' }} type='primary' onClick={handleUserMemoSave}>저장</Button>
    </div>
  </div>;
};

export default CustomerUserMemoTab;
