import { getLevelTestApplicantList } from '../api/levelTestApplicant.api';
import {
  ILevelTestApplicantParam,
  ILevelTestApplicant,
  IGetLevelTestApplicantListSuccess
} from '../models/levelTestApplicant.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';

interface ILevelTestApplicantState {
  levelTestApplicants: ILevelTestApplicant[];
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: ILevelTestApplicantState = {
  levelTestApplicants: [],
  total: 0,
  isLoading: false,
  error: undefined,
};

const levelTestApplicantSlicer = createSlice({
  name: 'levelTestApplicant',
  initialState,
  reducers: {
    getLevelTestApplicantListStart: (state: ILevelTestApplicantState) => {
      state.levelTestApplicants = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getLevelTestApplicantListSuccess: (state: ILevelTestApplicantState, action: PayloadAction<IGetLevelTestApplicantListSuccess>) => {
      state.levelTestApplicants = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getLevelTestApplicantListFailure: (state: ILevelTestApplicantState, action: PayloadAction<AxiosError>) => {
      state.levelTestApplicants = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

export const {
  getLevelTestApplicantListStart,
  getLevelTestApplicantListSuccess,
  getLevelTestApplicantListFailure
} = levelTestApplicantSlicer.actions;

export default levelTestApplicantSlicer.reducer;

export const doGetLevelTestApplicantList = (params: ILevelTestApplicantParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getLevelTestApplicantListStart());
    const response = await getLevelTestApplicantList(params);
    const data: IGetLevelTestApplicantListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getLevelTestApplicantListSuccess(data));
  } catch (e) {
    dispatch(getLevelTestApplicantListFailure(e));
  }
};
