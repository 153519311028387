import { AxiosPromise } from 'axios';
import { BoardCategory, BoardListItem, BoardRequest, IAttachment, IBoardParams, IBoardRead } from '../models/Board';
import axios from './axiosConfig';
import qs from 'qs';

export const create = (data: BoardRequest): AxiosPromise<{id: number}> => {
  return axios.post('/api/boards', data, {
    withCredentials: true,
  });
};

export const list = (params?: IBoardParams): AxiosPromise<BoardListItem[]> => {
  let url = `/api/boards`;
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const read = (boardId: number): AxiosPromise<IBoardRead> => {
  return axios.get(`/api/boards/${boardId}`);
};

export const update = (boardId: number, data: BoardRequest): AxiosPromise => {
  return axios.patch(`/api/boards/${boardId}`, data, {
    withCredentials: true,
  });
};

export const remove = (boardId: number): AxiosPromise => {
  return axios.delete(`/api/boards/${boardId}`, {
    withCredentials: true,
  });
};

export const boardCategoryList = (bmId: number): AxiosPromise<BoardCategory[]> => {
  return axios.get(`/api/boards/tutor/${bmId}`);
};

export const boardFilesList = (id: number): AxiosPromise<IAttachment[]> => {
  return axios.get(`/api/boards/${id}/files`);
};

export const boardFilesRemove = (attachmentId: number): AxiosPromise<IAttachment[]> => {
  return axios.delete(`/api/boards/${attachmentId}/files`);
};

export const boardFilesThumbnail = (id: number, formData: FormData): AxiosPromise<{ thumbnail: string }> => {
  return axios.post(`/api/boards/${id}/thumbnail`, formData);
};
