import { FC } from 'react';
import { IStaff } from '../../models/Staff';
import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { tableStyle } from '../../style/antdCssProperty';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

interface IProps {
  staffList: IStaff[];

  showModal(id: number): void;
}

const StaffTable: FC<IProps> = ({ staffList, showModal }) => {
  const { user } = useSelector((root: RootState) => root.authState);

  const columns: ColumnsType<IStaff> = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'First Name',
      key: 'firstName',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      key: 'lastName',
      dataIndex: 'lastName',
    },
    {
      title: '탈퇴',
      key: 'quit',
      render: (value, record) => {
        if (user) {
          if (user.isAdmin || user.id === record.id) {
            return <Button type='primary' onClick={() => {
              onWithdrawalClick(record.id);
            }}>탈퇴</Button>;
          }
        }
        return null;
      },
    },
  ];

  const onWithdrawalClick = (id: number) => {
    console.log('테이블');
    showModal(id);
  };

  return (
    <Table
      style={tableStyle}
      dataSource={staffList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
    />
  );
};

export default StaffTable;
