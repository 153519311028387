import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { ICourse } from '../models/Course';

export const getCourseList = (productId?: number): AxiosPromise<ICourse[]> => {
  let url = '/api/courses';
  if (productId) {
    url += `?productId=${productId}`;
  }
  return axios.get(url);
};
