import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Layout, Menu, Row, Col, Image } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import {
  FileOutlined,
  UserOutlined,
  LogoutOutlined,
  BookOutlined, AreaChartOutlined,
} from '@ant-design/icons';
import MainPage from 'containers/MainPage';
import { authLogout } from 'store/auth.slice';
import BoardPage from '../containers/board/BoardPage';
import BoardWritePage from '../containers/board/BoardWritePage';
import SurveyListPage from '../containers/SurveyListPage';
import DashBoardPage from '../containers/DashBoardPage';
import CustomerPage from '../containers/CustomerPage';
import CustomerListPage from '../containers/CustomerListPage';
import BookListPage from '../containers/BookListPage';
import EpisodeListPage from '../containers/EpisodeListPage';
import EpisodePageListPage from '../containers/EpisodePageListPage';
import SurveyPage from '../containers/SurveyPage';
import TutorListPage from '../containers/TutorListPage';
import TutorPage from '../containers/TutorPage';
import logo from '../img/logo.png';
import CouponListPage from '../containers/CouponListPage';
import CouponFormPage from '../containers/CouponFormPage';
import CouponPage from '../containers/CouponPage';
import EpisodeFormPage from '../containers/EpisodeFormPage';
import ResourcePage from '../containers/ResourcePage';
import ResourceWritePage from '../containers/ResourceWritePage';
import IssueListPage from '../containers/IssueListPage';
import IssuePage from '../containers/IssuePage';
import SubscriptionPage from '../containers/SubscriptionPage';
import TransactionPage from '../containers/TransactionPage';
import DashboardViewPage from '../containers/DashboardViewPage';
import ScheduleCancelLogListPage from '../containers/ScheduleCancelLogListPage';
import LevelTestBoardPage from '../containers/LevelTestBoardPage';
import LevelTestApplicantPage from '../containers/LevelTestApplicantPage';
import LevelTestViewPage from '../containers/LevelTestViewPage';
import StaffListPage from '../containers/StaffListPage';
import StaffCreatePage from '../containers/StaffCreatePage';
import NewPassword from '../containers/NewPassword';
import PaymentPage from '../containers/PaymentPage';
import TutorPaymentPage from '../containers/TutorPaymentPage';
import TutorPaymentSchedulePage from '../containers/TutorPaymentSchedulePage';
import StaticPage from '../containers/StaticPage';
import EventPage from '../containers/EventPage';

interface TitleEventEntity {
  key: string;
  domEvent: Event;
}

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const SiderDemo = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState('dashBoard');
  const [openKey, setOpenKey] = useState<Array<string>>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const path = history.location.pathname;
    const newOpenKey = [];
    if (path.indexOf('/staff') !== -1) {
      setCurrent('staff');
      return;
    }
    if (path.indexOf('/level-test') !== -1) {
      setCurrent('levelTest');
      return;
    }
    if (path.indexOf('/user') !== -1) {
      setCurrent('users');
      return;
    }
    if (path.indexOf('/customer') !== -1) {
      setCurrent('customer');
      return;
    }
    if (path.indexOf('/tutor') !== -1) {
      setCurrent('tutor');
      return;
    }
    if (path.indexOf('/subscription') !== -1) {
      setCurrent('subscription');
      return;
    }
    if (path.indexOf('/transaction') !== -1) {
      setCurrent('transaction');
      return;
    }
    if (path.indexOf('/book') !== -1) {
      setCurrent('book');
      return;
    }
    if (path.indexOf('/coupons') !== -1) {
      setCurrent('coupon');
      return;
    }
    if (path.indexOf('/boards') !== -1) {
      setCurrent('board');
      return;
    }
    if (path.indexOf('/schedule-cancel-log') !== -1) {
      setCurrent('scheduleCancelLog');
      return;
    }
    if (path.indexOf('/payment') !== -1) {
      setCurrent('payment');
      return;
    }
    if (path.indexOf('/resource') !== -1) {
      newOpenKey.push('Resource');
      setOpenKey(newOpenKey);
      if (path.indexOf('/3') !== -1) {
        setCurrent('Contents Introduction');
        return;
      }
      if (path.indexOf('/4') !== -1) {
        setCurrent('Contents Video');
        return;
      }
      if (path.indexOf('/5') !== -1) {
        setCurrent('Guide Video');
        return;
      }
      if (path.indexOf('/6') !== -1) {
        setCurrent('Lesson Plan');
        return;
      }
      if (path.indexOf('/7') !== -1) {
        setCurrent('General Preparation');
        return;
      }
    }
    if (path.indexOf('/faq') !== -1) {
      newOpenKey.push('FAQ');
      setOpenKey(newOpenKey);
      if (path.indexOf('/8') !== -1) {
        setCurrent('StudentFAQ');
        return;
      }
      if (path.indexOf('/9') !== -1) {
        setCurrent('TutorFAQ');
        return;
      }
    }
    if (path.indexOf('/one') !== -1) {
      newOpenKey.push('OtO');
      setOpenKey(newOpenKey);
      if (path.indexOf('/student') !== -1) {
        setCurrent('StudentOtO');
        return;
      }
      if (path.indexOf('/tutor') !== -1) {
        setCurrent('TutorOtO');
        return;
      }
    }
  }, [history]);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const handleLogout = () => {
    dispatch(authLogout());
    history.push('/login');
  };

  const handleClick = (event: MenuInfo) => {
    setCurrent(event.key as string);
  };

  const onMenuTitleClick = (e: TitleEventEntity) => {
    const found = openKey.find((item) => item === e.key);
    if (found) {
      const newOpenKey = openKey.filter((item) => item !== e.key);
      setOpenKey(newOpenKey);
    } else {
      const newOpenKey = [...openKey];
      newOpenKey.push(e.key);
      setOpenKey(newOpenKey);
    }
  };

  return (
    <BrowserRouter>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div
            className='logo'
            style={{
              margin: '20px 0px',
              color: 'white',
              textAlign: 'center',
            }}>
            <Link to='/'><Image src={logo} alt='logo' preview={false} /></Link>
          </div>
          <div style={{ paddingTop: '0px', backgroundColor: 'black' }}>
            <Menu
              openKeys={openKey}
              selectedKeys={[current]}
              mode='inline'
              onClick={handleClick}>
              <Menu.Item key='users' icon={<UserOutlined />}>
                <Link to='/user'>채용관리</Link>
              </Menu.Item>
              <Menu.Item key='customer' icon={<UserOutlined />}>
                <Link to='/customer'>회원관리</Link>
              </Menu.Item>
              <Menu.Item key='tutor' icon={<UserOutlined />}>
                <Link to='/tutor'>강사관리</Link>
              </Menu.Item>
              <Menu.Item key='staff' icon={<UserOutlined />}>
                <Link to='/staff'>스태프 관리</Link>
              </Menu.Item>
              <SubMenu key='subscriptions' icon={<UserOutlined />} title='수강관리' onTitleClick={onMenuTitleClick}>
                <Menu.Item key='subscription' style={{ backgroundColor: '#333' }}>
                  <Link to='/subscription/active'>수강목록</Link>
                </Menu.Item>
                <Menu.Item key='cancellation' style={{ backgroundColor: '#333' }}>
                  <Link to='/subscription/deactive'>구독해지</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key='transaction' icon={<UserOutlined />}>
                <Link to='/transaction'>결제관리</Link>
              </Menu.Item>
              <Menu.Item key='book' icon={<BookOutlined />}>
                <Link to='/book'>교재관리</Link>
              </Menu.Item>
              <Menu.Item key='coupon' icon={<BookOutlined />}>
                <Link to='/coupons'>쿠폰관리</Link>
              </Menu.Item>
              <Menu.Item key='board' icon={<FileOutlined />}>
                <Link to='/boards'>공지관리</Link>
              </Menu.Item>
              <Menu.Item key='event' icon={<FileOutlined />}>
                <Link to='/events'>이벤트관리</Link>
              </Menu.Item>
              <Menu.Item key='dashBoard' icon={<FileOutlined />}>
                <Link to='/dashBoard'>DashBoard</Link>
              </Menu.Item>
              <SubMenu key='levelTests' icon={<FileOutlined />} title='체험수업' onTitleClick={onMenuTitleClick}>
                <Menu.Item key='levelTest' style={{ backgroundColor: '#333' }}>
                  <Link to='/level-test'>체험수업 현황</Link>
                </Menu.Item>
                <Menu.Item key='testApplicants' style={{ backgroundColor: '#333' }}>
                  <Link to='/level-test-applicants'>체험수업 신청자</Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key='scheduleCancelLog' icon={<FileOutlined />}>
                <Link to='/schedule-cancel-log'>수업 취소 List</Link>
              </Menu.Item>
              <Menu.Item key='payment' icon={<FileOutlined/>}>
                <Link to='/payment'>강사 급여 관리</Link>
              </Menu.Item>
              <SubMenu key='Resource' icon={<FileOutlined />} title='Education Resource'
                       onTitleClick={onMenuTitleClick}>
                <Menu.Item key='Contents Introduction' style={{ backgroundColor: '#333' }}>
                  <Link to='/resource/3'>Contents Introduction</Link>
                </Menu.Item>
                <Menu.Item key='Contents Video' style={{ backgroundColor: '#333' }}>
                  <Link to='/resource/4'>Contents Video</Link>
                </Menu.Item>
                <Menu.Item key='Guide Video' style={{ backgroundColor: '#333' }}>
                  <Link to='/resource/5'>Guide Video</Link>
                </Menu.Item>
                <Menu.Item key='Lesson Plan' style={{ backgroundColor: '#333' }}>
                  <Link to='/resource/6'>Class Material</Link>
                </Menu.Item>
                <Menu.Item key='General Preparation' style={{ backgroundColor: '#333' }}>
                  <Link to='/resource/7'>General Preparation</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key='FAQ' icon={<FileOutlined />} title='FAQ 관리' onTitleClick={onMenuTitleClick}>
                <Menu.Item key='StudentFAQ' style={{ backgroundColor: '#333' }}>
                  <Link to='/faq/8'>학생 FAQ</Link>
                </Menu.Item>
                <Menu.Item key='TutorFAQ' style={{ backgroundColor: '#333' }}>
                  <Link to='/faq/9'>강사 FAQ</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key='OtO' icon={<FileOutlined />} title='1:1 문의 관리' onTitleClick={onMenuTitleClick}>
                <Menu.Item key='StudentOtO' style={{ backgroundColor: '#333' }}>
                  <Link to='/one/student'>학생 문의</Link>
                </Menu.Item>
                <Menu.Item key='TutorOtO' style={{ backgroundColor: '#333' }}>
                  <Link to='/one/tutor'>강사 문의</Link>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </Sider>
        <Layout className='site-layout'>
          <Header
            className='site-layout-background'
            style={{
              padding: 0,
              backgroundColor: '#000000',
            }}>
            <Row justify='end'>
              <Col>
                <a href='/monitoring' target='_blank'>
                  <AreaChartOutlined color='white' height='5em' width='5em' style={{
                    color: 'white',
                    width: '3em',
                    height: '3em',
                    fontSize: 20,
                  }} />
                </a>
                <Link to='/new-password'>
                  <UserOutlined
                    color='white'
                    height='5em'
                    width='5em'
                    style={{
                      color: 'white',
                      width: '3em',
                      height: '3em',
                      fontSize: 20,
                    }} />
                </Link>
                <LogoutOutlined
                  color='white'
                  height='5em'
                  width='5em'
                  style={{
                    color: 'white',
                    width: '3em',
                    height: '3em',
                    fontSize: '20px',
                  }}
                  onClick={handleLogout}
                />
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <div
              className='site-layout-background'
              style={{ padding: 24, minHeight: 360 }}>
              <Switch>
                <Redirect path='/' exact to='/main' />
                <Route path='/main' exact component={StaticPage} />
                <Route path='/new-password' exact component={NewPassword} />
                <Route path='/dashBoard' exact component={DashBoardPage} />
                <Route path='/dashboard/:id' exact component={DashboardViewPage} />
                <Route path='/level-test' exact component={LevelTestBoardPage} />
                <Route path='/level-test-applicants' exact component={LevelTestApplicantPage} />
                <Route path='/level-test/:id' exact component={LevelTestViewPage} />
                <Route path='/book' exact component={BookListPage} />
                <Route path='/book/:bookId/episode/:id/page' exact component={EpisodePageListPage} />
                <Route path='/book/:bookId/episode/:id/' exact component={EpisodeFormPage} />
                <Route path='/book/:id/episode' exact component={EpisodeListPage} />
                <Route path='/boards' exact component={MainPage} />
                <Route path='/events' exact component={EventPage} />
                <Route path='/boards/:id/:type' exact component={BoardWritePage} />
                <Route path='/boards/:id' exact component={BoardPage} />
                <Route path='/user' exact component={SurveyListPage} />
                <Route path='/user/:id' exact component={SurveyPage} />
                <Route path='/tutor' exact component={TutorListPage} />
                <Route path='/tutor/:id' exact component={TutorPage} />
                <Route path='/staff' exact component={StaffListPage} />
                <Route path='/staff/create' exact component={StaffCreatePage} />
                <Route path='/customer' exact component={CustomerListPage} />
                <Route path='/customer/:id' exact component={CustomerPage} />
                <Route path='/coupons' exact component={CouponListPage} />
                <Route path='/coupons/form' exact component={CouponFormPage} />
                <Route path='/coupons/:id' exact component={CouponPage} />
                <Route path='/resource/:id' exact component={ResourcePage} />
                <Route path='/resource/:id/:type' exact component={ResourceWritePage} />
                <Route path='/faq/:id' exact component={ResourcePage} />
                <Route path='/faq/:id/:type' exact component={ResourceWritePage} />
                <Route path='/one/:user' exact component={IssueListPage} />
                <Route path='/one/:user/:id' exact component={IssuePage} />
                <Route path='/subscription/:status' exact component={SubscriptionPage} />
                <Route path='/transaction' exact component={TransactionPage} />
                <Route path='/schedule-cancel-log' exact component={ScheduleCancelLogListPage} />
                <Route path='/payment' exact component={PaymentPage} />
                <Route path='/payment/:paymentMasterId/tutor/:staffId' exact component={TutorPaymentSchedulePage} />
                <Route path='/payment/:id' exact component={TutorPaymentPage} />
                <Redirect path='/login' to='/main' exact />
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            COPYRIGHT ⓒ EGG School LLC. All rights reserved.
          </Footer>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

export default SiderDemo;
