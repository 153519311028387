import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { issueCategoryList, issueStudentList, issueTutorList } from '../store/issue.slice';
import IssueList from '../components/IssueList';
import Loader from '../components/Loader';
import { Link, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, Col, PageHeader, Row, Pagination } from 'antd';
import IssueTutorList from '../components/IssueTutorList';
import { IssueType } from '../models/Enum';
import { IIssueParam } from '../models/Issue';
import IssueFilterBox from '../components/issue/IssueFilterBox';

interface IMatch {
  user: string;
}

const IssueListPage: FC = () => {
  const dispatch = useDispatch();
  const {
    issueStudents,
    isLoading,
    issueTutors,
    issueCategories,
    total,
  } = useSelector((root: RootState) => root.issueState);
  const match = useRouteMatch<IMatch>();
  const [current, setCurrent] = useState(1);
  const [param, setParam] = useState<IIssueParam>({});

  useEffect(() => {
    if (match.params.user === 'student') {
      const data: IIssueParam = {
        ...param,
        page: current,
      };
      setParam(data);
      dispatch(issueStudentList(data));
      dispatch(issueCategoryList(IssueType.STUDENT));
    } else if (match.params.user === 'tutor') {
      const data: IIssueParam = {
        ...param,
        page: current,
      };
      setParam(data);
      dispatch(issueTutorList(data));
      dispatch(issueCategoryList(IssueType.TUTOR));
    }
  }, [dispatch, match.params.user, current]);

  const onSubmit = (data: IIssueParam) => {
    setParam(data);
    if (match.params.user === 'student') {
      dispatch(issueStudentList(data));
    } else {
      dispatch(issueTutorList(data));
    }
  };

  const onCurrentChange = (page: number) => {
    setCurrent(page);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashboard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>1:1 문의 관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='1:1 문의 관리' />
      <IssueFilterBox issueCategoryList={issueCategories} onSubmit={onSubmit} />
      <Row gutter={10}>
        <Col span={24}>
          {
            !isLoading ? (match.params.user && match.params.user === 'student') ? (
              <IssueList issueStudents={issueStudents} user={match.params.user} />
            ) : (
              <IssueTutorList issueTutors={issueTutors} />
            ) : (
              <Loader />
            )
          }
        </Col>
      </Row>
      <Pagination
        current={current}
        onChange={onCurrentChange}
        total={total}
        defaultPageSize={20}
        style={{ marginTop: '1.5rem' }}
        showSizeChanger={false}
      />
    </>
  );
};

export default IssueListPage;
