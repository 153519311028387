import { FC, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  doDeletePaymentCredit,
  doDeletePaymentSchedule, doDeletePayTutorExtraPayment, doGetPayTutorExtraPayment,
  doGetTierBonus,
  doGetTutorPayment,
  doGetTutorPaymentCreditList,
  doGetTutorPaymentScheduleList, doPostPayTutorExtraPayment, initTierBonus,
  doDeleteStaffPaymentInit, doGetPreviousMonthSchedule,
} from '../store/payment.slice';
import { Breadcrumb, PageHeader, Button, Modal } from 'antd';
import { RootState } from '../store/rootReducer';
import TutorPaymentScheduleInfo from '../components/payment/TutorPaymentScheduleInfo';
import BasePaymentTable from '../components/payment/BasePaymentTable';
import NoShowPaymentTable from '../components/payment/NoShowPaymentTable';
import CancellationPaymentTable from '../components/payment/CancellationPaymentTable';
import SubstitutePaymentTable from '../components/payment/SubstitutePaymentTable';
import { IPaymentSchedule, ITutorExtraPaymentCreate } from '../models/payment.model';
import { ScheduleType } from '../models/Enum';
import CreditPaymentTable from '../components/payment/CreditPaymentTable';
import TierPaymentTable from '../components/payment/TierPaymentTable';
import StudentNoShowTable from '../components/payment/StudentNoShowTable';
import ITIssuePaymentTable from '../components/payment/ITIssuePaymentTable';
import AddPaymentModal from '../components/payment/AddPaymentModal';
import TutorExtraPaymentTable from '../components/payment/TutorExtraPaymentTable';
import { paymentUserCheck } from '../libs/helper';
import Student2HourCancelTable from '../components/payment/Student2HourCancelTable';

interface IMatch {
  paymentMasterId: string;
  staffId: string;
}

const { confirm } = Modal;
const confirmDeleteText = {
  title: '수업 데이터 삭제',
  content: '수업 데이터를 정말 삭제하시겠습니까?',
  okText: '삭제',
  cancelText: '취소',
};

const TutorPaymentSchedulePage: FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const paymentMasterId = Number(match.params.paymentMasterId);
  const staffId = Number(match.params.staffId);

  const { tutorPayment, paymentSchedule } = useSelector((root: RootState) => root.paymentState);
  const { user } = useSelector((root: RootState) => root.authState);

  const [visible, setVisible] = useState(false);

  const handleOk = (body: ITutorExtraPaymentCreate) => {
    dispatch(doPostPayTutorExtraPayment(paymentMasterId, staffId, body));
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const filter = (item: IPaymentSchedule) => {
    return item.scheduleType === ScheduleType.CANCEL2 || item.scheduleType === ScheduleType.CANCEL3 || item.scheduleType === ScheduleType.TUTOR_NOSHOW || item.scheduleType === ScheduleType.ALL_NOSHOW;
  };

  useEffect(() => {
    dispatch(doGetTutorPayment(paymentMasterId, staffId));
    dispatch(doGetTutorPaymentScheduleList(paymentMasterId, staffId));
    dispatch(doGetPayTutorExtraPayment(paymentMasterId, staffId));
    dispatch(doGetPreviousMonthSchedule(paymentMasterId, staffId));
  }, [dispatch, paymentMasterId, staffId]);

  useEffect(() => {
    if (paymentSchedule.length >= 1) {
      const filterSchedule = paymentSchedule.filter(filter);
      if (filterSchedule.length === 0) {
        dispatch(doGetTutorPaymentCreditList(paymentMasterId, staffId));
      }
    }
  }, [dispatch, paymentSchedule]);

  useEffect(() => {
    if (tutorPayment && paymentSchedule.length >= 1) {
      const filterSchedule = paymentSchedule.filter((item) => item.scheduleType === ScheduleType.SUCCESS);
      if (filterSchedule.length >= 41) {
        dispatch(doGetTierBonus(tutorPayment.tier, filterSchedule.length));
      } else {
        dispatch(initTierBonus());
      }
    } else {
      dispatch(initTierBonus());
    }
  }, [dispatch, paymentSchedule, tutorPayment]);

  const showConfirmScheduleDelete = (paymentScheduleId: number) => {
    confirm({
      title: confirmDeleteText.title,
      content: confirmDeleteText.content,
      okText: confirmDeleteText.okText,
      cancelText: confirmDeleteText.cancelText,
      onOk() {
        dispatch(doDeletePaymentSchedule(paymentMasterId, staffId, paymentScheduleId));
      },
    });
  };

  const onScheduleDelete = (paymentScheduleId: number) => {
    showConfirmScheduleDelete(paymentScheduleId);
  };

  const showConfirmCreditDelete = (tutorDayClassCountId: number) => {
    confirm({
      title: confirmDeleteText.title,
      content: confirmDeleteText.content,
      okText: confirmDeleteText.okText,
      cancelText: confirmDeleteText.cancelText,
      onOk() {
        dispatch(doDeletePaymentCredit(tutorDayClassCountId));
      },
    });
  };

  const onCreditDelete = (tutorDayClassCountId: number) => {
    showConfirmCreditDelete(tutorDayClassCountId);
  };

  const showConfirmTutorExtraPaymentDelete = (id: number) => {
    confirm({
      title: confirmDeleteText.title,
      content: confirmDeleteText.content,
      okText: confirmDeleteText.okText,
      cancelText: confirmDeleteText.cancelText,
      onOk() {
        dispatch(doDeletePayTutorExtraPayment(paymentMasterId, staffId, id));
      },
    });
  };

  const onTutorExtraPaymentDelete = (id: number) => {
    showConfirmTutorExtraPaymentDelete(id);
  };

  const showModal = () => {
    setVisible(true);
  };

  const onInitStaffPayment = () => {
    dispatch(doDeleteStaffPaymentInit(paymentMasterId, staffId));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to='/payment'>강사 급여 관리</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link
          to={`/payment/${paymentMasterId}`}>{tutorPayment?.paymentMaster?.title ?? ''}</Link></Breadcrumb.Item>
        <Breadcrumb.Item>
          강사 정보
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='강사 정보' />
      <TutorPaymentScheduleInfo />
      <BasePaymentTable onDelete={onScheduleDelete} />
      <StudentNoShowTable onDelete={onScheduleDelete} />
      <Student2HourCancelTable onDelete={onScheduleDelete} />
      <ITIssuePaymentTable onDelete={onScheduleDelete} />
      <TierPaymentTable />
      <CreditPaymentTable onDelete={onCreditDelete} />
      <SubstitutePaymentTable onDelete={onScheduleDelete} />
      <CancellationPaymentTable onDelete={onScheduleDelete} />
      <NoShowPaymentTable onDelete={onScheduleDelete} />
      <TutorExtraPaymentTable onDelete={onTutorExtraPaymentDelete} />
      <div style={{
        marginTop: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Link to={`/payment/${paymentMasterId}`}>
          <Button
            type='primary'>목록</Button>
        </Link>
        <Button type='primary' onClick={onInitStaffPayment}
                disabled={tutorPayment?.paymentMaster.isPublished || user === null || paymentUserCheck(user.id)}>초기화</Button>
        <Button type='primary' onClick={showModal}
                disabled={tutorPayment?.paymentMaster.isPublished || user === null || paymentUserCheck(user.id)}>추가 항목
          등록</Button>
      </div>
      <AddPaymentModal visible={visible} onOk={handleOk} onCancel={handleCancel} />
    </>
  );
};

export default TutorPaymentSchedulePage;
