import React, { FC, useCallback, useState } from 'react';
import { BookItem } from '../models/Book';
import { Button, Descriptions, Modal, Table } from 'antd';
import { EpisodeCount, EpisodeItem } from '../models/Episode';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { LinkStyle } from '../style/antdCssProperty';
import arrayMove from 'array-move';

interface IProps {
  book: BookItem;
  episodes: EpisodeItem[];

  onUpdate(episodes: EpisodeCount[]): void;

  onDelete(id: number): void;
}

interface IMatch {
  id: string;
}

const EpisodeList: FC<IProps> = ({ book, episodes, onUpdate, onDelete }) => {
  const [episodeList, setEpisodeList] = useState(episodes);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState(0);
  const match = useRouteMatch<IMatch>();

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'pointer' }} />
  ));

  const showModal = useCallback((id: number) => {
    setId(id);
    setIsModalVisible(true);
  }, []);

  const handleOk = useCallback(() => {
    onDelete(id);
    setIsModalVisible(false);
  }, [id, onDelete]);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const columns: ColumnsType<EpisodeItem> = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: '차시',
      key: 'pageNo',
      render: (text: string, record: EpisodeItem) => (
        record.pageNo
      ),
    },
    {
      title: 'Episode Name',
      key: 'name',
      render: (text: string, record: EpisodeItem) => (
        <Link to={`/book/${match.params.id}/episode/${record.id}/page`}
              style={LinkStyle}>{record.name}</Link>
      ),
    },
    {
      title: 'Lesson Name',
      key: 'lessonName',
      render: (text: string, record: EpisodeItem) => (
        record.lessonName
      ),
    },
    {
      title: '편집',
      key: 'edit',
      render: (text: string, record: EpisodeItem, index: number) => (
        <>
          <Link to={`/book/${match.params.id}/episode/${index}/`}>
            <Button type='primary'
                    style={{ marginRight: '1.5rem' }}>수정</Button>
          </Link>
          <Button type='primary' onClick={() => showModal(record.id)}>삭제</Button>
        </>
      ),
    },
  ];

  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableTable = SortableContainer((props: any) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (oldIndex !== newIndex) {
      // @ts-ignore
      const newData = arrayMove<EpisodeItem>([].concat(episodeList), oldIndex, newIndex).filter(el => !!el);
      for (let i = 0; i < episodeList.length; i++) {
        newData[i] = {
          ...newData[i],
          pageNo: episodeList[i].pageNo,
        };
      }
      setEpisodeList(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableTable
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const onUpdateClick = () => {
    const data: EpisodeCount[] = [];
    for (const episodeItem of episodeList) {
      data.push({
        id: episodeItem.id,
        pageNo: episodeItem.pageNo,
      });
    }
    onUpdate(data);
  };

  // @ts-ignore
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = episodeList.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <>
      <Descriptions bordered size='small'>
        <Descriptions.Item label='Full Name'>{book.name}</Descriptions.Item>
        <Descriptions.Item label='Short Name'>{book.shortName}</Descriptions.Item>
      </Descriptions>
      <Table
        dataSource={episodeList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        style={{
          marginTop: '4rem',
        }} />
      <div style={{
        marginTop: '1rem',
        textAlign: 'center',
      }}
      >
        <Link to={`/book/${match.params.id}/episode/-1/`}>
          <Button type='primary' style={{ marginRight: '1.5rem' }}>추가하기</Button>
        </Link>
        <Button type='primary' onClick={onUpdateClick}>차시 변경하기</Button>
      </div>

      <Modal
        title='에피소드 삭제'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>정말로 삭제하시겠습니까?</p>
      </Modal>
    </>
  );
};

export default EpisodeList;
