import React, { FC, useEffect, useState } from 'react';
import EpisodeForm from '../components/EpisodeForm';
import { Breadcrumb, Col, PageHeader, Row, message, Modal } from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { doPatchEpisodeFile, episodeCreate, episodeList, episodeUpdate } from '../store/episode.slice';
import { RootState } from '../store/rootReducer';
import Loader from '../components/Loader';

interface IMatch {
  bookId: string;
  id: string;
}

const EpisodeFormPage: FC = () => {
  const match = useRouteMatch<IMatch>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, episodes } = useSelector((state: RootState) => state.episodeState);
  const [header, setHeader] = useState('');
  const [lessonPlanModal, setLessonPlanModal] = useState(false);
  const [classToolModal, setClassToolModal] = useState(false);

  useEffect(() => {
    dispatch(episodeList(Number(match.params.bookId)));
    if (match.params.id === '0') {
      setHeader('Episode 저장');
    } else {
      setHeader('Episode 변경');
    }
  }, []);

  const onCreate = async (pageNo: number, name: string, lessonName: string, lessonPlan: File, classTool: File) => {
    const data = new FormData();
    data.append('bookId', match.params.bookId);
    data.append('pageNo', String(pageNo));
    data.append('name', name);
    data.append('lessonName', lessonName);
    data.append('lessonPlan', lessonPlan);
    data.append('classTool', classTool);
    console.log('test in : ', lessonName);

    // data.forEach( item => console.log(item))
    await dispatch(episodeCreate(data));
    message.success('성공적으로 에피소드를 만들었습니다.');
    history.push(`/book/${match.params.bookId}/episode`);
  };

  const onUpdate = async (name: string, lessonName: string, lessonPlan: File, classTool: File) => {
    // const data: EpisodeUpdate = {
    //   id: Number(match.params.id), name: name,
    // };
    const data = new FormData();
    data.append('name', name);
    data.append('lessonName', lessonName);
    data.append('lessonPlan', lessonPlan);
    data.append('classTool', classTool);
    data.append('id', String(episodes![Number(match.params.id)].id));

    
    console.log('test in form_data : ', data);
    await dispatch(episodeUpdate(data));
    message.success('성공적으로 에피소드를 변경했습니다.');
    history.push(`/book/${match.params.bookId}/episode`);
  };

  const onLessonPlanDelete = () => {
    setLessonPlanModal(true);
  };

  const onClassToolDelete = () => {
    setClassToolModal(true);
  };

  const handleLessonPlanOk = () => {
    dispatch(doPatchEpisodeFile(episodes![Number(match.params.id)].id, 'lessonPlan', Number(match.params.id)));
    setLessonPlanModal(false);
  };

  const handleLessonPlanCancel = () => {
    setLessonPlanModal(false);
  };

  const handleClassToolOk = () => {
    dispatch(doPatchEpisodeFile(episodes![Number(match.params.id)].id, 'classTool', Number(match.params.id)));
    setClassToolModal(false);
  };

  const handleClassToolCancel = () => {
    setClassToolModal(false);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to='/book'>Book</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/book/${match.params.bookId}`}>Episode</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{header}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={header} />
      <Row gutter={10}>
        <Col span={24}>
          {
            isLoading ? (
              <Loader />
            ) : match.params.id === '-1' ? (
              <EpisodeForm onCreate={onCreate} onUpdate={onUpdate}
                           episodeCount={episodes && episodes.length !== 0 ? episodes[episodes.length - 1].pageNo + 1 : undefined} />
            ) : (
              <EpisodeForm isCreate={false} onCreate={onCreate} onUpdate={onUpdate}
                           lessonPlan={episodes && episodes.length !== 0 ? episodes[Number(match.params.id)].lessonPlan : undefined}
                           classTool={episodes && episodes.length !== 0 ? episodes[Number(match.params.id)].classTool : undefined}
                           lessonPlanName={episodes && episodes.length !== 0 ? episodes[Number(match.params.id)].lessonPlanName : undefined}
                           classToolName={episodes && episodes.length !== 0 ? episodes[Number(match.params.id)].classToolName : undefined}
                           onLessonDelete={onLessonPlanDelete}
                           onClassToolDelete={onClassToolDelete}
                           lessonName={episodes && episodes.length !== 0 ? episodes[Number(match.params.id)].lessonName : undefined}
                           name={episodes && episodes.length !== 0 ? episodes[Number(match.params.id)].name : undefined} />
            )
          }
        </Col>
      </Row>
      <Modal visible={lessonPlanModal} onOk={handleLessonPlanOk} onCancel={handleLessonPlanCancel}>
        <p>정말로 lessonPlan을 삭제하시겠습니까?</p>
      </Modal>
      <Modal visible={classToolModal} onOk={handleClassToolOk} onCancel={handleClassToolCancel}>
        <p>정말로 classTool을 삭제하시겠습니까?</p>
      </Modal>
    </>
  );
};

export default EpisodeFormPage;
