import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doGetStaticList } from '../store/schedule.slice';
import StaticBox from '../components/static/StaticBox';

const StaticPage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doGetStaticList());
  }, []);

  return (
    <StaticBox />
  );
};

export default StaticPage;
