import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IDashboardSchedule } from '../../models/Schedule';
import { format } from 'date-fns';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const DashboardLevelTestTable: FC = () => {
    const { dashboardScheduleList } = useSelector((root: RootState) => root.scheduleState);

    useEffect(() => {
      console.log('dashboardScheduleList: ', dashboardScheduleList);
    }, [dashboardScheduleList]);

    const columns: ColumnsType<IDashboardSchedule> = [
      {
        title: '',
        render: (value, record, index) => index + 1,
      },
      {
        title: '테스트(예정)시간',
        key: 'startDateTime',
        render: (value, record) => <Link style={LinkStyle}
                                         to={`/level-test/${record.id}`}>{format(new Date(record.startDateTime), 'yyyy.MM.dd HH:mm')}</Link>,
      },
      {
        title: '학생',
        key: 'studentId',
        render: (value, record) => <a href={`/customer/${record.userId}`} target='_blank'
                                      style={LinkStyle}>{record.studentName}({record.studentId})</a>,
      },
      {
        title: 'Tutor',
        key: 'staffId',
        render: (value, record) => <a href={`/tutor/${record.staffId}`} target='_blank'
                                      style={LinkStyle}>{record.firstName} {record.lastName}({record.staffId})</a>,
      },
      {
        title: '학생입장',
        render: (value, record) => record.studentEnterDateTime ? format(new Date(record.studentEnterDateTime), 'yyyy-MM-dd HH:mm:ss') : '-',
      },
      {
        title: '강사입장',
        render: (value, record) => record.tutorEnterDateTime ? format(new Date(record.tutorEnterDateTime), 'yyyy-MM-dd HH:mm:ss') : '-',
      },
      {
        title: '평가',
        render: (value, record) => record.isEvaluated ? 'Y' : 'N',
      },
      {
        title: '교육상담',
        render: (value, record) => record.levelTestConsultingDate ? 'Y' : 'N',
      },
      {
        title: 'Fail상담',
        render: (value, record) => record.levelTestIsFailed === 1 ? 'Y' : 'N',
      },
      {
        title: '',
        render: (value, record) => record.levelTestDeletedAt && '복원 완료',
      },
    ];

    return (
      <Table style={tableStyle} dataSource={dashboardScheduleList} columns={columns} size='small'
             rowKey={record => record.levelTestId ?? record.id}
             pagination={false} />
    );
  }
;

export default DashboardLevelTestTable;
