import React, { FC, useEffect, useState } from 'react';
import { Button, Tooltip, Select, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { divStyle } from '../../style/antdCssProperty';
import { BoardCategory, IBoardParams } from '../../models/Board';
import { getSession, setSession } from '../../libs/session';

interface IProps {
  bmId: number
  total: number;
  boardCategorieList: BoardCategory[];

  onSubmit(params: IBoardParams): void;
}

const { Option } = Select;
const { Search } = Input;

const ResourceFilterBox: FC<IProps> = ({ bmId, total, boardCategorieList, onSubmit }) => {
  const history = useHistory();

  const [categoryId, setCategoryId] = useState<number | '구분'>('구분');
  const [search, setSearch] = useState('');

  const onSearchClick = () => {
    const params: IBoardParams = {};
    if (categoryId !== '구분') {
      params.categoryId = categoryId;
    }
    if (search) {
      params.search = search;
    }
    onSubmit(params);
  };

  useEffect(() => {
    const sessionParams = getSession<IBoardParams>('resource');
    if (sessionParams) {
      if (sessionParams.categoryId) {
        setCategoryId(sessionParams.categoryId);
      } else {
        setCategoryId('구분');
      }
      if (sessionParams.search) {
        setSearch(sessionParams.search);
      } else {
        setSearch('');
      }
    }
  }, [boardCategorieList]);

  return (
    <div style={{
      ...divStyle,
      overflow: 'hidden',
    }}>
      <Select style={{ width: '10%' }} value={categoryId} onChange={(value) => setCategoryId(value)}>
        <Option value='구분'>구분</Option>
        {boardCategorieList.map((item) => (
          <Option value={item.id} key={`category-${item.id}`}>{item.name}</Option>
        ))}
      </Select>
      <Search style={{ width: '20%' }} value={search} onChange={(e) => setSearch(e.target.value)}
              onSearch={onSearchClick} />
      total: {total}
      <Button type='primary' onClick={onSearchClick} style={{ marginRight: '0.5rem' }}>검색</Button>
      <Tooltip title='write'>
        <Button type='primary' icon={<EditOutlined />} style={{
          float: 'right',
          background: '#001529',
        }} onClick={() => history.push(`/resource/${bmId}/create`)}>
        </Button>
      </Tooltip>
    </div>
  );
};

export default ResourceFilterBox;
