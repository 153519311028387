import { FC } from 'react';
import { divStyle } from '../../style/antdCssProperty';
import { RedoOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { doGetMonitoringCount } from '../../store/monitoring.slice';
import CallButton from './CallButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  & > div:first-child {
    margin-left: 0;
  }
`;

const MonitoringCountBox: FC = () => {
    const dispatch = useDispatch();

    const { monitoringCount, monitoringCallCheckList } = useSelector((root: RootState) => root.monitoringState);

    const onRefreshIconClick = () => {
      dispatch(doGetMonitoringCount());
    };

    const getIsCall = () => {
      return monitoringCallCheckList.length >= 1;
    };

    return (
      <Container style={{
        ...divStyle,
      }}>
        <div>
          <strong style={{ marginRight: '0.5rem', fontSize: 36 }}>TODAY</strong>
          <RedoOutlined style={{ fontSize: 36 }} onClick={onRefreshIconClick} />
        </div>
        <div>
          <p>예약수업건수</p>
          <strong>{monitoringCount?.reservedCount ?? 0}</strong>
        </div>
        <div>
          <p>대기중 수업수</p>
          <strong>{monitoringCount?.waitingCount ?? 0}</strong>
        </div>
        <div>
          <p>진행중 수업수</p>
          <strong>{monitoringCount?.studyingCount ?? 0}</strong>
        </div>
        <div>
          <p>정상완료 수업수</p>
          <strong>{monitoringCount?.completeCount ?? 0}</strong>
        </div>
        <div>
          <p>비정상 완료 수업수</p>
          <strong>{monitoringCount?.failCount ?? 0}</strong>
        </div>
        <div>
          <p>Issue call수</p>
          <strong>{monitoringCount?.studyIssueCount ?? 0}</strong>
        </div>
        <CallButton isCall={getIsCall()} text={`Call${getIsCall() ? `(${monitoringCallCheckList.length})` : ''}`} />
      </Container>
    );
  }
;

export default MonitoringCountBox;
