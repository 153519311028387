import React, { useRef, FC, MouseEvent, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BoardListItem } from 'models/Board';
import { Breadcrumb, Button, Modal, PageHeader, Pagination, Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { boardRemove } from '../store/board.slice';
import { localDateFormat } from '../libs/helper';
import { ColumnsType } from 'antd/lib/table';

interface IProps {
  boards: BoardListItem[];

  showModal(id: number): void;
}

const BoardList: FC<IProps> = ({ boards, showModal }) => {
  const history = useHistory();

  const onEditClick = useCallback((e: MouseEvent<HTMLSpanElement>, record: BoardListItem) => {
    e.preventDefault();
    history.push({
      pathname: `/resource/${record.boardMasterId}/update`,
      state: record.id,
    });
  }, [history]);

  const onRemoveClick = useCallback((e: MouseEvent<HTMLSpanElement>, record: BoardListItem) => {
    e.preventDefault();
    showModal(record.id);
  }, []);

  const columns: ColumnsType<BoardListItem> = [
    {
      title: 'Title',
      key: 'title',
      render: (record: BoardListItem) => {
        return <Link to={`/boards/${record.id}`}>{record.title}</Link>;
      },
    },
    {
      title: 'Category',
      key: 'category',
      render: (text: string, record: BoardListItem, index: number) => {
        return record.boardCategoryName;
      },
    },
    {
      title: 'Writer',
      key: 'name',
      render: (record: BoardListItem) => {
        return `${record.staffFirstName} ${record.staffLastName}`;
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record: string) => {
        return localDateFormat(record);
      },
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (record: BoardListItem) => (
        <EditOutlined
          onClick={(e: MouseEvent<HTMLSpanElement>) => onEditClick(e, record)}
        />
      ),
    },
    {
      title: 'Remove',
      key: 'remove',
      render: (record: BoardListItem) => (
        <DeleteOutlined
          onClick={(e: MouseEvent<HTMLSpanElement>) => onRemoveClick(e, record)}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={boards}
      columns={columns}
      size='small'
      pagination={false}
      rowKey='id' />
  );
};

export default BoardList;

