import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { IScheduleCancelLog, IScheduleCancelLogParam } from '../models/scheduleCancelLog.model';
import qs from 'qs';

export const getScheduleCancelLogList = (params?: IScheduleCancelLogParam): AxiosPromise<IScheduleCancelLog[]> => {
  let url = '/api/schedule-cancel-log';
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};
