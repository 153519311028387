import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonProps, message } from 'antd';
import { divStyle } from '../../style/antdCssProperty';
import { EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const StaffFilterBox: FC = () => {
  const history = useHistory();

  const { user } = useSelector((root: RootState) => root.authState);

  const createButtonProps: ButtonProps = {
    type: 'primary',
    icon: <EditOutlined />,
    onClick: () => {
      if (user && user.isAdmin) {
        history.push('/staff/create');
      } else {
        message.warn('관리자만 생성 가능힙니다.');
      }
    },
    style: { float: 'right' },
  };

  useEffect(() => {
    console.log('staffFilterBox: Create');
  }, []);

  return (
    <div style={{...divStyle, overflow: 'hidden'}}>
      <Button {...createButtonProps} />
    </div>
  );
};

export default StaffFilterBox;
