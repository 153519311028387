import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ColumnsType } from 'antd/lib/table';
import { IPaymentCredit } from '../../models/payment.model';
import { Button, Table } from 'antd';
import { tableStyle } from '../../style/antdCssProperty';
import { setCreditTotal } from '../../store/payment.slice';
import { paymentUserCheck } from '../../libs/helper';

interface IProps {
  onDelete(tutorDayClassCountId: number): void;
}

const CreditPaymentTable: FC<IProps> = ({ onDelete }) => {
  const dispatch = useDispatch();

  const { paymentCredit, tutorPayment } = useSelector((root: RootState) => root.paymentState);
  const { user } = useSelector((root: RootState) => root.authState);

  const columns: ColumnsType<IPaymentCredit> = [
    {
      title: 'Credit 수업일자',
      key: 'bonusDate',
      dataIndex: 'bonusDate',
    },
    {
      title: '수업 갯수',
      key: 'classCount',
      dataIndex: 'classCount',
    },
    {
      title: '금액',
      key: 'pay',
      render: () => '$2.00',
    },
    {
      title: '',
      render: (value, record) => <Button type='primary' onClick={() => onDelete(record.id)} disabled={tutorPayment?.paymentMaster.isPublished || user === null || paymentUserCheck(user.id)}>삭제</Button>,
    },
  ];

  useEffect(() => {
    dispatch(setCreditTotal({ credit: paymentCredit.length * 2 }));
  }, [dispatch, paymentCredit]);

  const summary = (pageData: IPaymentCredit[]) => (
    <Table.Summary.Row>
      <Table.Summary.Cell align='center' index={0} colSpan={2}>총 {pageData.length}건</Table.Summary.Cell>
      <Table.Summary.Cell align='center' index={1} colSpan={2}>${(pageData.length * 2).toFixed(2)}</Table.Summary.Cell>
    </Table.Summary.Row>
  );

  return (
    <Table
      style={{ ...tableStyle, marginTop: '1.5rem' }}
      dataSource={paymentCredit}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
      title={() => 'Credit Bonus'}
      summary={summary}
    />
  );
};

export default CreditPaymentTable;
