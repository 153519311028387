import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { Breadcrumb, PageHeader, Pagination } from 'antd';
import { doGetCourseList } from '../store/course.slice';
import { productList } from '../store/product.slice';
import { doGetUserTransactionList } from '../store/userTransaction.slice';
import TransactionFilterBox from '../components/Transaction/TransactionFilterBox';
import TransactionTable from '../components/Transaction/TransactionTable';
import { IUserTransactionParam } from '../models/UserTransaction';
import { getSession, setSession } from '../libs/session';
import { getTransactionDate } from '../libs/helper';

const TransactionPage: FC = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((root: RootState) => root.productState);
  const { courses } = useSelector((root: RootState) => root.courseState);
  const { userTransactions, total } = useSelector((root: RootState) => root.userTransactionState);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState<IUserTransactionParam>();

  useEffect(() => {
    dispatch(doGetCourseList());
    dispatch(productList());
  }, [dispatch]);

  useEffect(() => {
    const sessionParams = getSession<IUserTransactionParam>('transaction');
    if (sessionParams) {
      if (sessionParams.page) {
        setPage(sessionParams.page);
      }
      const [newStartDate, newEndDate] = getTransactionDate(sessionParams);
      dispatch(doGetUserTransactionList({ ...sessionParams, startDate: newStartDate, endDate: newEndDate }));
    } else {
      const data: IUserTransactionParam = {
        ...params,
        page,
      };
      setParams(data);
      dispatch(doGetUserTransactionList(data));
    }
  }, [dispatch, page]);

  useEffect(() => {
    if (params) {
      setSession('transaction', params);
    }
  }, [params]);

  const onSearch = (params?: IUserTransactionParam) => {
    if (params) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      params.page;
      setParams(params);
      const [newStartDate, newEndDate] = getTransactionDate(params);
      dispatch(doGetUserTransactionList({ ...params, startDate: newStartDate, endDate: newEndDate }));
    } else {
      dispatch(doGetUserTransactionList());
    }
  };

  const onPageChange = (page: number) => {
    const newParams: IUserTransactionParam = {
      ...params,
      page,
    };
    setSession('transaction', newParams);
    setPage(page);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>결제관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='결제관리' />
      <TransactionFilterBox products={products} courses={courses} total={total} onSearch={onSearch} />
      <TransactionTable userTransactions={userTransactions} />
      <Pagination current={page} total={total} defaultPageSize={20} onChange={onPageChange}
                  style={{ marginTop: '1.5rem' }}
                  showSizeChanger={false}
      />
    </>
  );
};

export default TransactionPage;
