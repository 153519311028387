import { FC, useEffect, useState, CSSProperties, ReactNode } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { PageHeader, Typography, BreadcrumbProps, PageHeaderProps, Button, ButtonProps, Select, Input } from 'antd';
import { LeftOutlined, RightOutlined, FileExcelOutlined } from '@ant-design/icons';
import { addDays, format, subDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { getLevelTestApplicantList } from '../../api/levelTestApplicant.api';
import { excelInit } from '../../libs/helper';
import { getSession, setSession } from '../../libs/session';
import { ILevelTestApplicantParam } from '../../models/levelTestApplicant.model';
import { divStyle } from '../../style/antdCssProperty';

import CustomDatePicker from '../common/CustomDatePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

interface IState {
  currentDate: string;
  isDelete?: string;
}

interface IProps {
  title: string;
  excelButton?: ReactNode;
  table: ReactNode;
  sessionName: string;
  isTrialClass?: boolean;
  onSubmit?: Function;
  total?: number;

  onChangeDate(startDate: string, endDate: string, isDelete?: string): void;
}

interface IExcel {
  id: number;
  reservedDateTime: string;
  userId: number;
  userName: string;
  studentId: number;
  studentName: string;
  staffId: number;
  staffFirstName: string;
  staffLastName: string;
  startDateTime: string;
  isEvaluated: string;
  consultingDate: string | null;
  isFailed: string;
}

const { Option } = Select;
const { Search } = Input;

const DashboardMain: FC<IProps> = ({
                                     title,
                                     excelButton,
                                     table,
                                     sessionName,
                                     onChangeDate,
                                     isTrialClass = false,
                                     onSubmit,
                                     total,
                                   }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isDelete, setIsDelete] = useState('all');
  const [type, setType] = useState<string>('userName');
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const { isLoading, isGetSuccess } = useSelector((root: RootState) => root.scheduleState);

  const router = useRouteMatch();

  useEffect(() => {
    if (isLoading) return;
    const sessionParams: IState = {
      currentDate: currentDate.toString(),
      isDelete,
    };
    setSession(sessionName, sessionParams);
    const sessionCurrentDate = getSession<IState>(sessionName)?.currentDate;
    const startDate = sessionCurrentDate ? new Date(sessionCurrentDate) : currentDate;
    const endDate = sessionCurrentDate ? addDays(new Date(sessionCurrentDate), 1) : addDays(currentDate, 1);
    const formatStartDate = format(startDate, 'yyyy-MM-dd');
    const formatEndDate = format(endDate, 'yyyy-MM-dd');
    onChangeDate(formatStartDate, formatEndDate, isDelete);
  }, [currentDate, isDelete, isGetSuccess]);

  const getDateString = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
  };

  useEffect(() => {
    const sessionParams = getSession<IState>(sessionName);
    if (sessionParams && sessionParams.currentDate) {
      setCurrentDate(new Date(sessionParams.currentDate));
    }
  }, []);

  useEffect(() => {
    const sessionParams = getSession<ILevelTestApplicantParam>(sessionName);
    if (sessionParams) {
      if (sessionParams.type) setType(sessionParams.type);
      if (sessionParams.search) setSearch(sessionParams.search);
      if (sessionParams.startDateTime) setStartDate(sessionParams.startDateTime);
      if (sessionParams.endDateTime) setEndDate(sessionParams.endDateTime);
    }
  }, []);

  const breadcrumb: BreadcrumbProps = {
    routes: [
      {
        path: router.url,
        breadcrumbName: title,
      },
    ],
  };

  const pageHeaderProps: PageHeaderProps = {
    title: title,
    breadcrumb: breadcrumb,
  };

  const typographyStyle: CSSProperties = {
    textAlign: 'center',
    marginBottom: '1.5rem',
  };

  const buttonProps = (onClick: () => void): ButtonProps => {
    return {
      block: false,
      style: { border: 'none' },
      size: 'large',
      onClick: onClick,
    };
  };

  const onNextDayClick = () => {
    setCurrentDate(addDays(currentDate, 1));
  };

  const onPrevDayClick = () => {
    setCurrentDate(subDays(currentDate, 1));
  };

  const iconStyle: CSSProperties = {
    fontSize: 36,
  };

  const handleDateChange = (date: Date | null, dateString: string) => {
    if (date !== null) {
      setCurrentDate(date);
    }
  };

  const onSelectChange = (value: any) => {
    setIsDelete(value);
  };

  const onTypeChange = (value: string) => {
    setType(value);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onStartDateChange = (date: any, dateString: string) => {
    setStartDate(dateString);
  };

  const onEndDateChange = (date: any, dateString: string) => {
    setEndDate(dateString);
  };

  const initData = () => {
    const params: ILevelTestApplicantParam = {};
    if (search) {
      params.type = type;
      params.search = search.trim();
    }
    if (startDate) {
      params.startDateTime = startDate;
    }
    if (endDate) {
      params.endDateTime = endDate;
    }

    return params;
  };

  const onSearchClick = () => {
    const params = initData();
    if (onSubmit) onSubmit(params);
  };

  const onExcelClick = async () => {
    const params = initData();
    params.isAll = true;
    const response = await getLevelTestApplicantList(params);

    if (response.data.length >= 1) {
      const newDataList: IExcel[] = [];
      for (const item of response.data) {
        newDataList.push({
          id: item.id,
          reservedDateTime: format(utcToZonedTime(new Date(item.reservedDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
          userId: item.student.user.id,
          userName: item.student.user.name,
          studentId: item.student.id,
          studentName: item.student.name,
          staffId: item.staff.id,
          staffFirstName: item.staff.firstName,
          staffLastName: item.staff.lastName,
          startDateTime: format(utcToZonedTime(new Date(item.startDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
          isEvaluated: item.isEvaluated ? 'Y' : 'N',
          consultingDate: item.levelTest.consultingDate || 'N',
          isFailed: item.levelTest.isFailed ? 'Y' : 'N',
        });
      }
      await excelInit('levelTest', 'levelTest', newDataList);
    }
  };

  return (
    <>
      {
        sessionName === 'levelTestApplicants'
          ? (
            <div style={divStyle}>
              <Select
                style={{ width: '10%' }}
                value={type}
                onChange={onTypeChange}
              >
                <Option value='userName'>고객이름</Option>
                <Option value='studentName'>학생이름</Option>
                <Option value='email'>이메일</Option>
                <Option value='phone'>전화번호</Option>
              </Select>
              <Search
                style={{ width: '20%' }}
                enterButton='검색'
                value={search}
                onChange={onSearchChange}
                onSearch={onSearchClick}
              />
              신청일
              <CustomDatePicker
                value={startDate ? new Date(startDate) : undefined}
                onChange={onStartDateChange}
              />
              ~
              <CustomDatePicker
                value={endDate ? new Date(endDate) : undefined}
                onChange={onEndDateChange}
              />
              <Button
                type='primary'
                onClick={onSearchClick}
                style={{ marginRight: '0.5rem' }}
              >
                검색
              </Button>
              total: {total}
              <Button
                icon={<FileExcelOutlined />}
                onClick={onExcelClick}
                style={{ marginLeft: '0.5rem', float: 'right' }}
              />
            </div>
          )
          : (
            <>
              <PageHeader {...pageHeaderProps} />
              <Typography style={typographyStyle}>
                {isTrialClass &&
                  <Select style={{ float: 'left', width: '10%' }} value={isDelete} onChange={onSelectChange}>
                    <Option value='all'>전체</Option>
                    <Option value='y'>복원완료</Option>
                  </Select>
                }
                <Button {...buttonProps(onPrevDayClick)} icon={<LeftOutlined style={iconStyle} />} />
                <CustomDatePicker onChange={handleDateChange} style={{ height: 36 }} value={currentDate} />
                <Button {...buttonProps(onNextDayClick)} icon={<RightOutlined style={iconStyle} />} />
                {excelButton}
              </Typography>
            </>
          )
      }
      {table}
    </>
  );
};

export default DashboardMain;
