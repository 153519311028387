import React, { FC, useState } from 'react';
import { Modal, Radio, List } from 'antd';
import { ICourse } from '../../models/Course';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

interface IProps {
  visible: boolean;
  courseList: ICourse[];

  onOk(courseId: number): void;

  onCancel(): void;
}

const CustomerCourseChangeModal: FC<IProps> = ({ visible, courseList, onOk, onCancel }) => {
  const [courseId, setCourseId] = useState(0);

  const onRadioChange = (e: RadioChangeEvent) => {
    setCourseId(e.target.value);
  };

  const onCourseClick = (courseId: number) => {
    setCourseId(courseId);
  };

  const handleOk = () => {
    onOk(courseId);
  }

  return (
    <Modal title='수업 리스트' visible={visible} onOk={handleOk} onCancel={onCancel}>
      <Radio.Group onChange={onRadioChange} style={{ width: '100%' }} value={courseId}>
        <List style={{ width: '100%', textAlign: 'left' }} bordered dataSource={courseList} renderItem={item => (
          <List.Item style={{ display: 'block' }}>
            <Radio value={item.id} />
            <p style={{ display: 'inline-block', paddingLeft: 0, cursor: 'pointer' }}
               onClick={() => onCourseClick(item.id)}>[{item.shortName}] {item.name}</p>
          </List.Item>
        )} />
      </Radio.Group>
    </Modal>
  );
};

export default CustomerCourseChangeModal;
