import { IGetMessageLogListSuccess, IMessageLog, IMessageLogParam } from '../models/messageLog.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getMessageLogList } from '../api/messageLog.api';

interface IMessageLogState {
  messageLogList: IMessageLog[];
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IMessageLogState = {
  messageLogList: [],
  total: 0,
  isLoading: false,
};

const messageLogSlice = createSlice({
  name: 'messageLog',
  initialState,
  reducers: {
    getMessageLogListStart: (state: IMessageLogState) => {
      state.messageLogList = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getMessageLogListSuccess: (state: IMessageLogState, action: PayloadAction<IGetMessageLogListSuccess>) => {
      state.messageLogList = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getMessageLogListFailure: (state: IMessageLogState, action: PayloadAction<AxiosError>) => {
      state.messageLogList = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getMessageLogListStart,
  getMessageLogListSuccess,
  getMessageLogListFailure,
} = messageLogSlice.actions;
export default messageLogSlice.reducer;

export const doGetMessageLogList = (param: IMessageLogParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getMessageLogListStart());
    const response = await getMessageLogList(param);
    const success: IGetMessageLogListSuccess = {
      total: +response.headers['total'],
      data: response.data,
    };
    dispatch(getMessageLogListSuccess(success));
  } catch (e) {
    dispatch(getMessageLogListFailure(e));
  }
};
