import { TutorScheduleMasterResponse } from '../models/TutorScheduleMaster';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { list } from '../api/tutorScheduleMaster';

interface ITutorScheduleMasterState {
  tutorScheduleMasters: TutorScheduleMasterResponse[] | null;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: ITutorScheduleMasterState = {
  tutorScheduleMasters: null,
  isLoading: false,
  error: null,
};

const tutorScheduleMasterSlicer = createSlice({
  name: 'tutorScheduleMaster',
  initialState,
  reducers: {
    requestStart: (state: ITutorScheduleMasterState) => {
      state.error = null;
      state.isLoading = true;
    },
    requestListSuccess: (state: ITutorScheduleMasterState, action: PayloadAction<TutorScheduleMasterResponse[]>) => {
      state.tutorScheduleMasters = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestFailure: (state: ITutorScheduleMasterState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestFailure,
} = tutorScheduleMasterSlicer.actions;

export default tutorScheduleMasterSlicer.reducer;

export const tutorScheduleMasterList = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(id);
    dispatch(requestListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};