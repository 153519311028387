import React, { FC, useEffect, useState } from 'react';
import { divStyle } from '../../style/antdCssProperty';
import { Select, Input, Button } from 'antd';
import { CancelBy } from '../../models/Enum';
import CustomDatePicker from '../common/CustomDatePicker';
import { IScheduleCancelLogParam } from '../../models/scheduleCancelLog.model';
import { getSession } from '../../libs/session';
import { addDays, format } from 'date-fns';

interface IProps {
  total: number;

  onSubmit(params: IScheduleCancelLogParam, isUpdate: boolean): void;
}

const { Option } = Select;
const { Search } = Input;

const ScheduleCancelLogFilterBox: FC<IProps> = ({ total, onSubmit }) => {
  const [cancelBy, setCancelBy] = useState<CancelBy | '구분'>('구분');
  const [type, setType] = useState('검색');
  const [search, setSearch] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const sessionParams = getSession<IScheduleCancelLogParam>('scheduleCancelLog');
    if (sessionParams) {
      if (sessionParams.cancelBy) {
        setCancelBy(sessionParams.cancelBy);
      }
      if (sessionParams.type) {
        setType(sessionParams.type);
      }
      if (sessionParams.search) {
        setSearch(sessionParams.search);
      }
      if (sessionParams.startDateTime) {
        setStartDateTime(sessionParams.startDateTime);
      }
      if (sessionParams.endDateTime) {
        setEndDateTime(sessionParams.endDateTime);
      }
    }
  }, []);

  const onSearch = () => {
    const data: IScheduleCancelLogParam = {};
    if (cancelBy !== '구분') {
      data.cancelBy = cancelBy;
    }
    if (type !== '검색') {
      data.type = type;
    }
    if (search) {
      data.search = search.trim();
    }
    if (startDateTime) {
      data.startDateTime = startDateTime;
    }
    if (endDateTime) {
      data.endDateTime = format(addDays(new Date(endDateTime), 1), 'yyyy-MM-dd');
    }
    onSubmit(data, isUpdate);
    setIsUpdate(false);
  };

  const onCancelByChange = (value: CancelBy | '구분') => {
    setIsUpdate(true);
    setCancelBy(value);
  };

  const onTypeChange = (value: string) => {
    setIsUpdate(true);
    setType(value);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdate(true);
    setSearch(e.target.value);
  };

  const onStartDateTimeChange = (date: Date | null, dateString: string) => {
    setIsUpdate(true);
    setStartDateTime(dateString);
  };

  const onEndDateTimeChange = (date: Date | null, dateString: string) => {
    setIsUpdate(true);
    setEndDateTime(dateString);
  };

  const getStartDateTime = () => {
    return startDateTime ? new Date(startDateTime) : undefined;
  };

  const getEndDateTime = () => {
    return endDateTime ? new Date(endDateTime) : undefined;
  };

  return (
    <div style={divStyle}>
      <Select style={{ width: '10%', marginRight: '0.5rem' }} value={cancelBy} onChange={onCancelByChange}>
        <Option value='구분'>구분</Option>
        <Option value={CancelBy.STUDENT}>학생취소</Option>
        <Option value={CancelBy.STAFF}>관리자취소</Option>
        <Option value={CancelBy.TUTOR}>강사취소</Option>
      </Select>
      <Select style={{ width: '10%', marginRight: '0.5rem' }} value={type} onChange={onTypeChange}>
        <Option value='검색'>검색</Option>
        <Option value='studentName'>학생이름</Option>
        <Option value='tutorName'>강사이름</Option>
      </Select>
      <Search style={{ width: '20%', marginRight: '0.5rem' }} enterButton='검색' value={search} onChange={onSearchChange}
              onSearch={onSearch} />
      <CustomDatePicker value={getStartDateTime()}
                        onChange={onStartDateTimeChange} placeholder='취소일' style={{ marginRight: '0.5rem' }} />
      ~
      <CustomDatePicker value={getEndDateTime()}
                        onChange={onEndDateTimeChange} placeholder='취소일' style={{ marginRight: '0.5rem',marginLeft:'0.5rem' }} />
      total: {total}
      <Button type='primary' onClick={onSearch} style={{ marginLeft: '0.5rem', float: 'right' }}>검색</Button>
    </div>
  );
};

export default ScheduleCancelLogFilterBox;
