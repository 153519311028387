export function getSession<T>(name: string): (T)|undefined {
  const sessionParams = sessionStorage.getItem(`${name}Params`);
  if (sessionParams) {
    return JSON.parse(sessionParams);
  }
  return undefined;
}

export function setSession(name: string, data: Object) {
  sessionStorage.setItem(`${name}Params`, JSON.stringify(data));
}

export function removeSession(name: string) {
  sessionStorage.removeItem(`${name}Params`);
}
