import { IContract } from '../models/Contract';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getContract, getContractList, patchContract, postContract } from '../api/contract.api';

interface IContractState {
  contractList: IContract[];
  contract?: IContract;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IContractState = {
  contractList: [], error: undefined, isLoading: false,
};

const contractSlicer = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    getContractListStart: (state: IContractState) => {
      state.contractList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getContractListSuccess: (state: IContractState, action: PayloadAction<IContract[]>) => {
      state.contractList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getContractListFailure: (state: IContractState, action: PayloadAction<AxiosError>) => {
      state.contractList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getContractStart: (state: IContractState) => {
      state.contract = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getContractSuccess: (state: IContractState, action: PayloadAction<IContract>) => {
      state.contract = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getContractFailure: (state: IContractState, action: PayloadAction<AxiosError>) => {
      state.contract = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    postContractStart: (state: IContractState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    postContractSuccess: (state: IContractState, action: PayloadAction<IContract>) => {
      state.contractList = state.contractList.concat(action.payload);
      state.isLoading = false;
      state.error = undefined;
    },
    postContractFailure: (state: IContractState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    patchContractStart: (state: IContractState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchContractSuccess: (state: IContractState, action: PayloadAction<IContract>) => {
      state.contractList = state.contractList.map((item) => {
        if (item.id === action.payload.id) {
          item = action.payload;
        }
        return item;
      });
      state.contract = undefined;
      state.isLoading = false;
      state.error = undefined;
    },
    patchContractFailure: (state: IContractState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getContractListStart,
  getContractListSuccess,
  getContractListFailure,
  getContractStart,
  getContractSuccess,
  getContractFailure,
  postContractStart,
  postContractSuccess,
  postContractFailure,
  patchContractStart,
  patchContractSuccess,
  patchContractFailure,
} = contractSlicer.actions;
export default contractSlicer.reducer;

export const doGetContractList = (staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getContractListStart());
    const response = await getContractList(staffId);
    dispatch(getContractListSuccess(response.data));
  } catch (e) {
    dispatch(getContractListFailure(e));
  }
};

export const doGetContract = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getContractStart());
    const response = await getContract(id);
    dispatch(getContractSuccess(response.data));
  } catch (e) {
    dispatch(getContractFailure(e));
  }
};

export const doPostContract = (data: FormData): AppThunk => async (dispatch) => {
  try {
    dispatch(postContractStart());
    const response = await postContract(data);
    dispatch(postContractSuccess(response.data));
  } catch (e) {
    dispatch(postContractFailure(e));
  }
};

export const doPatchContract = (id: number, data: FormData): AppThunk => async (dispatch) => {
  try {
    dispatch(patchContractStart());
    const response = await patchContract(id, data);
    dispatch(patchContractSuccess(response.data));
  } catch (e) {
    dispatch(patchContractFailure(e));
  }
};
