import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EpisodePageItem, EpisodePageUpdate } from '../models/EpisodePage';
import { AppThunk } from './index';
import { update, remove,list } from '../api/episodePage';
import { messageAdd, showMessage } from './message.slice';

interface IEpisodePageState {
  episodePages: EpisodePageItem[] | null;
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IEpisodePageState = {
  episodePages: null,
  isLoading: false,
  error: null,
};

const episodePageSlicer = createSlice({
  name: 'episodePage',
  initialState,
  reducers: {
    requestStart: (state: IEpisodePageState) => {
      state.episodePages = null;
      state.error = null;
      state.isLoading = true;
    },
    requestListSuccess: (state: IEpisodePageState,action: PayloadAction<EpisodePageItem[]>) => {
      state.episodePages = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    requestNotResponseSuccess: (state: IEpisodePageState) => {
      state.error = null;
      state.isLoading = false;
    },
    requestFailure: (state: IEpisodePageState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestNotResponseSuccess,
  requestFailure,
} = episodePageSlicer.actions;

export default episodePageSlicer.reducer;

export const episodePageUpdate = (data: EpisodePageUpdate[]): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await update(data);
    dispatch(messageAdd(showMessage('success', '에피소드들을 성공적으로 저장하였습니다.')));
    dispatch(requestNotResponseSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const episodePageRemove = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    await remove(id);
    dispatch(requestNotResponseSuccess());
  } catch (e) {
    dispatch(requestFailure(e));
  }
};

export const episodePageList = (episodeId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(episodeId);
    dispatch(requestListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};
