import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Pagination } from 'antd';
import { boardList } from 'store/board.slice';
import { RootState } from 'store/rootReducer';
import BoardList from 'components/BoardList';
import Loader from '../components/Loader';

const MainPage: FC = () => {
  const dispatch = useDispatch();
  const { isLoading, boards, total } = useSelector((state: RootState) => state.boardState);
  const [current, setCurrent] = useState(1);

  const onChange = (page: number) => {
    dispatch(boardList({
      page,
      boardMasterId: 12,
    }));
    setCurrent(page);
  };

  useEffect(() => {
    dispatch(boardList({ boardMasterId: 12 }));
  }, [dispatch]);


  if (isLoading || !boards) {
    return <Loader />;
  } else {
    return (
      <>
        <Row gutter={10}>
          <Col span={24}>
            <BoardList boards={boards} title='공지관리' />
          </Col>
        </Row>
        <Pagination
          current={current}
          total={total}
          defaultPageSize={20}
          onChange={onChange}
          style={{ marginTop: '1.5rem' }}
        />
      </>
    );
  }
};

export default MainPage;
