import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { divStyle } from '../../style/antdCssProperty';
import { Select, Input, message, Button } from 'antd';
import { Status, Steps } from '../../models/Enum';
import { StaffListQuery } from '../../models/Staff';
import { FileExcelOutlined } from '@ant-design/icons';
import { list } from '../../api/staff';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { excelInit } from '../../libs/helper';
import { getSession } from '../../libs/session';

interface IProps {
  total: number;

  onSubmit(data: StaffListQuery): void;
}

interface IExcelData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  countryName: string;
  applicantStatus: string;
  applicantSteps: string;
}

const { Option } = Select;
const { Search } = Input;

const SurveyFilterBox: FC<IProps> = ({ total, onSubmit }) => {
  const [steps, setSteps] = useState<Steps | 'STEP'>('STEP');
  const [status, setStatus] = useState<Status | 'Status'>('Status');
  const [type, setType] = useState<'name' | 'email' | '선택'>('선택');
  const [search, setSearch] = useState('');

  useEffect(() => {
    const sessionParams = getSession<StaffListQuery>('survey');
    if (sessionParams) {
      if (sessionParams.status) {
        setStatus(sessionParams.status);
      }
      if (sessionParams.steps) {
        setSteps(sessionParams.steps);
      }
      if (sessionParams.type) {
        setType(sessionParams.type);
      }
      if (sessionParams.search) {
        setSearch(sessionParams.search);
      }
    }
  }, []);

  const onStepsChange = (value: Steps | 'STEP') => {
    setSteps(value);
  };

  const onStatusChange = (value: Status | 'Status') => {
    setStatus(value);
  };

  const onTypeChange = (value: 'name' | 'email' | '선택') => {
    setType(value);
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onDataInit = () => {
    if (search && type === '선택') {
      message.warn('타입을 골라주세요.');
      return;
    }
    if (type !== '선택' && !search) {
      message.warn('찾을 값을 입력해주세요.');
      return;
    }
    const data: StaffListQuery = {};
    if (search) {
      data.search = search.trim();
    }
    if (steps !== 'STEP') {
      data.steps = steps;
    }
    if (status !== 'Status') {
      data.status = status;
    }
    if (type !== '선택') {
      data.type = type;
    }
    return data;
  };

  const onSearch = (value: string, event?: (React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement> | undefined)) => {
    event?.preventDefault();
    const data = onDataInit();
    if (data) {
      onSubmit(data);
    }
  };

  const onClick = async () => {
    const data = onDataInit();
    if (data) {
      data.all = true;

      const response = await list(data);
      if (response.data.length >= 1) {
        const excelData: IExcelData[] = [];
        for (const item of response.data) {
          excelData.push({
            id: item.id,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            createdAt: format(utcToZonedTime(new Date(item.createdAt), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            countryName: item.country.name,
            applicantStatus: item.applicant.status,
            applicantSteps: item.applicant.steps,
          });
        }
        await excelInit('applicant', 'applicant', excelData);
      }
    }
  };

  return (
    <div style={divStyle}>
      <Select style={{ width: '15%' }} value={steps} onChange={onStepsChange}>
        <Option value={Steps.SURVEY}>{Steps.SURVEY}</Option>
        <Option value={Steps.MOCK}>{Steps.MOCK}</Option>
        <Option value={Steps.MOCK_RETRY}>{Steps.MOCK_RETRY}</Option>
        <Option value={Steps.DOCUMENT}>{Steps.DOCUMENT}</Option>
        <Option value={Steps.CONTRACT}>{Steps.CONTRACT}</Option>
      </Select>
      <Select style={{ width: '15%' }} value={status} onChange={onStatusChange}>
        <Option value='Status'>Status</Option>
        <Option value={Status.APPLYING}>{Status.APPLYING}</Option>
        <Option value={Status.FAIL}>{Status.FAIL}</Option>
        <Option value={Status.SUBMIT}>{Status.SUBMIT}</Option>
      </Select>
      <Select style={{ width: '15%' }} value={type} onChange={onTypeChange}>
        <Option value='name'>이름</Option>
        <Option value='email'>이메일</Option>
      </Select>
      <Search
        style={{ width: '30%', marginRight: '0.5rem' }}
        enterButton='Search'
        value={search}
        onChange={onSearchChange}
        onSearch={onSearch}
      />
      total: {total}
      <Button icon={<FileExcelOutlined />} onClick={onClick} style={{ marginLeft: '0.5rem', float: 'right' }} />
    </div>
  );
};

export default SurveyFilterBox;
