import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Col, PageHeader, Pagination, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import StaffList from '../components/StaffList';
import { staffList } from '../store/staff.slice';
import { StaffListQuery } from '../models/Staff';
import SurveyFilterBox from '../components/Survey/SurveyFilterBox';
import { getSession, setSession } from '../libs/session';

const SurveyListPage: FC = () => {
  const dispatch = useDispatch();
  const { staffs, total } = useSelector((state: RootState) => state.staffState);
  const [current, setCurrent] = useState(1);
  const [param, setParam] = useState<StaffListQuery>();

  useEffect(() => {
    const sessionParams = getSession<StaffListQuery>('survey');
    if (sessionParams) {
      if (sessionParams.page) {
        setCurrent(sessionParams.page);
      }
      dispatch(staffList(sessionParams));
    } else {
      const data: StaffListQuery = {
        ...param,
        page: current,
      };
      setParam(data);
      dispatch(staffList(data));
    }
  }, [dispatch, current]);

  useEffect(() => {
    if (param) {
      setSession('survey', param);
    }
  }, [param]);

  const onChange = useCallback((page: number) => {
    const params: StaffListQuery = {
      ...param,
      page,
    };
    setSession('survey', params);
    setCurrent(page);
  }, []);

  const onSubmit = (data: StaffListQuery) => {
    data.page = current;
    setParam(data);
    dispatch(staffList(data));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>채용관리</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='채용관리'>
      </PageHeader>
      <SurveyFilterBox total={total} onSubmit={onSubmit} />
      <Row gutter={10}>
        <Col span={24}>
          <StaffList staffs={staffs ? staffs : []} />
        </Col>
      </Row>
      <Pagination
        current={current}
        onChange={onChange}
        total={total}
        defaultPageSize={20}
        style={{ marginTop: '1.5rem' }}
        showSizeChanger={false}
      />
    </>
  );
};

export default SurveyListPage;
