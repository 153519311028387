import { ICalender, ICalenderParam } from '../models/calender.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getCalenderList } from '../api/calender.api';

interface ICalenderState {
  calenders: ICalender[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: ICalenderState = {
  calenders: [], isLoading: false,
};

const calenderSlice = createSlice({
  name: 'calender',
  initialState,
  reducers: {
    getCalenderListStart: (state: ICalenderState) => {
      state.calenders = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getCalenderListSuccess: (state: ICalenderState, action: PayloadAction<ICalender[]>) => {
      state.calenders = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getCalenderListFailure: (state: ICalenderState, action: PayloadAction<AxiosError>) => {
      state.calenders = [];
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getCalenderListStart,
  getCalenderListSuccess,
  getCalenderListFailure
} = calenderSlice.actions;
export default calenderSlice.reducer;

export const doGetCalenderList = (params?: ICalenderParam):AppThunk=> async (dispatch)=>{
  try {
    dispatch(getCalenderListStart());
    const response = await getCalenderList(params);
    dispatch(getCalenderListSuccess(response.data));
  } catch (e) {
    dispatch(getCalenderListFailure(e));
  }
}
