import { FC, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, PageHeader, message } from 'antd';
import StaffForm, { IFormItem } from '../components/Staff/StaffForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { IStaffRegister } from '../models/Staff';
import { doPostStaff, initIsCreated } from '../store/staff.slice';

const StaffCreatePage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, isCreated } = useSelector((root: RootState) => root.staffState);

  useEffect(() => {
    if (error && error.response) {
      switch (error.response.status) {
        case 409:
          message.warn('이메일이 중복되었습니다. 다른 이메일을 입력하세요.');
          break;
        case 403:
          message.warn('관리자만 생성 가능힙니다.');
          break;
      }
    }
  }, [error]);

  useEffect(() => {
    if (isCreated) {
      history.push('/staff');
      dispatch(initIsCreated());
    }
  }, [isCreated]);

  function onFinish(values: IFormItem) {
    const body: IStaffRegister = { ...values };
    dispatch(doPostStaff(body));
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to='/staff'>스태프 관리</Link></Breadcrumb.Item>
        <Breadcrumb.Item>스태프 생성</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title='스태프 관리' />
      <StaffForm onFinish={onFinish} />
    </>
  );
};

export default StaffCreatePage;
