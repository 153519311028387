import { FC, useState } from 'react';
import DashboardMain from '../components/dashboard/DashboardMain';
import DashboardLevelTestTable from '../components/dashboard/DashboardLevelTestTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { IDashboardScheduleParam } from '../models/Schedule';
import { doDashboardScheduleList } from '../store/schedule.slice';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { excelInit, getMonthDay } from '../libs/helper';
import { addHours, format } from 'date-fns';
import { dashboardScheduleList as dashboardScheduleListAPI } from '../api/schedule';
import { utcToZonedTime } from 'date-fns-tz';
import CustomDatePicker from '../components/common/CustomDatePicker';
import { FailReason, ScheduleStatus } from '../models/Enum';

interface IExcel {
  startDateTime: string;
  isTrialClass: boolean;
  studentId: number;
  userId: number;
  studentName: string;
  email: string;
  phone: string;
  staffId: number;
  firstName: string;
  lastName: string;
  courseName: string;
  episodeName: string;
  studentEnterDateTime: string;
  tutorEnterDateTime: string;
  isEvaluated: boolean;
  status: ScheduleStatus;
  EduCounselingDate: string;
  EduCounseling: string;
  FailCounselingDate: string;
  FailType: FailReason | string;
  FailCounseling: string;
}

const LevelTestBoardPage: FC = () => {
  const dispatch = useDispatch();
  const [month, setMonth] = useState('');

  const { dashboardScheduleList } = useSelector((root: RootState) => root.scheduleState);

  const onChangeDate = (startDate: string, endDate: string, isDelete?: string) => {
    const params: IDashboardScheduleParam = {
      endDateTime: endDate,
      startDateTime: startDate,
      isTrialClass: 'y',
      isDelete,
    };
    dispatch(doDashboardScheduleList(params));
  };

  const onExcelClick = async () => {
    if (month) {
      const { firstDay, lastDay } = getMonthDay(month);
      const formatStartDate = format(firstDay, 'yyyy-MM-dd');
      const formatEndDate = format(addHours(lastDay, 48), 'yyyy-MM-dd');
      const params: IDashboardScheduleParam = {
        isTrialClass: 'y',
        endDateTime: formatEndDate,
        startDateTime: formatStartDate,
      };
      const response = await dashboardScheduleListAPI(params);
      if (response.data.length >= 1) {
        const newDataList: IExcel[] = [];
        for (const item of response.data) {
          newDataList.push({
            startDateTime: format(utcToZonedTime(new Date(item.startDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            isTrialClass: item.isTrialClass === 1,
            studentId: item.studentId,
            userId: item.userId,
            studentName: item.studentName,
            email: item.userEmail,
            phone: item.userPhone,
            staffId: item.staffId,
            firstName: item.firstName,
            lastName: item.lastName,
            courseName: item.courseName,
            episodeName: item.episodeName,
            studentEnterDateTime: item.studentEnterDateTime ? format(utcToZonedTime(new Date(item.studentEnterDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            tutorEnterDateTime: item.tutorEnterDateTime ? format(utcToZonedTime(new Date(item.tutorEnterDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            isEvaluated: item.isEvaluated === 1,
            status: item.status,
            EduCounselingDate: item?.levelTestConsultingDate ? format(utcToZonedTime(new Date(item.levelTestConsultingDate), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            EduCounseling: item?.levelTestConsultingNotes ?? '',
            FailCounselingDate: item?.levelTestFailDateTime ? format(utcToZonedTime(new Date(item.levelTestFailDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            FailType: item?.levelTestFailReason ?? '',
            FailCounseling: item?.levelTestFailReasonDetail ?? '',
          });
        }
        await excelInit('levelTest', 'levelTest', newDataList);
      }
    } else {
      if (dashboardScheduleList.length >= 1) {
        const newDataList: IExcel[] = [];
        for (const item of dashboardScheduleList) {
          newDataList.push({
            startDateTime: format(utcToZonedTime(new Date(item.startDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss'),
            isTrialClass: item.isTrialClass === 1,
            studentId: item.studentId,
            userId: item.userId,
            studentName: item.studentName,
            email: item.userEmail,
            phone: item.userPhone,
            staffId: item.staffId,
            firstName: item.firstName,
            lastName: item.lastName,
            courseName: item.courseName,
            episodeName: item.episodeName,
            studentEnterDateTime: item.studentEnterDateTime ? format(utcToZonedTime(new Date(item.studentEnterDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            tutorEnterDateTime: item.tutorEnterDateTime ? format(utcToZonedTime(new Date(item.tutorEnterDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            isEvaluated: item.isEvaluated === 1,
            status: item.status,
            EduCounselingDate: item?.levelTestConsultingDate ? format(utcToZonedTime(new Date(item.levelTestConsultingDate), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            EduCounseling: item?.levelTestConsultingNotes ?? '',
            FailCounselingDate: item?.levelTestFailDateTime ? format(utcToZonedTime(new Date(item.levelTestFailDateTime), 'Asia/Seoul'), 'yyyy-MM-dd HH:mm:ss') : '',
            FailType: item?.levelTestFailReason ?? '',
            FailCounseling: item?.levelTestFailReasonDetail ?? '',
          });
        }
        await excelInit('levelTest', 'levelTest', newDataList);
      }
    }
    return;
  };

  const onMonthChange = (value: Date | null, dateString: string) => {
    setMonth(dateString);
  };

  return (
    <DashboardMain title='Trial Class' table={<DashboardLevelTestTable />}
                   sessionName='TrialClass' onChangeDate={onChangeDate} excelButton={<>
      <Button icon={<FileExcelOutlined style={{ fontSize: 36 }} />} block={false} size='large'
              style={{ border: 'none', float: 'right' }} onClick={onExcelClick} />
      <CustomDatePicker onChange={onMonthChange} picker='month' style={{ float: 'right', marginTop: 7.5 }} />
    </>} isTrialClass />
  );
};

export default LevelTestBoardPage;
