import React from 'react';

import { useDispatch } from 'react-redux';
import axios from 'axios';
import { BrowserRouter } from 'react-router-dom';
import RootRouter from 'routes';
import { authCheck } from 'store/auth.slice';
import 'antd/dist/antd.less';
import './style/egg-htt-lms-theme.less';
import Message from './components/Message';

function App() {
  axios.defaults.withCredentials = true;
  const dispatch = useDispatch();

  dispatch(authCheck());

  return (
    <BrowserRouter>
      <RootRouter />
      <Message />
    </BrowserRouter>
  );
}

export default App;
