import { StudyRoomVideo } from '../models/studyRoomVideo';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { list } from '../api/studyRoomVideo';

interface IStudyRoomVideoState {
  studyRoomVideoList: StudyRoomVideo[];
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IStudyRoomVideoState = {
  studyRoomVideoList: [],
  isLoading: false,
};

const studyRoomVideoSlicer = createSlice({
  name: 'studyRoomVideo',
  initialState,
  reducers: {
    requestStart: (state: IStudyRoomVideoState) => {
      state.isLoading = true;
    },
    requestListSuccess: (state: IStudyRoomVideoState, action: PayloadAction<StudyRoomVideo[]>) => {
      state.studyRoomVideoList = action.payload;
      state.isLoading = false;
    },
    requestFailure: (state: IStudyRoomVideoState, action: PayloadAction<AxiosError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  requestStart,
  requestListSuccess,
  requestFailure,
} = studyRoomVideoSlicer.actions;

export default studyRoomVideoSlicer.reducer;

export const studyRoomVideoList = (staffId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());
    const response = await list(staffId);
    dispatch(requestListSuccess(response.data));
  } catch (e) {
    dispatch(requestFailure(e));
  }
};
