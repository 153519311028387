import React, { FC, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import BoardEditor from '../components/Board/BoardEditor';
import { Breadcrumb, PageHeader } from 'antd';

interface IMatch {
  id: string;
}

const ResourceWritePage: FC = () => {
  const match = useRouteMatch<IMatch>();

  useEffect(() => {
    console.log(Number(match.params.id));
  }, [match.params.id]);


  const headerTitle = (id: number) => {
    switch (id) {
      case 1:
        return 'freeBoard';
      case 2:
        return 'noticeBoard';
      case 3:
        return 'Contents Introduction';
      case 4:
        return 'Contents Video';
      case 5:
        return 'Guide Video';
      case 6:
        return 'Class Material';
      case 7:
        return 'General Preparation';
      case 8:
        return 'Student Board';
      case 9:
        return 'Tutor Board';
      default:
        return 'board Write';
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item><Link to='/dashBoard'>DashBoard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{headerTitle(Number(match.params.id))}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader title={headerTitle(Number(match.params.id))} />
      <BoardEditor editor='create' bmId={Number(match.params.id)} />
    </>
  );
};

export default ResourceWritePage;
