import { FC } from 'react';
import { Form, FormItemProps, FormProps, Select, InputNumber, Button } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import CustomDatePicker from '../common/CustomDatePicker';

export interface IValues {
  courseId: number;
  startDate: Date;
  endDate: Date;
  totalClassCount: number;
}

interface IProps {
  onFinish(values: IValues): void;
}

const { Option } = Select;

const CustomerTrialForm: FC<IProps> = ({ onFinish }) => {
  const [form] = Form.useForm();

  const { courses } = useSelector((root: RootState) => root.courseState);

  const formProps: FormProps = {
    wrapperCol: { span: 16 },
    onFinish: (values:IValues) => {
      onFinish(values);
      form.resetFields();
    },
    form,
  };

  const tailLayout: FormItemProps = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const courseFormItemProps: FormItemProps = {
    label: '과정',
    name: 'courseId',
    rules: [{ required: true, message: '과정을 선택해주세요.' }],
  };

  const startDateItemProps: FormItemProps = {
    label: '시작일',
    name: 'startDate',
    rules: [{ required: true, message: '시작일을 선택해주세요.' }],
  };

  const endDateItemProps: FormItemProps = {
    label: '종료일',
    name: 'endDate',
    rules: [{ required: true, message: '종료일을 선택해주세요.' }],
  };

  const totalClassCountItemProps: FormItemProps = {
    label: '수업갯수',
    name: 'totalClassCount',
    rules: [{ required: true, message: '수업갯수를 작성해주세요.' }],
  };

  return (
    <Form {...formProps}>
      <Form.Item {...courseFormItemProps}>
        <Select>
          {courses.map((item) => <Option value={item.id}>{item.name}</Option>)}
        </Select>
      </Form.Item>
      <Form.Item {...startDateItemProps}>
        <CustomDatePicker />
      </Form.Item>
      <Form.Item {...endDateItemProps}>
        <CustomDatePicker />
      </Form.Item>
      <Form.Item {...totalClassCountItemProps}>
        <InputNumber />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>제출</Button>
      </Form.Item>
    </Form>
  );
};

export default CustomerTrialForm;
