import axios from './axiosConfig';
import {
  IExcel,
  IPaymentCredit,
  IPaymentMaster,
  IPaymentMasterCreate,
  IPaymentMasterRead,
  IPaymentParam, IPaymentSchedule, ITutorExtraPayment, ITutorExtraPaymentCreate,
  ITutorPayment, ITutorPaymentRead,
} from '../models/payment.model';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const postPayment = (body: IPaymentMasterCreate): AxiosPromise<IPaymentMaster> => {
  const url = '/api/payment';
  return axios.post(url, body);
};

export const getPaymentList = (params?: IPaymentParam): AxiosPromise<IPaymentMaster[]> => {
  let url = '/api/payment';
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const getPayment = (id: number): AxiosPromise<IPaymentMasterRead> => {
  const url = `/api/payment/${id}`;
  return axios.get(url);
};

export const getTutorPaymentList = (id: number): AxiosPromise<ITutorPayment[]> => {
  const url = `/api/payment/${id}/tutor`;
  return axios.get(url);
};

export const getTutorPayment = (paymentMasterId: number, staffId: number): AxiosPromise<ITutorPaymentRead> => {
  const url = `/api/payment/${paymentMasterId}/tutor/${staffId}`;
  return axios.get(url);
};

export const getTutorPaymentScheduleList = (paymentMasterId: number, staffId: number): AxiosPromise<IPaymentSchedule[]> => {
  const url = `/api/payment/${paymentMasterId}/tutor/${staffId}/schedule`;
  return axios.get(url);
};

export const getTutorPaymentCreditList = (paymentMasterId: number, staffId: number): AxiosPromise<IPaymentCredit[]> => {
  const url = `/api/payment/${paymentMasterId}/tutor/${staffId}/credit`;
  return axios.get(url);
};

export const getTierBonus = (tier: number, total: number) => {
  const url = `/api/payment/tier-bonus?tier=${tier}&total=${total}`;
  return axios.get(url);
};

export const deletePaymentInit = (id: number): AxiosPromise<ITutorPayment[]> => {
  const url = `/api/payment/${id}/init`;
  return axios.post(url);
};

export const deleteStaffPaymentInit = (id: number, staffId: number): AxiosPromise<ITutorPayment[]> => {
  const url = `/api/payment/${id}/init`;
  return axios.post(url, { staffId });
};

export const patchTutorPaymentTotal = (paymentMasterId: number, staffId: number): AxiosPromise<ITutorPayment> => {
  const url = `/api/payment/${paymentMasterId}/tutor/${staffId}/total`;
  return axios.patch(url);
};

export const deletePaymentSchedule = (paymentMasterId: number, staffId: number, paymentScheduleId: number): AxiosPromise<{ id: number }> => {
  const url = `/api/payment/${paymentMasterId}/${staffId}/${paymentScheduleId}/schedule`;
  return axios.delete(url);
};

export const deletePaymentCredit = (tutorDayClassCountId: number): AxiosPromise<{ id: number }> => {
  const url = `/api/payment/${tutorDayClassCountId}/credit`;
  return axios.delete(url);
};

export const patchPaymentDeadline = (id: number): AxiosPromise => {
  const url = `/api/payment/${id}/deadline`;
  return axios.patch(url);
};

export const getPaymentExcel = (id: number): AxiosPromise<IExcel> => {
  const url = `/api/payment/${id}/excel`;
  return axios.get(url);
};

export const getPayTutorExtraPayment = (paymentMasterId: number, staffId: number): AxiosPromise<ITutorExtraPayment[]> => {
  const url = `/api/payment/${paymentMasterId}/tutor/${staffId}/tutor-extra-payment`;
  return axios.get(url);
};

export const postPayTutorExtraPayment = (paymentMasterId: number, staffId: number, body: ITutorExtraPaymentCreate): AxiosPromise<ITutorExtraPayment> => {
  const url = `/api/payment/${paymentMasterId}/tutor/${staffId}/tutor-extra-payment`;
  return axios.post(url, body);
};

export const deletePayTutorExtraPayment = (paymentMasterId: number, staffId: number, id: number): AxiosPromise<{ id: number }> => {
  const url = `/api/payment/${paymentMasterId}/tutor/${staffId}/tutor-extra-payment/${id}`;
  return axios.delete(url);
};

export const patchTutorPaymentTotalAll = (paymentMasterId: number, ids: number[]): AxiosPromise<void> => {
  const url = `/api/payment/${paymentMasterId}/tutor-all`;
  return axios.patch(url, { ids });
};

export const patchPaymentEmail = (paymentMasterId: number): AxiosPromise<IPaymentMaster> => {
  const url = `/api/payment/${paymentMasterId}/email`;
  return axios.patch(url);
};

export const deletePayment = (id: number): AxiosPromise => {
  const url = `/api/payment/${id}`;
  return axios.delete(url);
};

export const getPreviousMonthSchedule = (id: number, staffId: number): AxiosPromise<{ count: number }> => {
  return axios.get(`/api/payment/${id}/${staffId}/previous-month-schedule`);
};
