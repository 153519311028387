import { useEffect } from 'react';

const useDomain = () => {
  // useEffect(() => {
  //   if (location.origin.includes('.net')) {
  //     location.href = 'https://lms.eggschool.com';
  //   }
  // }, []);
};

export default useDomain;
