import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IUserUnsubscriptionListParam,
  IStudentUserUnsubscription,
  IGetUserUnsubscriptionListSuccess
} from '../models/UserSubscription';
import { AxiosError } from 'axios';
import { AppThunk } from './index';
import { getUserUnsubscriptionList } from '../api/userSubscription';

interface IUserUnsubscriptionState {
  studentUserSubscriptions: IStudentUserUnsubscription[];
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: IUserUnsubscriptionState = {
  studentUserSubscriptions: [],
  total: 0,
  isLoading: false,
  error: undefined,
};

const userUnsubscriptionSlicer = createSlice({
  name: 'userUnsubscription',
  initialState,
  reducers: {
    getUserUnsubscriptionListStart: (state: IUserUnsubscriptionState) => {
      state.studentUserSubscriptions = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getUserUnsubscriptionListSuccess: (state: IUserUnsubscriptionState, action: PayloadAction<IGetUserUnsubscriptionListSuccess>) => {
      state.studentUserSubscriptions = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getUserUnsubscriptionListFailure: (state: IUserUnsubscriptionState, action: PayloadAction<AxiosError>) => {
      state.studentUserSubscriptions = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    }
  },
});

export const {
  getUserUnsubscriptionListStart,
  getUserUnsubscriptionListSuccess,
  getUserUnsubscriptionListFailure,
} = userUnsubscriptionSlicer.actions;

export default userUnsubscriptionSlicer.reducer;

export const doGetUserUnsubscriptionList = (param?: IUserUnsubscriptionListParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserUnsubscriptionListStart());
    const response = await getUserUnsubscriptionList(param);
    const data: IGetUserUnsubscriptionListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getUserUnsubscriptionListSuccess(data));
  } catch (e) {
    dispatch(getUserUnsubscriptionListFailure(e));
  }
};
