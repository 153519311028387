import { FC } from 'react';
import { Statistic, Typography, Card } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { format } from 'date-fns';

const { Title } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CustomCard = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #f0f0f0;
`

const StaticBox: FC = () => {
  const { statics } = useSelector((root: RootState) => root.scheduleState);
  return (
    <>
      <Title level={5}
             style={{ textAlign: 'right' }}>기준일: {statics ? statics.currentDate : format(new Date(), 'yyyy-MM-dd')}</Title>
      <Container>
        <Card style={{ width: 200, backgroundColor: '#a8d08c' }}><Statistic title='현재 회원수'
                                                                            value={statics?.totalSubscription ?? 0}
                                                                            suffix='명' /></Card>
        <Card style={{ width: 200, backgroundColor: '#a8d08c' }}><Statistic title='신규 회원수'
                                                                            value={statics?.newSubscription ?? 0}
                                                                            suffix='명' /></Card>
        <Card style={{ width: 200, backgroundColor: '#a8d08c' }}><Statistic title='해지 회원수'
                                                                            value={statics?.unSubscription ?? 0}
                                                                            suffix='명' /></Card>
        <Card style={{ width: 200, backgroundColor: '#ffc000' }}><Statistic title='매출액' value={statics?.revenue ?? 0}
                                                                            suffix='원' /></Card>
        <Card style={{ width: 200, backgroundColor: '#cc99ff' }}><Statistic title='체험수업 완료건수'
                                                                            value={statics?.totalLevelTest ?? 0}
                                                                            suffix='건' /></Card>
        <Card style={{ width: 200, backgroundColor: '#cc99ff' }}><Statistic title='수업 / 총 강사 수'
                                                                            value={statics?.activeTutor ?? 0}
                                                                            suffix={` / ${statics?.totalTutor ?? 0}`} /></Card>
      </Container>
      <Container>
        <CustomCard>
          <Title level={4}>정규 수업<br />진행 현황</Title>
          <Statistic title='총 건수' value={statics?.totalClass ?? 0} />
          <Statistic title='완료된 수업건수' value={statics?.completeClass ?? 0} />
          <Statistic title='성공한 수업건수' value={statics?.successClass ?? 0} />
          <Statistic title='학생 노쇼' value={statics?.studentNoShow ?? 0} />
          <Statistic title='강사 노쇼' value={statics?.tutorNoShow ?? 0} />
          <Statistic title='IT 문제' value={statics?.itProblem ?? 0} />
        </CustomCard>
      </Container>
      <Container>
        <CustomCard>
          <Title level={4}>정규 수업<br />취소 현황</Title>
          <Statistic title='강사 2시간내 취소' value={statics?.tutorCancel2Hour ?? 0} />
          <Statistic title='강사 24시간내 취소' value={statics?.tutorCancel4Hour ?? 0} />
          <Statistic title='학생 24시간내 취소' value={statics?.studentCancel2Hour ?? 0} />
        </CustomCard>
      </Container>
      <Container>
        <CustomCard>
          <Title level={4}>체험수업<br />현황</Title>
          <Statistic title='총 건수' value={statics?.totalLevelTest ?? 0} />
          <Statistic title='완료된 수업건수' value={statics?.completeLevelTest ?? 0} />
          <Statistic title='성공한 수업건수' value={statics?.successLevelTest ?? 0} />
          <Statistic title='학생 노쇼' value={statics?.studentNoShowLevelTest ?? 0} />
          <Statistic title='강사 노쇼' value={statics?.tutorNoShowLevelTest ?? 0} />
          <Statistic title='IT 문제' value={statics?.itProblemLevelTest ?? 0} />
        </CustomCard>
      </Container>
    </>
  );
};

export default StaticBox;
