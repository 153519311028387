import { IGetTutorWorkingLogListSuccess, ITutorWorkingLog, ITutorWorkingLogParam } from '../models/TutorWorkingLog';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { getTutorWorkingLogList } from '../api/tutorWorkingLog';

interface ITutorWorkingLogState {
  tutorWorkingLogList: ITutorWorkingLog[];
  total: number;
  isLoading: boolean;
  error?: AxiosError;
}

const initialState: ITutorWorkingLogState = {
  error: undefined, isLoading: false, total: 0, tutorWorkingLogList: [],
};

const tutorWorkingLogSlice = createSlice({
  name: 'tutorWorkingLog',
  initialState,
  reducers: {
    getTutorWorkingLogListStart: (state: ITutorWorkingLogState) => {
      state.tutorWorkingLogList = [];
      state.total = 0;
      state.isLoading = true;
      state.error = undefined;
    },
    getTutorWorkingLogListSuccess: (state: ITutorWorkingLogState, action: PayloadAction<IGetTutorWorkingLogListSuccess>) => {
      state.tutorWorkingLogList = action.payload.data;
      state.total = action.payload.total;
      state.isLoading = false;
      state.error = undefined;
    },
    getTutorWorkingLogListFailure: (state: ITutorWorkingLogState, action: PayloadAction<AxiosError>) => {
      state.tutorWorkingLogList = [];
      state.total = 0;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getTutorWorkingLogListStart,
  getTutorWorkingLogListSuccess,
  getTutorWorkingLogListFailure,
} = tutorWorkingLogSlice.actions;
export default tutorWorkingLogSlice.reducer;

export const doGetTutorWorkingLogList = (params?: ITutorWorkingLogParam): AppThunk => async (dispatch) => {
  try {
    dispatch(getTutorWorkingLogListStart());
    const response = await getTutorWorkingLogList(params);
    const data: IGetTutorWorkingLogListSuccess = {
      data: response.data,
      total: response.headers['total'],
    };
    dispatch(getTutorWorkingLogListSuccess(data));
  } catch (e) {
    dispatch(getTutorWorkingLogListFailure(e));
  }
};
