import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IDashboardSchedule } from '../../models/Schedule';
import { format } from 'date-fns';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';

interface IProps {
  dashboardScheduleList: IDashboardSchedule[];
}

const DashboardTable: FC<IProps> = ({ dashboardScheduleList }) => {
    const columns: ColumnsType<IDashboardSchedule> = [
      {
        title: '',
        render: (value, record, index) => index + 1,
      },
      {
        title: '수업(예정)시간',
        key: 'startDateTime',
        render: (value, record) => format(new Date(record.startDateTime), 'yyyy.MM.dd HH:mm'),
      },
      {
        title: '학생',
        key: 'studentId',
        render: (value, record) => <a href={`/customer/${record.userId}`} target='_blank'
                                      style={LinkStyle}>{record.studentName}({record.studentId})</a>,
      },
      {
        title: 'Tutor',
        key: 'staffId',
        render: (value, record) => <a href={`/tutor/${record.staffId}`} target='_blank'
                                      style={LinkStyle}>{record.firstName} {record.lastName}({record.staffId})</a>,
      },
      {
        title: '코스',
        key: 'courseName',
        dataIndex: 'courseName',
      },
      {
        title: 'Class',
        key: 'episodeName',
        render: (value, record) => <Link style={LinkStyle}
                                         to={`/dashboard/${record.id}`}>{record.episodeName} - {record.episodeLessonName}</Link>,
      },
      {
        title: '학생입장',
        render: (value, record) => record.studentEnterDateTime ? format(new Date(record.studentEnterDateTime), 'yyyy-MM-dd HH:mm:ss') : '-',
      },
      {
        title: '강사입장',
        render: (value, record) => record.tutorEnterDateTime ? format(new Date(record.tutorEnterDateTime), 'yyyy-MM-dd HH:mm:ss') : '-',
      },
      {
        title: '학생평가',
        render: (value, record) => record.isEvaluated ? 'Y' : 'N',
      },
    ];

    return (
      <Table
        style={tableStyle}
        dataSource={dashboardScheduleList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false}
      />
    );
  }
;

export default DashboardTable;
