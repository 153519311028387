import { IStudent, IStudentTrial, IStudentTrialCreate } from '../models/Student';
import { AxiosPromise } from 'axios';
import axios from './axiosConfig';

export const getStudent = (id: number): AxiosPromise<IStudent> => {
  return axios.get(`/api/students/${id}/lms`);
};

export const postStudentTrial = (userId: number, studentId: number, body: IStudentTrialCreate): AxiosPromise<IStudentTrial> => {
  return axios.post(`/api/user/${userId}/students/${studentId}/trial`, body);
};

export const getStudentTrialList = (userId: number, studentId: number): AxiosPromise<IStudentTrial[]> => {
  return axios.get(`/api/user/${userId}/students/${studentId}/trial`);
};
