import React, { FC, useState } from 'react';
import { descriptionContentStyle, descriptionLabelStyle } from '../style/antdCssProperty';
import { Button, Checkbox, DatePicker, Descriptions, Input, InputNumber, Radio, message } from 'antd';
import { CouponType, PublishEvent } from '../models/Enum';
import { ProductItem } from '../models/Product';
import { CouponCreate } from '../models/Coupon';
import { useHistory } from 'react-router-dom';

interface IProps {
  productItem: ProductItem[];

  onCouponFormSubmit(data: CouponCreate): void;
}

const CouponForm: FC<IProps> = ({ productItem, onCouponFormSubmit }) => {
  const [name, setName] = useState('');
  const [startPublishDateTime, setStartPublishDateTime] = useState('');
  const [endPublishDateTime, setEndPublishDateTime] = useState('');
  const [startValidDateTime, setStartValidDateTime] = useState('');
  const [endValidDateTime, setEndValidDateTime] = useState('');
  const [couponType, setCouponType] = useState<CouponType>(CouponType.AUTO);
  const [count, setCount] = useState(1);
  const [products, setProducts] = useState<Array<number>>([1]);
  const [publishEvent, setPublishEvent] = useState<PublishEvent>(PublishEvent.REGISTER);
  const [discountRatio, setDiscountRatio] = useState(0);
  const [isPublishEvent, setIsPublishEvent] = useState(false);
  const history = useHistory();

  const onNameChange = (value: string) => {
    setName(value);
  };

  const onStartPublishDateTimeChange = (value: string) => {
    setStartPublishDateTime(value);
  };

  const onEndPublishDateTimeChange = (value: string) => {
    setEndPublishDateTime(value);
  };

  const onStartValidDateTimeChange = (value: string) => {
    setStartValidDateTime(value);
  };

  const onEndValidDateTimeChange = (value: string) => {
    setEndValidDateTime(value);
  };

  const onCouponChange = (value: CouponType) => {
    setCouponType(value);
    setIsPublishEvent(value !== CouponType.AUTO);
  };

  const onCountChange = (value: string | number | null | undefined) => {
    if (value && typeof value === 'number') {
      setCount(value);
    }
  };

  const onProductsChange = (checkedValue: any) => {
    setProducts(checkedValue);
  };

  const onPublishEventChange = (value: PublishEvent) => {
    setPublishEvent(value);
  };

  const onDiscountRatio = (value: string | number | null | undefined) => {
    if (value && typeof value === 'number') {
      setDiscountRatio(value);
    }
  };

  const onSubmit = async () => {
    if (!name || !startPublishDateTime || !endPublishDateTime || !startValidDateTime || !endValidDateTime || count <= 1 || products.length === 0 || discountRatio <= 0) {
      message.warning('값을 제대로 입력해주세요.');
      return;
    }
    const data: CouponCreate = {
      name,
      startPublishDateTime,
      endPublishDateTime,
      startValidDateTime,
      endValidDateTime,
      couponType,
      products,
      discountRatio,
      count,
    };
    if (couponType === CouponType.AUTO) {
      data.publishEvent = publishEvent;
    }
    await onCouponFormSubmit(data);
    history.push('/coupons');
  };

  return (
    <>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='쿠폰명' span={2}>
          <Input onChange={(e => onNameChange(e.target.value))} required />
        </Descriptions.Item>
        <Descriptions.Item label='발생기간' span={1}>
          <DatePicker style={{ width: '45%' }}
                      onChange={(date, dateString) => onStartPublishDateTimeChange(dateString)} /> ~ <DatePicker
          style={{ width: '45%' }} onChange={(date, dateString) => onEndPublishDateTimeChange(dateString)} />
        </Descriptions.Item>
        <Descriptions.Item label='유효기간' span={1}>
          <DatePicker style={{ width: '45%' }}
                      onChange={(date, dateString) => onStartValidDateTimeChange(dateString)} /> ~ <DatePicker
          style={{ width: '45%' }} onChange={(date, dateString) => onEndValidDateTimeChange(dateString)} />
        </Descriptions.Item>
        <Descriptions.Item label='유형선택' span={1}>
          <Radio.Group onChange={(e) => onCouponChange(e.target.value)} value={couponType}>
            <Radio value={CouponType.AUTO}>자동적용</Radio>
            <Radio value={CouponType.MANUAL}>수동적용</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item label='생성수' span={1}>
          <InputNumber onChange={onCountChange} /> 개
        </Descriptions.Item>
        <Descriptions.Item label='상품선택' span={2}>
          <Checkbox.Group onChange={onProductsChange} defaultValue={[1]}>
            {
              productItem.map((item) => (
                <Checkbox value={item.id}>{item.name}</Checkbox>
              ))
            }
          </Checkbox.Group>
        </Descriptions.Item>
        <Descriptions.Item label='자동발생 이벤트' span={2}>
          <Radio.Group onChange={(e) => onPublishEventChange(e.target.value)} value={publishEvent}
                       disabled={isPublishEvent}>
            <Radio value={PublishEvent.REGISTER}>자녀등록</Radio>
            <Radio value={PublishEvent.TRIAL_CLASS}>체험상품신청</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item label='할인율' span={2}>
          <InputNumber onChange={onDiscountRatio} /> % (1%~100%까지 입력)
        </Descriptions.Item>
      </Descriptions>
      <div style={{ textAlign: 'center', marginTop: '1rem' }}>
        <Button type='primary' onSubmit={onSubmit} onClick={onSubmit}>등록</Button>
      </div>
    </>
  );
};

export default CouponForm;
