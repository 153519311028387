import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import { IStudent, IStudentTrial, IStudentTrialCreate } from '../models/Student';
import { AxiosError } from 'axios';
import { getStudent, getStudentTrialList, postStudentTrial } from '../api/student';
import { message } from 'antd';

interface IStudentState {
  customerStudent?: IStudent,
  studentTrialList: IStudentTrial[];
  isLoading: boolean;
  error: AxiosError | null;
}

const initialState: IStudentState = {
  customerStudent: undefined,
  studentTrialList: [],
  isLoading: false,
  error: null,
};

const studentSlicer = createSlice({
  name: 'student',
  initialState,
  reducers: {
    getStudentStart: (state: IStudentState) => {
      state.customerStudent = undefined;
      state.isLoading = true;
      state.error = null;
    },
    getStudentSuccess: (state: IStudentState, action: PayloadAction<IStudent>) => {
      state.customerStudent = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getStudentFailure: (state: IStudentState, action: PayloadAction<AxiosError>) => {
      state.customerStudent = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    initStudent: (state: IStudentState) => {
      state.customerStudent = undefined;
    },
    postStudentTrialStart: (state: IStudentState) => {
      state.isLoading = true;
      state.error = null;
    },
    postStudentTrialSuccess: (state: IStudentState, action: PayloadAction<IStudentTrial>) => {
      state.studentTrialList = [...state.studentTrialList, action.payload];
      state.isLoading = false;
      state.error = null;
    },
    postStudentTrialFailure: (state: IStudentState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getStudentTrialListStart: (state: IStudentState) => {
      state.studentTrialList = [];
      state.isLoading = true;
      state.error = null;
    },
    getStudentTrialListSuccess: (state: IStudentState, action: PayloadAction<IStudentTrial[]>) => {
      state.studentTrialList = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getStudentTrialListFailure: (state: IStudentState, action: PayloadAction<AxiosError>) => {
      state.studentTrialList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getStudentStart,
  getStudentSuccess,
  getStudentFailure,
  initStudent,
  postStudentTrialStart,
  postStudentTrialSuccess,
  postStudentTrialFailure,
  getStudentTrialListStart,
  getStudentTrialListSuccess,
  getStudentTrialListFailure,
} = studentSlicer.actions;

export default studentSlicer.reducer;

export const doGetStudent = (id: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getStudentStart());
    const response = await getStudent(id);
    dispatch(getStudentSuccess(response.data));
  } catch (e) {
    dispatch(getStudentFailure(e));
  }
};

export const doPostStudentTrial = (userId: number, studentId: number, body: IStudentTrialCreate): AppThunk => async (dispatch) => {
  try {
    dispatch(postStudentTrialStart());
    const { data } = await postStudentTrial(userId, studentId, body);
    dispatch(postStudentTrialSuccess(data));
    message.success('성공적으로 체험단에 등록하였습니다.');
  } catch (e) {
    dispatch(postStudentTrialFailure(e));
  }
};

export const doGetStudentTrialList = (userId: number, studentId: number): AppThunk => async dispatch => {
  try {
    dispatch(getStudentTrialListStart());
    const { data } = await getStudentTrialList(userId, studentId);
    dispatch(getStudentTrialListSuccess(data));
  } catch (e) {
    dispatch(getStudentTrialListFailure(e));
  }
};
