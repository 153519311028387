import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import {
  IStaff,
  IStaffListQuery, IStaffNewPassword, IStaffRegister,
  StaffApplicantPatch,
  StaffListQuery,
  StaffTutorListItem,
  StaffTutorReadItem,
} from '../models/Staff';
import qs from 'qs';

export const list = (params?: StaffListQuery): AxiosPromise<StaffTutorListItem[]> => {
  let url = '/api/staffs/tutor';
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const read = (id: number): AxiosPromise<StaffTutorReadItem> => {
  return axios.get(`/api/staffs/tutor/${id}`);
};

export const update = (staffId: number, data: StaffApplicantPatch): AxiosPromise<StaffTutorReadItem> => {
  return axios.patch(`/api/staffs/applicants/${staffId}`, data);
};

export const getStaffList = (params?: IStaffListQuery): AxiosPromise<IStaff[]> => {
  let url = `/api/staffs`;
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const postStaff = (body: IStaffRegister): AxiosPromise<IStaff> => {
  return axios.post('/api/auth/staff/register/staff', body);
};

export const patchStaffPassword = (body: IStaffNewPassword): AxiosPromise => {
  return axios.patch('/api/auth/staff/new_password/staff', body);
};

export const deleteStaff = (id: number):AxiosPromise => {
  return axios.delete(`/api/auth/staff/${id}`);
}
