import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { IUserTransaction, IUserTransactionBody, IUserTransactionParam } from '../models/UserTransaction';
import qs from 'qs';

export const getUserTransactionList = (params?: IUserTransactionParam): AxiosPromise<IUserTransaction[]> => {
  let url = '/api/students/userTransaction';
  const qsParams = qs.stringify(params);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const patchUserTransaction = (id: number, body: IUserTransactionBody): AxiosPromise<void> => {
  return axios.patch(`/api/students/userTransaction/${id}`, body);
};
