import { FC, useEffect, useState } from 'react';
import { Button, Input, InputProps, message } from 'antd';
import { divStyle } from '../../style/antdCssProperty';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import CustomDatePicker from '../common/CustomDatePicker';
import { doPostPayment } from '../../store/payment.slice';
import { addDays, format, getDate, lastDayOfMonth, setDate } from 'date-fns';
import { paymentUserCheck } from '../../libs/helper';

const PaymentForm: FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((root: RootState) => root.authState);

  const [title, setTitle] = useState('');
  const [startDateTime, setStartDateTime] = useState<Date>();
  const [endDateTime, setEndDateTime] = useState<Date>();

  const titleInputProps: InputProps = {
    placeholder: '생성명',
    onChange: event => setTitle(event.target.value),
    value: title,
    style: { display: 'inline-block', width: 250 },
  };

  const onStartDateTimeChange = (date: Date | null, dateString: string) => {
    if (date) {
      setStartDateTime(date);
    } else {
      setStartDateTime(new Date(dateString));
    }
  };

  const onEndDateTimeChange = (date: Date | null, dateString: string) => {
    if (date) {
      setEndDateTime(date);
    } else {
      setEndDateTime(new Date(dateString));
    }
  };

  const onSubmitBtn = () => {
    if (!title) {
      message.warn('생성명을 입력해주세요.');
      return;
    }
    if (!startDateTime) {
      message.warn('시작 날짜를 입력해주세요.');
      return;
    }
    if (!endDateTime) {
      message.warn('마지막 날짜를 입력해주세요.');
      return;
    }
    dispatch(doPostPayment({
      title,
      startDateTime: format(startDateTime, 'yyyy-MM-dd'),
      endDateTime: format(addDays(endDateTime, 1), 'yyyy-MM-dd'),
    }));
  };

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const firstDate = setDate(currentDate, 1);
    const lastDate = setDate(currentDate, getDate(lastDayOfMonth(currentDate)));
    const title = format(currentDate, 'MMMM ') +
      format(firstDate, 'do ') +
      'to '
      + format(lastDate, 'do');

    setTitle(title);
    setStartDateTime(firstDate);
    setEndDateTime(lastDate);
  }, []);

  return (
    <div style={{ ...divStyle, display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <p style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '1rem' }}>신규생성</p>
        <Input {...titleInputProps} />
      </div>
      <div style={{ display: 'flex', alignItems: 'start' }}>
        <p style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '1rem' }}>시작일</p>
        <CustomDatePicker value={startDateTime} onChange={onStartDateTimeChange} />
        <CustomDatePicker value={endDateTime} onChange={onEndDateTimeChange} />
      </div>
      <Button
        type='primary'
        onClick={onSubmitBtn}
        disabled={user === null || paymentUserCheck(user.id)}
      >
        생성
      </Button>
    </div>
  );
};

export default PaymentForm;
