import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { couponListRemove, couponListResponse } from '../store/coupon.slice';
import { Link, useRouteMatch } from 'react-router-dom';
import { RootState } from '../store/rootReducer';
import CouponListTable from '../components/CouponListTable';
import Loader from '../components/Loader';
import { CouponListQuery } from '../models/Coupon';
import { Breadcrumb, PageHeader } from 'antd';

interface IMatch {
  id: string;
}

const CouponPage: FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch<IMatch>();
  const { isLoading: isCouponLoading, couponList } = useSelector((root: RootState) => root.couponState);

  useEffect(() => {
    const data: CouponListQuery = {
      couponId: Number(match.params.id),
    };
    dispatch(couponListResponse(data));
  }, [dispatch, match.params.id]);

  const onClick =async (id: number) => {
    await dispatch(couponListRemove(id));
    const data: CouponListQuery = {
      couponId: Number(match.params.id),
    };
    await dispatch(couponListResponse(data));
  };

  return (
    <>
      {
        isCouponLoading ? (
          <Loader />
        ) : (
          <>
            <Breadcrumb>
              <Breadcrumb.Item><Link to='/dashboard'>DashBoard</Link></Breadcrumb.Item>
              <Breadcrumb.Item><Link to='/coupons'>쿠폰관리</Link></Breadcrumb.Item>
              <Breadcrumb.Item>쿠폰리스트</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader title='쿠폰리스트'/>
            <CouponListTable couponList={couponList!} onClick={onClick} />
          </>
        )
      }
    </>
  );
};

export default CouponPage;