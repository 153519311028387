import { FC } from 'react';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IDashboardSchedule } from '../../models/Schedule';
import { addMinutes, format, isAfter, isBefore } from 'date-fns';
import { LinkStyle, tableStyle } from '../../style/antdCssProperty';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { ScheduleStatus, StudyUserType } from '../../models/Enum';
import { isStudying } from '../../libs/helper';
import styled from 'styled-components';
import { IMonitoringCallCheck } from '../../models/monitoring.model';
import CallButton from './CallButton';
import { UAParser } from 'ua-parser-js';

interface IProps {
  showModal(monitorCallCheck: IMonitoringCallCheck): void;
}

interface IStatusButton {
  color: string;
}

const { Text } = Typography;

const StatusButton = styled.div<IStatusButton>`
  display: inline-block;
  padding: 5px 10px;
  background-color: ${({ color }) => color};
`;

const MonitoringTable: FC<IProps> = ({ showModal }) => {

  const { dashboardScheduleList } = useSelector((root: RootState) => root.scheduleState);
  const { monitoringCheckList, monitoringCallCheckList } = useSelector((root: RootState) => root.monitoringState);

  const getEnterStudy = (record: IDashboardSchedule, userType: StudyUserType): boolean => {
    if (isStudying(record.startDateTime)) {
      const filter = monitoringCheckList.filter((item) => item.scheduleId === record.id && item.userType === userType);
      return filter.length >= 1;
    } else if (isAfter(new Date(), new Date(record.startDateTime))) {
      if (userType === StudyUserType.STUDENT) {
        return record.studentEnterDateTime !== null;
      } else {
        return record.tutorEnterDateTime !== null;
      }
    } else {
      return false;
    }
  };

  const getStatus = (record: IDashboardSchedule) => {
    const startDateTime = new Date(record.startDateTime);
    const current = new Date();
    if (isBefore(current, startDateTime)) {
      return <StatusButton color='#bfbfbf'>미오픈</StatusButton>;
    }
    if (isStudying(record.startDateTime)) {
      const isTutorEnter = getEnterStudy(record, StudyUserType.TUTOR);
      const isStudentEnter = getEnterStudy(record, StudyUserType.STUDENT);
      if (isTutorEnter && isStudentEnter) {
        return <StatusButton color='#1890ff'>정상진행</StatusButton>;
      } else {
        return <StatusButton color='#fa8c16'>비정상진행</StatusButton>;
      }
    }
    if (isBefore(startDateTime, current)) {
      if (record.status === ScheduleStatus.COMPLETE) {
        return <StatusButton color='#52c41a'>정상완료</StatusButton>;
      } else {
        return <StatusButton color='#ad6800'>비정상완료</StatusButton>;
      }
    }
    return '';
  };

  const getCall = (record: IDashboardSchedule, userType: StudyUserType) => {
    const filter = monitoringCallCheckList.filter((item) => item.scheduleId === record.id && item.userType === userType);
    if (filter.length >= 1) {
      return <CallButton isCall text='Call' onClick={() => {
        getCallBtnClick(record, userType);
      }}>Call</CallButton>;
    }
    return <CallButton isCall={false} text='Call' />;
  };

  const getCallBtnClick = (record: IDashboardSchedule, userType: StudyUserType) => {
    const filter = monitoringCallCheckList.filter((item) => item.scheduleId === record.id && item.userType === userType);
    if (filter.length >= 1) {
      showModal(filter[0]);
    }
  };

  const getUserAgent = (record: IDashboardSchedule, userType: StudyUserType): string => {
    const find = monitoringCheckList.find((item) => item.scheduleId === record.id && userType === item.userType);
    if (find) {
      const ua = UAParser(find?.userAgent);
      return `${ua.browser.name} ${ua.browser.version} ${ua.os.name} ${ua.os.version}`;
    }
    return '-';
  };

  const columns: ColumnsType<IDashboardSchedule> = [
    {
      title: '수업시간',
      key: 'startDateTime',
      render: (value, record) => format(new Date(record.startDateTime), 'yyyy.MM.dd HH:mm'),
    },
    {
      title: '학생',
      key: 'studentId',
      render: (value, record) => <a href={`/customer/${record.userId}`} target='_blank'
                                    style={LinkStyle}>{record.studentName}({record.studentId})</a>,
    },
    {
      title: '강사',
      key: 'staffId',
      render: (value, record) => <a href={`/tutor/${record.staffId}`} target='_blank'
                                    style={LinkStyle}>{record.firstName} {record.lastName}({record.staffId})</a>,
    },
    {
      title: '구분',
      key: 'isTrialClass',
      render: (value, record) => record.isTrialClass === 0 ? '일반 수업' : '레벨 테스트 수업',
    },
    {
      title: '학생 입장',
      render: (value, record) => {
        const isEnter = getEnterStudy(record, StudyUserType.STUDENT);
        const isStudy = isAfter(new Date(), new Date(record.startDateTime)) && isBefore(new Date(), addMinutes(new Date(record.startDateTime), 30));
        return isStudy ? record.studentEnterDateTime ? format(new Date(record.studentEnterDateTime), 'yyyy-MM-dd HH:mm:ss') : '-' : '-';
      },
    },
    {
      title: '강사 입장',
      render: (value, record) => {
        const isEnter = getEnterStudy(record, StudyUserType.TUTOR);
        const isStudy = isAfter(new Date(), new Date(record.startDateTime)) && isBefore(new Date(), addMinutes(new Date(record.startDateTime), 30));
        return isStudy ? record.tutorEnterDateTime ? format(new Date(record.tutorEnterDateTime), 'yyyy-MM-dd HH:mm:ss') : '-' : '-';
      },
    },
    {
      title: '학생 접속',
      render: (value, record) => {
        const isEnter = getEnterStudy(record, StudyUserType.STUDENT);
        const isStudy = isAfter(new Date(), new Date(record.startDateTime)) && isBefore(new Date(), addMinutes(new Date(record.startDateTime), 30));
        return <Text
          style={{ color: isStudy ? isEnter ? '#52c41a' : '#f5222d' : '#000000' }}>{isStudy ? isEnter ? 'ON' : 'OFF' : '-'}</Text>;
      },
    },
    {
      title: '강사 접속',
      render: (value, record) => {
        const isEnter = getEnterStudy(record, StudyUserType.TUTOR);
        const isStudy = isAfter(new Date(), new Date(record.startDateTime)) && isBefore(new Date(), addMinutes(new Date(record.startDateTime), 30));
        return <Text
          style={{ color: isStudy ? isEnter ? '#52c41a' : '#f5222d' : '#000000' }}>{isStudy ? isEnter ? 'ON' : 'OFF' : '-'}</Text>;
      },
    },
    {
      title: '수업상태',
      render: (value, record) => getStatus(record),
    },
    {
      title: '학생 Call',
      render: (value, record) => getCall(record, StudyUserType.STUDENT),
    },
    {
      title: '강사 Call',
      render: (value, record) => getCall(record, StudyUserType.TUTOR),
    },
    {
      title: '강사 UserAgent',
      render: (value, record) => getUserAgent(record, StudyUserType.TUTOR),
    },
    {
      title: '학생 UserAgent',
      render: (value, record) => getUserAgent(record, StudyUserType.STUDENT),
    },
  ];

  return (
    <Table
      style={tableStyle}
      dataSource={dashboardScheduleList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
    />
  );
};

export default MonitoringTable;
