import React, { FC, useEffect, useState } from 'react';
import { divStyle } from '../../style/antdCssProperty';
import { Select, Input, Button } from 'antd';
import { StaffStatus } from '../../models/Enum';
import { ITutor2Param } from '../../models/tutor2.model';
import { FileExcelOutlined } from '@ant-design/icons';
import { getTutor2List } from '../../api/tutor2.api';
import { excelInit } from '../../libs/helper';
import { getSession } from '../../libs/session';

interface IProps {
  total: number;

  onFilter(data: ITutor2Param): void;
}

interface IExcelData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  countryId: number;
  countryName: string;
}

const { Option } = Select;
const { Search } = Input;

const TutorFilterBox: FC<IProps> = ({ total, onFilter }) => {
  const [status, setStatus] = useState<StaffStatus | '상태'>('상태');
  const [type, setType] = useState<'name' | 'email' | '선택'>('선택');
  const [search, setSearch] = useState('');

  useEffect(() => {
    const sessionParams = getSession<ITutor2Param>('tutor');
    if (sessionParams) {
      if (sessionParams.status) {
        setStatus(sessionParams.status);
      }
      if (sessionParams.type) {
        setType(sessionParams.type);
      }
      if (sessionParams.search) {
        setSearch(sessionParams.search);
      }
    }
  }, []);

  const onStatusChange = (value: StaffStatus | '상태') => {
    setStatus(value);
  };

  const onTypeChange = (value: 'name' | 'email' | '선택') => {
    setType(value);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const initData = () => {
    const data: ITutor2Param = {};
    if (status !== '상태') {
      data.status = status;
    }
    if (type !== '선택' && search !== '') {
      data.type = type;
      data.search = search;
    }
    return data;
  };

  const onSubmit = () => {
    const data = initData();
    onFilter(data);
  };

  const onExcelClick = async () => {
    const data = initData();
    data.all = true;
    const response = await getTutor2List(data);
    if (response.data.length >= 1) {
      const excelData: IExcelData[] = [];
      for (const item of response.data) {
        excelData.push({
          id: item.id,
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName,
          status: item.status,
          countryId: item.country.id,
          countryName: item.country.name,
        });
      }
      await excelInit('tutor', 'tutor', excelData);
    }
  };

  return (
    <div style={divStyle}>
      <Select style={{ width: '15%' }} value={status} onChange={onStatusChange}>
        <Option value='상태'>상태</Option>
        <Option value={StaffStatus.ACTIVE}>{StaffStatus.ACTIVE}</Option>
        <Option value={StaffStatus.HOLD}>{StaffStatus.HOLD}</Option>
        <Option value={StaffStatus.QUIT}>{StaffStatus.QUIT}</Option>
      </Select>
      <Select style={{ width: '15%' }} value={type} onChange={onTypeChange}>
        <Option value='name'>이름</Option>
        <Option value='email'>이메일</Option>
      </Select>
      <Search
        style={{ width: '30%', marginRight: '0.5rem' }}
        enterButton='Search'
        value={search}
        onSearch={onSubmit}
        onChange={onSearch} />
      total: {total}
      <Button icon={<FileExcelOutlined />} onClick={onExcelClick} style={{ marginLeft: '0.5rem', float: 'right' }} />
    </div>
  );
};

export default TutorFilterBox;
