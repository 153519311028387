import {
  IStudentUnSubscription,
  IStudentUserSubscriptionByUser,
  IUserSubscriptionListParam,
  IStudentUserUnsubscription
} from '../models/UserSubscription';
import { AxiosPromise } from 'axios';
import axios from './axiosConfig';
import qs from 'qs';

export const getUserSubscriptionList = (param?: IUserSubscriptionListParam): AxiosPromise<IStudentUserSubscriptionByUser[]> => {
  let url = '/api/students/userSubscription/list';
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const getUserUnsubscriptionList = (param?: IUserSubscriptionListParam): AxiosPromise<IStudentUserUnsubscription[]> => {
  let url = '/api/unsubscription/list';
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const postUnsubscription = (data: IStudentUnSubscription): AxiosPromise<IStudentUserSubscriptionByUser> => {
  return axios.post(`/api/students/userSubscription/unsubscription`, data);
};
