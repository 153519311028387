import {
  ILevelTestConsulting,
  ILevelTestConsultingPatch, ILevelTestFail, ILevelTestFailPatch,
  ILevelTestItem, ILevelTestItemElement,
  ILevelTestSurvey,
} from '../models/levelTest.model';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './index';
import {
  deleteLevelTestRecovery,
  getLevelTestConsulting, getLevelTestFail,
  getLevelTestItem, getLevelTestItemElement,
  getLevelTestSurveyList,
  patchLevelTestConsulting, patchLevelTestFail,
} from '../api/levelTest.api';
import { message } from 'antd';
import { levelTestRecoverySuccess } from './schedule.slice';

interface ILevelTestState {
  levelTestItem?: ILevelTestItem;
  levelTestSurveyList: ILevelTestSurvey[];
  isLoading: boolean;
  error?: AxiosError;
  levelTestConsulting?: ILevelTestConsulting;
  levelTestFail?: ILevelTestFail;
  levelTestItemElementList: ILevelTestItemElement[];
  isRedirect: boolean;
}

const initialState: ILevelTestState = {
  levelTestSurveyList: [],
  isLoading: false,
  levelTestItemElementList: [],
  isRedirect: false,
};

const levelTestSlicer = createSlice({
  name: 'levelTest',
  initialState,
  reducers: {
    getLevelTestItemStart: (state: ILevelTestState) => {
      state.levelTestItem = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getLevelTestItemSuccess: (state: ILevelTestState, action: PayloadAction<ILevelTestItem>) => {
      state.levelTestItem = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getLevelTestItemFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.levelTestItem = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getLevelTestSurveyListStart: (state: ILevelTestState) => {
      state.levelTestSurveyList = [];
      state.isLoading = true;
      state.error = undefined;
    },
    getLevelTestSurveyListSuccess: (state: ILevelTestState, action: PayloadAction<ILevelTestSurvey[]>) => {
      state.levelTestSurveyList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getLevelTestSurveyListFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.levelTestSurveyList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    patchLevelTestConsultingStart: (state: ILevelTestState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    patchLevelTestConsultingSuccess: (state: ILevelTestState, action: PayloadAction<ILevelTestConsulting>) => {
      state.levelTestConsulting = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    patchLevelTestConsultingFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.levelTestConsulting = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getLevelTestConsultingStart: (state: ILevelTestState) => {
      state.levelTestConsulting = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getLevelTestConsultingSuccess: (state: ILevelTestState, action: PayloadAction<ILevelTestConsulting>) => {
      state.levelTestConsulting = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getLevelTestConsultingFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.levelTestConsulting = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    patchLevelTestFailStart: (state: ILevelTestState) => {
      state.levelTestFail = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    patchLevelTestFailSuccess: (state: ILevelTestState, action: PayloadAction<ILevelTestFail>) => {
      state.levelTestFail = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    patchLevelTestFailFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.levelTestFail = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    getLevelTestFailStart: (state: ILevelTestState) => {
      state.levelTestFail = undefined;
      state.isLoading = true;
      state.error = undefined;
    },
    getLevelTestFailSuccess: (state: ILevelTestState, action: PayloadAction<ILevelTestFail>) => {
      state.levelTestFail = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getLevelTestFailFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.levelTestFail = undefined;
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteLevelTestRecoveryStart: (state: ILevelTestState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    deleteLevelTestRecoverySuccess: (state: ILevelTestState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = undefined;
      if (action.payload === 'reserved') {
        state.levelTestItem = undefined;
        state.isRedirect = true;
      } else if (state.levelTestItem) {
        state.levelTestItem = {
          ...state.levelTestItem,
          deletedAt: new Date().toISOString(),
        };
      }
    },
    deleteLevelTestRecoveryFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getLevelTestItemElementStart: (state: ILevelTestState) => {
      state.isLoading = true;
      state.error = undefined;
    },
    getLevelTestItemElementSuccess: (state: ILevelTestState, action: PayloadAction<ILevelTestItemElement[]>) => {
      state.levelTestItemElementList = action.payload;
      state.isLoading = false;
      state.error = undefined;
    },
    getLevelTestItemElementFailure: (state: ILevelTestState, action: PayloadAction<AxiosError>) => {
      state.levelTestItemElementList = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    initIsRedirect: (state: ILevelTestState) => {
      state.isRedirect = false;
    },
  },
});

export const {
  getLevelTestItemStart,
  getLevelTestItemSuccess,
  getLevelTestItemFailure,
  getLevelTestSurveyListStart,
  getLevelTestSurveyListSuccess,
  getLevelTestSurveyListFailure,
  patchLevelTestConsultingStart,
  patchLevelTestConsultingSuccess,
  patchLevelTestConsultingFailure,
  getLevelTestConsultingStart,
  getLevelTestConsultingSuccess,
  getLevelTestConsultingFailure,
  patchLevelTestFailStart,
  patchLevelTestFailSuccess,
  patchLevelTestFailFailure,
  getLevelTestFailStart,
  getLevelTestFailSuccess,
  getLevelTestFailFailure,
  deleteLevelTestRecoveryStart,
  deleteLevelTestRecoverySuccess,
  deleteLevelTestRecoveryFailure,
  getLevelTestItemElementStart,
  getLevelTestItemElementSuccess,
  getLevelTestItemElementFailure,
  initIsRedirect,
} = levelTestSlicer.actions;
export default levelTestSlicer.reducer;

export const doGetLevelTestItem = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getLevelTestItemStart());
    const { data } = await getLevelTestItem(scheduleId);
    dispatch(getLevelTestItemSuccess(data));
  } catch (e) {
    dispatch(getLevelTestItemFailure(e));
  }
};

export const doGetLevelTestSurveyList = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getLevelTestSurveyListStart());
    const { data } = await getLevelTestSurveyList(scheduleId);
    dispatch(getLevelTestSurveyListSuccess(data));
  } catch (e) {
    dispatch(getLevelTestSurveyListFailure(e));
  }
};

export const doPatchLevelTestConsulting = (body: ILevelTestConsultingPatch): AppThunk => async (dispatch) => {
  try {
    dispatch(patchLevelTestConsultingStart());
    const { data } = await patchLevelTestConsulting(body);
    dispatch(patchLevelTestConsultingSuccess(data));
    message.success('교육상담 작성이 성공했습니다.');
  } catch (e) {
    dispatch(patchLevelTestConsultingFailure(e));
    message.success('교육상담 작성이 실패했습니다.');
  }
};

export const doGetLevelTestConsulting = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getLevelTestConsultingStart());
    const { data } = await getLevelTestConsulting(scheduleId);
    dispatch(getLevelTestConsultingSuccess(data));
  } catch (e) {
    dispatch(getLevelTestConsultingFailure(e));
  }
};

export const doPatchLevelTestFail = (body: ILevelTestFailPatch): AppThunk => async (dispatch) => {
  try {
    dispatch(patchLevelTestFailStart());
    const { data } = await patchLevelTestFail(body);
    dispatch(patchLevelTestFailSuccess(data));
    message.success('작성에 성공했습니다.');
  } catch (e) {
    dispatch(patchLevelTestFailFailure(e));
  }
};

export const doGetLevelTestFail = (scheduleId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(getLevelTestFailStart());
    const { data } = await getLevelTestFail(scheduleId);
    dispatch(getLevelTestFailSuccess(data));
  } catch (e) {
    dispatch(getLevelTestFailFailure(e));
  }
};

export const doDeleteLevelTestRecovery = (scheduleId: number, status: string): AppThunk => async (dispatch) => {
  try {
    console.log('status1: ', status);
    dispatch(deleteLevelTestRecoveryStart());
    await deleteLevelTestRecovery(scheduleId);
    dispatch(deleteLevelTestRecoverySuccess(status));
    dispatch(levelTestRecoverySuccess());
    message.success(`체험수업 ${status === 'reserved' ? '삭제' : '복원'}에 성공했습니다.`);
  } catch (e) {
    dispatch(deleteLevelTestRecoveryFailure(e));
    message.success('체험수업 복원에 실패했습니다.');
  }
};

export const doGetLevelTestItemElement = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getLevelTestItemElementStart());
    const { data } = await getLevelTestItemElement();
    console.log('data: ', data);
    dispatch(getLevelTestItemElementSuccess(data));
  } catch (e) {
    dispatch(getLevelTestItemElementFailure(e));
  }
};
