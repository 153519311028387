import { FC, useEffect } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';

const Message: FC = () => {
  const { message: msg, timestamps, type } = useSelector((state: RootState) => state.messageState);

  useEffect(() => {
    if (timestamps > 0) {
      message[type](msg);
    }
  }, [timestamps, msg, type]);

  return null;
};

export default Message;