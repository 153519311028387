import React, { FC, useEffect, useState } from 'react';
import { Descriptions, Divider, Input, Radio } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle, LinkStyle } from '../../style/antdCssProperty';
import { ITutor2Read } from '../../models/tutor2.model';

interface IProps {
  tutor?: ITutor2Read;
  isBlacklisted: string | null;
  additionalEmail: string;
  setIsBlacklisted: Function;

  onAdditionalEmailChange(value: string): void;
}

const TutorInfo: FC<IProps> = ({
                                 tutor,
                                 isBlacklisted,
                                 additionalEmail,
                                 setIsBlacklisted,
                                 onAdditionalEmailChange,
                               }) => {
  const [loginProvider, setLoginProvider] = useState('');

  useEffect(() => {
    setLoginProvider('');
    if (tutor) {
      for (const item of tutor.staffLoginMethods) {
        let newLoginProvider = '';
        if (loginProvider === '') {
          newLoginProvider = item.loginProvider;
        } else {
          newLoginProvider = `${loginProvider} / ${item.loginProvider}`;
        }
        setLoginProvider(newLoginProvider);
      }
      tutor.tutor.additionalEmail && onAdditionalEmailChange(tutor.tutor.additionalEmail);
    }
  }, [tutor]);

  return (
    <>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='firstName'
                           span={1}>{tutor?.firstName ? tutor.firstName : ''}</Descriptions.Item>
        <Descriptions.Item label='lastName'
                           span={1}>{tutor?.lastName ? tutor.lastName : ''}</Descriptions.Item>
        <Descriptions.Item label='Email'
                           span={1}>{tutor?.email ? tutor.email : ''}</Descriptions.Item>
        <Descriptions.Item label='Nationality'
                           span={1}>{tutor?.country?.name ? tutor.country.name : ''}</Descriptions.Item>
        <Descriptions.Item label='Cell Phone'
                           span={1}>{tutor?.phone ? tutor.phone : ''}</Descriptions.Item>
        <Descriptions.Item label='Certificate of Teaching'
                           span={1}>{tutor?.certificate ? tutor.certificate : ''}</Descriptions.Item>
        <Descriptions.Item label='Teaching Experience'
                           span={1}>{tutor?.teachingExperience ? tutor.teachingExperience : ''}</Descriptions.Item>
        <Descriptions.Item label='Referral Code'
                           span={1}>{tutor?.referralCode ? tutor.referralCode : ''}</Descriptions.Item>
        <Descriptions.Item label='Time Zone'
                           span={1}>{tutor?.timeZone ? tutor.timeZone : ''}</Descriptions.Item>
        <Descriptions.Item label='Login Method'
                           span={1}>{loginProvider}</Descriptions.Item>
        <Descriptions.Item label='정규 강의 가능'
                           span={1}>{tutor?.tutor?.canRegularTeaching ? tutor.tutor.canRegularTeaching : ''}</Descriptions.Item>
        <Descriptions.Item label='체험수업 가능'
                           span={1}>{tutor?.tutor?.canTrialTeaching ? tutor.tutor.canTrialTeaching : ''}</Descriptions.Item>
        <Descriptions.Item label='상태'
                           span={1}>{tutor?.status ? tutor.status : ''}</Descriptions.Item>
        <Descriptions.Item label='Last Login'
                           span={1}>{tutor?.lastLogin ? tutor.lastLogin : ''}</Descriptions.Item>
        <Descriptions.Item label='강사 배정 차단'
                           span={2}>
          <Radio.Group
            onChange={e => setIsBlacklisted(e.target.value)}
            value={isBlacklisted || (tutor?.tutor?.isBlacklisted ? 'Y' : 'N')}
          >
            <Radio value='Y'>Y</Radio>
            <Radio value='N'>N</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item label='추가 이메일' span={2}>
          <Input type='text' value={additionalEmail}
                 onChange={({ target: { value } }) => onAdditionalEmailChange(value)} />
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='자기소개'
                           span={2}>{tutor?.tutor?.selfIntroduction ? tutor.tutor.selfIntroduction : ''}</Descriptions.Item>
        <Descriptions.Item label='Photo'
                           span={1}><a href={tutor?.tutor?.photo ? tutor.tutor.photo : ''}
                                       style={LinkStyle}>{tutor?.tutor?.photo ? tutor.tutor.photo : ''}</a></Descriptions.Item>
        <Descriptions.Item label='Self video'
                           span={1}><a href={tutor?.tutor?.video ? tutor.tutor.video : ''}
                                       style={LinkStyle}>{tutor?.tutor?.video ? tutor.tutor.video : ''}</a></Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default TutorInfo;
