import { CSSProperties } from 'react';

export const tableStyle: CSSProperties = {
  borderTop: '2px solid #ccc',
};

export const divStyle: CSSProperties = {
  border: '1px solid #ccc',
  padding: '15px',
  backgroundColor: '#efefef',
  textAlign: 'center',
  marginBottom: '1.5rem',
};

export const descriptionLabelStyle: CSSProperties = {
  width: '20%',
  backgroundColor: 'rgb(250, 250, 250)',
};

export const descriptionContentStyle: CSSProperties = {
  width: '30%',
};

export const LinkStyle: CSSProperties = {
  color: 'black',
  textDecoration: 'underline',
};