import { FC, useEffect, useState } from 'react';
import { Descriptions, Table } from 'antd';
import { ILevelTestItem, ILevelTestItemDetail } from '../../models/levelTest.model';
import { descriptionContentStyle, descriptionLabelStyle, tableStyle } from '../../style/antdCssProperty';
import { ICourse } from '../../models/Course';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { addMonths, format, subDays } from 'date-fns';

interface IProps {
  levelTestItem: ILevelTestItem;
  courseList: ICourse[];
}

const DashboardLevelTestEvaluation: FC<IProps> = ({ levelTestItem, courseList }) => {
  const { levelTestItemElementList } = useSelector((root: RootState) => root.levelTestState);

  const columns: ColumnsType<ILevelTestItemDetail> = [
    {
      title: '항목',
      key: 'name',
      render: (value, record) => record.levelTestItem.name,
    },
    {
      title: '의견',
      key: 'description',
      render: (value, record, index) => descriptions[index] ?? '',
    },
    {
      title: '점수',
      key: 'score',
      render: (value, record) => record.score,
    },
  ];
  const [descriptions, setDescriptions] = useState<string[]>([]);

  useEffect(() => {
    const newDescriptions: string[] = [];
    for (let i = 0; i < levelTestItem.levelTestItemDetails.length; i++) {
      let newDescription = getDescription(levelTestItem.levelTestItemDetails[i].score, i);
      newDescriptions.push(newDescription);
    }
    setDescriptions(newDescriptions);
  }, [levelTestItemElementList, levelTestItem]);

  const getDescription = (score: number, index: number) => {
    if (levelTestItemElementList.length === 0) {
      return '';
    }
    const levelTestItemVersion2 = levelTestItemElementList.filter((item) => item.version === 2);
    const descriptions = levelTestItemVersion2[index].levelTestItemDescriptions.filter((item) => item.score === score);
    if (descriptions.length >= 1) {
      return descriptions[0].koDescription;
    }
    return '';
  };

  const getCourse = () => {
    if (!levelTestItem) {
      return '';
    }
    if (levelTestItem.notRecommended) {
      return 'Not recommended.';
    }
    const course = courseList.filter((item) => item.id === levelTestItem.recommendCourseId);
    if (course.length === 1) {
      return course[0].name;
    }
    return '';
  };

  return (
    <>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}>
        <Descriptions.Item label='Program Level and Program Name [추천 코스명]' span={2}>
          {getCourse()}
        </Descriptions.Item>
        <Descriptions.Item label='Suggested Start Date' span={2}>
          {levelTestItem?.suggestedStartDate ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label='Expected End Date' span={2}>
          {levelTestItem?.suggestedStartDate ? format(subDays(addMonths(new Date(levelTestItem.suggestedStartDate), 5), 1), 'yyyy-MM-dd') : ''}
        </Descriptions.Item>
      </Descriptions>
      <Table style={{ ...tableStyle, marginTop: '1.5rem' }} dataSource={levelTestItem.levelTestItemDetails}
             columns={columns} size='small' rowKey='id' pagination={false} />
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}
                    style={{ marginTop: '1.5rem' }}>
        {levelTestItem && levelTestItem.version === 1 ? <Descriptions.Item label='튜터 평가' span={1}>
          {levelTestItem.comment}
        </Descriptions.Item> : <>
          <Descriptions.Item label='잘한 점' span={1}>
            {levelTestItem.strengths}
          </Descriptions.Item>
          <Descriptions.Item label='도움이 필요한 점' span={1}>
            {levelTestItem.improvements}
          </Descriptions.Item>
        </>}
      </Descriptions>
    </>
  );
};

export default DashboardLevelTestEvaluation;
