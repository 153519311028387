import React, { FC } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { BoardListItem } from '../../models/Board';
import { format } from 'date-fns';
import { Table } from 'antd';
import { tableStyle } from '../../style/antdCssProperty';

interface IProps {
  boardList: BoardListItem[]
}

const TutorBoard: FC<IProps> = ({ boardList }) => {
  const columns: ColumnsType<BoardListItem> = [
    {
      title: '번호',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: '카테고리',
      key: 'category',
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: '등록일',
      key: 'createdAt',
      render: (value, record, index) => (
        format(new Date(record.createdAt), 'yyyy-MM-dd HH:mm:ss')
      ),
    },
    {
      title: '구분',
      key: 'count',
      render: (value, record, index) => {
        return record.commentCount !== 0 ?
          <p>답변 완료 <br /> {format(new Date(record.commentCreatedAt), 'yyyy-MM-dd HH:mm:ss')}</p> : '미답변';
      },
    },
  ];
  return (
    <>
      <Table
        style={tableStyle}
        dataSource={boardList}
        columns={columns}
        size='small'
        rowKey='id'
        pagination={false} />
    </>
  );
};

export default TutorBoard;
