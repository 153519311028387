import React, { FC } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

const Loader: FC = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(100%, 0%)',
    }}>
      <Spin indicator={antIcon} style={{}} />
    </div>

  );
};

export default Loader;