import React, { FC } from 'react';
import { Descriptions } from 'antd';
import { descriptionContentStyle, descriptionLabelStyle } from '../../style/antdCssProperty';
import { IStutyroomVideo } from '../../models/studyroom.model';

interface IProps {
  studyroomVideoList: IStutyroomVideo[];
}

const DashboardVideoList: FC<IProps> = ({ studyroomVideoList }) => {
  return (
    <>
      <Descriptions column={2} bordered labelStyle={descriptionLabelStyle} contentStyle={descriptionContentStyle}
                    style={{
                      marginTop: '1.5rem',
                    }}>
        {studyroomVideoList.map((item) => (
          <Descriptions.Item label='수업 영상' span={1} key={`studyroom-video-${item.id}`}>
            <video src={item.url} controls style={{ minWidth: 320 }} />
          </Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
};

export default DashboardVideoList;
