import axios from './axiosConfig';
import { ITutorWorkingLog, ITutorWorkingLogParam } from '../models/TutorWorkingLog';
import { AxiosPromise } from 'axios';
import qs from 'qs';

export const getTutorWorkingLogList = (param?: ITutorWorkingLogParam): AxiosPromise<ITutorWorkingLog[]> => {
  let url = `/api/tutor-working-log`;
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};
