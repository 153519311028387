import { ChangeEvent, FC, useState } from 'react';
import { Modal, Input, Checkbox, CheckboxProps, message } from 'antd';

interface IProps {
  visible: boolean;

  onOk(failReasonDetail: string, isClassRecovery: boolean, isEpisodeRecovery: boolean): void;

  onCancel(): void;
}

const { TextArea } = Input;

const DashboardFailReason: FC<IProps> = ({ visible, onOk, onCancel }) => {
  const [isClassRecovery, setIsClassRecovery] = useState(false);
  const [isEpisodeRecovery, setIsEpisodeRecovery] = useState(false);
  const [failReasonDetail, setFailReasonDetail] = useState('');

  const onFailReasonDetailChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFailReasonDetail(event.target.value);
  };

  const isClassRecoveryCheckBoxProps: CheckboxProps = {
    onChange: (event) => {
      setIsClassRecovery(event.target.checked);
    },
  };

  const isEpisodeRecoveryCheckBoxProps: CheckboxProps = {
    style: {
      marginLeft: 0
    },
    onChange: (event) => {
      setIsEpisodeRecovery(event.target.checked);
    },
  };

  const handleOk = () => {
    if (!failReasonDetail) {
      message.warn('이유를 작성해주세요.');
      return;
    }
    onOk(failReasonDetail, isClassRecovery, isEpisodeRecovery);
  };

  return (
    <Modal title='상세 사유 입력' visible={visible} onOk={handleOk} onCancel={onCancel}>
      <p>상세 사유 입력</p>
      <TextArea value={failReasonDetail} onChange={onFailReasonDetailChange} />
      <Checkbox {...isClassRecoveryCheckBoxProps}>해당 학생 수업권 1회를 복구 하시겠습니까?</Checkbox>
      <Checkbox {...isEpisodeRecoveryCheckBoxProps}>해당 학생 수업 차시를 복구 하시겠습니까?</Checkbox>
    </Modal>
  );
};

export default DashboardFailReason;
