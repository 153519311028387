import { FC } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IStudentTrial } from '../../models/Student';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { tableStyle } from '../../style/antdCssProperty';

const CustomerTrailTable: FC = () => {
  const { studentTrialList } = useSelector((root: RootState) => root.studentState);

  const columns: ColumnsType<IStudentTrial> = [
    {
      title: '시작일',
      dataIndex: 'startDate',
    },
    {
      title: '종료일',
      dataIndex: 'endDate',
    },
    {
      title: '과목',
      render: (value, record) => record.course.name,
    },
    {
      title: '수업갯수',
      dataIndex: 'totalClassCount',
    },
  ];

  return (
    <Table
      style={tableStyle}
      dataSource={studentTrialList}
      columns={columns}
      size='small'
      rowKey='id'
      pagination={false}
    />
  );
};

export default CustomerTrailTable;
