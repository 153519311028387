import axios from './axiosConfig';
import { AxiosPromise } from 'axios';
import { ITutor2, ITutor2Param, ITutor2Read, ITutor2Request } from '../models/tutor2.model';
import qs from 'qs';

export const getTutor2List = (param?: ITutor2Param): AxiosPromise<ITutor2[]> => {
  let url = `/api/tutor2`;
  const qsParams = qs.stringify(param);
  if (qsParams) {
    url += `?${qsParams}`;
  }
  return axios.get(url);
};

export const getTutor2 = (id: number): AxiosPromise<ITutor2Read> => {
  return axios.get(`/api/tutor2/${id}`);
};

export const updateTutor2 = (id: number, data: ITutor2Request): AxiosPromise => {
  return axios.patch(`/api/tutor2/${id}`, data, {
    withCredentials: true,
  });
};
